import { Directive, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { GeoLocationService } from '../mechanism/geolocation/geolocation.service';
import { Location } from '../mechanism/geolocation/location.model';
import { Suggestion } from '../mechanism/geolocation/suggestion.model';
import { Unit } from '../model/unit.model';

/**
 * Directive responsible for automatically suggest and set the unit.
 */
@Directive({ selector: '[geolocation-api-autocomplete]' })
export class GeoLocationAutocompleteDirective implements OnInit, OnChanges {

    /**
     * Here's suggested units
     */
    @Output() suggestedAddresses: EventEmitter<Array<Suggestion>> = new EventEmitter();

    /**
     * On Select Here's unit event emitter
     */
    @Output() unitSelected: EventEmitter<Unit> = new EventEmitter();

    /**
     * The unit location id
     */
    @Input() locationId: string;

    /**
     * Moura's unit model
     */
    private unit: Unit;

    /**
     * Default constructor
     *
     * @param elementRef elementRef @see @angular/core
     * @param model the model object
     * @param hereApiService Here api service
     * @param toastService toast service
     */
    constructor(private elementRef: ElementRef, private model: NgModel, private hereApiService: GeoLocationService, private toastService: ToastrService) { }

    /**
    * @see @angular/core/OnInit/ngOnInit()
    */
    ngOnInit() {
        // instantiate a new unit
        this.unit = new Unit();

        // create a keyup event to be fired after 250ms
        fromEvent(this.elementRef.nativeElement, 'keyup').pipe(map(() => this.model.value), debounceTime(250)).subscribe(query => {
            this.hereApiService.getSugestions(query).subscribe(
                (response) => {
                    // get the suggested units and emit then
                    this.suggestedAddresses.emit(response);
                },
                (responseError) => {
                    this.handleErrors(responseError);
                }
            );
        });

        // create a focus out envent to clear the suggestions after keyup event, avoid conflit with ngOnChanges
        fromEvent(this.elementRef.nativeElement, 'focusout').pipe(debounceTime(300)).subscribe(() => {
            // clear the suggested units
            this.suggestedAddresses.emit(null);
        });
    }

    /**
     * @see @angular/core/OnChanges/ngOnChanges()
     */
    ngOnChanges() {
        // if the locationId is set, get the unit information
        if (this.locationId && this.locationId !== '') {
            this.hereApiService.getGeocoder(this.locationId).subscribe(
                (response) => {
                    // get the unit location information
                    const location: Location = response.response.view[0].result[0].location;

                    // fullfill unit
                    this.unit.address = location.address.label;
                    this.unit.uf = isNullOrUndefined(location.address.state) ? '' : location.address.state;
                    this.unit.city = isNullOrUndefined(location.address.city) ? '' : location.address.city;
                    this.unit.latitude = location.displayPosition.latitude;
                    this.unit.longitude = location.displayPosition.longitude;

                    // emit unit
                    this.unitSelected.emit(this.unit);
                    // clear the suggested units
                    this.suggestedAddresses.emit(null);
                },
                (responseError) => {
                    this.handleErrors(responseError);
                }
            );
        }
    }

    /**
     * Method responsible for dealing with errors coming from Here server
     *
     * @param responseError responseError object returned from here server
     */
    handleErrors(responseError: any) {
        // set error message to be shown
        if (isNullOrUndefined(responseError)) {
            // error without response
            this.toastService.error('Ocorreu um erro ao tentar obter informações do serviço de geolocalização. Aguarde um momento e tente novamente');
        } else {
            // error with response
            this.toastService.error('Ocorreu um erro inesperado. Aguarde um momento e tente novamente');
        }
    }
}
