import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { GenericPageableSearchComponent } from '../../shared/generic-pageable-search-component.component';
import { ExportDataManager } from '../../shared/mechanism/export-data-manager.service';
import { SecurityManager } from '../../shared/mechanism/security-manager.service';
import { AlertType } from '../../shared/model/alert-type.model';
import { Alert } from '../../shared/model/alert.model';
import { UserProfile } from '../../shared/model/enum/user-profile.enum';
import { AlertSearchParameters } from '../../shared/model/search/alert-search-parameters.model';
import { User } from '../../shared/model/user.model';
import { BatteryTypePipe } from '../../shared/pipe/battery-type.pipe';
import { AlertTypeService } from './alert-type.service';
import { AlertService } from './alert.service';

/**
 * List the alerts of the battery
 */
@Component({ selector: 'app-search-alerts', templateUrl: './search-alerts.component.html', styleUrls: ['./search-alerts.component.scss']})
export class SearchAlertsComponent extends GenericPageableSearchComponent<Alert> implements OnInit {
    /**
     * Filters object. Overwrites the inherited generic filters object.
     */
    filters: AlertSearchParameters;

    /**
     * Alert type list
     */
    alertTypes: AlertType[] = [];

    /**
     * Default constructor for generic application component
     *
     * @param toastService toast service
     * @param alertService service for alert matters
     * @param router router service
     * @param activatedRoute activated route service
     * @param alertService service for alert type matters
     * @param exportDataManager export data manager
     * @param batteryTypePipe battery type pipe
     * @param alertTypePipe alert type pipe
     */
    constructor(protected toastService: ToastrService, protected alertService: AlertService, protected router: Router, protected activatedRoute: ActivatedRoute, private alertTypeService: AlertTypeService, private exportDataManager: ExportDataManager, private batteryTypePipe: BatteryTypePipe, private securityManager: SecurityManager) {
        // call super
        super(toastService, alertService, router, 'alert');
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // call super
        super.ngOnInit();

        // load alert types
        this.loadAlertTypes();

        // get logged user
        const loggedUser: User = this.securityManager.getLoggedUser();

        // instantiate filters
        this.filters = new AlertSearchParameters();
        this.filters.businessAreaId = loggedUser.businessAreaId;
        this.filters.customerId = !loggedUser.internal || (loggedUser.internal && loggedUser.profile === UserProfile.OPERATOR) ? loggedUser.customers[0].id : 0;
        this.filters.customers = loggedUser.internal && loggedUser.profile === UserProfile.COORDINATOR ? loggedUser.customers : [];
        if (!loggedUser.internal && loggedUser.profile === UserProfile.OPERATOR) {
            this.filters.unitId = loggedUser.unitId;
        }

        // get the query Params if exists
        this.activatedRoute.queryParams.subscribe(params => {
            this.filters.batteryType = params['batteryType'] ? params['batteryType'] : '';
        });

        // setup pageable object
        this.pageable.sortField = 'createdDate';
        this.pageable.sortOrder = 'DESC';

        // perform initial search
        this.search();
    }

    /**
     * Method responsible for setting period date filter after user's selection
     *
     * @param customDateSelectionEvent provided only when a custom date is selected by the component
     */
    setPeriodDateFilter(customDateSelectionEvent: any) {
        this.filters.startDate = moment(customDateSelectionEvent[0]).set({hour: 0, minute: 0, second: 0}).toDate();
        this.filters.endDate = moment(customDateSelectionEvent[1]).set({hour: 23, minute: 59, second: 59}).toDate();
    }

    /**
     * Method responsible for getting period date filter
     *
     * @returns formatted period date filter
     */
    getFormattedPeriodDateFilter(): String {
        // formatted period date filter to be returned
        let formattedPeriodDateFilter: String = null;

        // if start and end date are present, show them
        if (this.filters.startDate && this.filters.endDate) {
            formattedPeriodDateFilter = moment(this.filters.startDate).format('DD/MM/YYYY');
            formattedPeriodDateFilter += '-';
            formattedPeriodDateFilter += moment(this.filters.endDate).format('DD/MM/YYYY');
        }

        return formattedPeriodDateFilter;
    }

    /**
     * Exports the current data content to an Excel file.
     */
    exportToExcel() {
        // export to file
        this.exportDataManager.exportAsExcelFile(this.results.content.map(this.buildExportableAlert.bind(this)), 'Lista de Alertas');
    }

    /**
     * Method responsible to verify if user can access battery details.
     *
     * @returns flag indicating if ca access battery details
     */
    canUserGoToBatteryDetails(): boolean {
        return this.securityManager.getLoggedUser().internal;
    }

    /**
     * Method responsible for redirecting user to battery details information
     *
     * @param batteryId battery identifier
     */
    goToBatteryDetails(batteryId: number) {
        this.router.navigate([`/batteries/${batteryId}/details`]);
    }

    /**
     * Method responsible for cleaning the search parameters
     */
    cleanFilters() {
        // clear the filter with the default value
        this.filters.batteryOperationsIdentification = '';
        this.filters.deviceChipIdentification = '';
        this.filters.batteryUniqueIdentifier = '';
        this.filters.batteryType = '';
        this.filters.typeCode = '';
        this.filters.startDate = null;
        this.filters.endDate = null;
    }

    /**
     * Takes a Alert object and returns its representation in an Excel row, which has more presentable column names and formatted values.
     *
     * @param alert the alert to be converted.
     * @returns an object that contains an exportable alert
     * - The object has the following structure:
     *      {
     *          'Identificador unico': string,
     *          'Tipo de bateria': string,
     *          'Alerta': string,
     *          'Data Criação': string,
     *          'Encerrado?': string
     *      }
     */
    private buildExportableAlert(alert: Alert): { [key: string]: string } {
        return {
            'Identificador unico': alert.battery.uniqueIdentifier,
            'Tipo de bateria': this.batteryTypePipe.transform(alert.battery.type),
            'Alerta': alert.type.name,
            'Data Criação': moment(alert.createdDate).format('DD/MM/YYYY HH:mm:ss'),
            'Encerrado?': alert.closedDate === null ? 'Não' : 'Sim'
        };
    }

    /**
     * Method responsible for loading all alert types
    */
    private loadAlertTypes() {
        this.alertTypeService.getAllEntities().subscribe(
            (data) => {
                // get all alert types
                this.alertTypes = data;

                // sort alert types
                this.alertTypes.sort((a: AlertType, b: AlertType) => a.name.localeCompare(b.name));
            },
            (responseError) => this.handleErrors(responseError)
        );
    }
}
