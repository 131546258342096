import { HttpParams } from '@angular/common/http';

import { GenericSearchParameters } from './generic-search-parameters.model';

/**
 * Model class representing head office search parameters
 */
export class HeadOfficeSearchParameters implements GenericSearchParameters {
    name: string;
    socialName: string;
    cnpj: string;

    /**
     * Default Constructor
     */
    constructor() {
        this.name = '';
        this.socialName = '';
        this.cnpj = '';
    }

    /**
     * @see GenericSearchParameters/generateHttpParams()
     */
    generateHttpParams(): HttpParams {
        // initialize parameter list
        let httpParams: HttpParams = new HttpParams();

        // assign name parameter
        if (this.name && this.name.trim().length > 0) {
            httpParams = httpParams.append('name', this.name);
        }

        // assign social name parameter
        if (this.socialName && this.socialName.trim().length > 0) {
            httpParams = httpParams.append('socialName', this.socialName);
        }

        // assign cnpj parameter
        if (this.cnpj && this.cnpj.trim().length > 0) {
            httpParams = httpParams.append('cnpj', this.cnpj);
        }

        return httpParams;
    }
}
