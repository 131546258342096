import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import 'jquery-slimscroll';

declare var $: any;

/**
 * Scrollable directive class
 */
@Directive({ selector: 'scrollable' })
export class ScrollableDirective implements OnInit {
    /**
     * Varible to hold height input
     */
    @Input() height: number;

    /**
     * Default constructor
     *
     * @param element reference to the DOM element
     */
    constructor(public element: ElementRef) { }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        $(this.element.nativeElement).slimScroll({
            height: (this.height || 250)
        });
    }
}
