import { Pipe, PipeTransform } from '@angular/core';
import { LogEntrySource } from '../model/enum/log-entry-source.enum';

/**
 * Pipe responsible to translate the log entry source
 */
@Pipe({ name: 'logEntrySource' })
export class LogEntrySourcePipe implements PipeTransform {

    /**
     * @see @angular/core/PipeTransform/transform(value: any, ...args: any[])
     */
    transform(logEntrySource: LogEntrySource): string {
        let logEntrySourceName: string;

        switch (logEntrySource) {
            case LogEntrySource.WEBSITE:
                logEntrySourceName = 'Frontend';
                break;
            case LogEntrySource.SERVER:
                logEntrySourceName = 'Backend';
                break;
            default:
                logEntrySourceName = 'Nenhuma descrição para ' + logEntrySource;
                break;
        }

        return logEntrySourceName;
    }
}
