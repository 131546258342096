import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe responsible for formatting a cnpj
 */
@Pipe({name: 'cnpj'})
export class CnpjPipe implements PipeTransform {

    /**
     * @see @angular/core/PipeTransform/transform(value: any, ...args: any[])
     */
    transform(input: string): string {
        return input ? `${input.substr(0, 2)}.${input.substr(2, 3)}.${input.substr(5, 3)}/${input.substr(8, 4)}-${input.substr(12)}` : '';
    }
}
