import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';

import { environment } from '../../../environments/environment';
import { GenericComponent } from '../../shared/generic-component.component';
import { SecurityManager } from '../../shared/mechanism/security-manager.service';
import { BatteryType } from '../../shared/model/enum/battery-type.enum';
import { DoughnutData } from '../../shared/model/interface/doughnut-data';
import { IdLabeledRow } from '../../shared/model/interface/id-labeled-row.interface';
import { TractionaryCoordinatorHomeReport } from '../../shared/model/report/tractionary-coordinator-home-report.model';
import { BatteryStatusPipe } from '../../shared/pipe/battery-status.pipe';
import { CustomerService } from '../customer/customer.service';
import { ReportService } from '../report/report.service';

/**
 * Hoem component controller class for coordinator dashboard
 */
@Component({ selector: 'app-tractionary-coordinator-home', templateUrl: './tractionary-coordinator-home.component.html', styleUrls: [ './tractionary-coordinator-home.component.scss' ] })
export class TractionaryCoordinatorHomeComponent extends GenericComponent implements OnInit, OnChanges {
    /**
     * Data content the coordinator report of the tractionary home
     */
    tractionaryCoordinatorHomeReport: TractionaryCoordinatorHomeReport;

    /**
     * Holds the customer logo url
     */
    customerLogoUrl: string;

    /**
     * Holds the customer name
     */
    customerName: string;

    /**
     * Color palette for battery status
     */
    batteryStatusColorPalette: any;

    /**
     * The doughtnut battery average time by status data.
     */
    batteryAverageTimeByStatusChartData: DoughnutData;

    /**
     * Data content of the Battery chart.
     */
    batteryChartData: IdLabeledRow[];

    /**
     * Variable to hold if is small device screen
     */
    isSmallDeviceScreen: boolean;

    /**
     * Average time maintenance of tractionary coordinator home component
     */
    averageTimeMaintenance: Object[];

    /**
     * Average time operations of tractionary coordinator home component
     */
    averageTimeOperations: Object[];

    /**
     * hold the current customer id and customer unit id.
     */
    @Input() homeFilter: any;

    /**
     * Variable to hold reference to battery chard modal close button
     */
    @ViewChild('batteryChartModalCloseButton') batteryChartModalCloseButtonElement: ElementRef;

    /**
     * Default constructor
     *
     * @param toastService toast service
     * @param reportService report service
     * @param customerService customer service
     * @param batteryStatusPipe battery status pipe
     * @param deviceService device detector service
     */
    constructor(protected toastService: ToastrService, private reportService: ReportService, private customerService: CustomerService, private batteryStatusPipe: BatteryStatusPipe, protected sanitizer: DomSanitizer, private router: Router, private securityManager: SecurityManager, deviceService: DeviceDetectorService) {
        // call super
        super(toastService);

        // set if is small device screen
        this.isSmallDeviceScreen = deviceService.isMobile() || deviceService.isTablet();
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // initialize the report
        this.clearReport();

        // setup static chart data
        this.batteryStatusColorPalette = {
            USING: 'rgb(55,188,155,0.8)',
            STOPPED: 'rgb(192,84,204,0.8)',
            CHARGING: 'rgb(228,197,46,0.8)',
            RESTING: 'rgb(240,80,80,0.8)',
            READY: 'rgb(93,156,236,0.8)',
            DISCHARGED: 'rgb(255,118,57,0.8)',
            CHARGED: 'rgb(91,170,61,0.8)',
            UNKNOW: 'rgb(127,132,132,0.8)',
        };
    }

    /**
     * @see @angular/core/OnChanges/ngOnChanges()
     */
    ngOnChanges() {
        // load the coordinator tractionary report
        this.getTractionaryCoordinatorReport();
    }

    /**
     * Event handler for the click action on a row in the Battery chart and close the modal element. Redirects to the battery's detail page.
     *
     * @param row the row that has been clicked
     */
    modalBatteryChartRowClicked(row: IdLabeledRow) {
        // close modal
        this.batteryChartModalCloseButtonElement.nativeElement.click();
        // navigate
        this.router.navigate([ '/batteries', row.id, 'details' ]);
    }

    /**
     * Method responsible to verify if user can access battery details.
     *
     * @returns flag indicating if ca access battery details
     */
    canUserGoToBatteryDetails(): boolean {
        return this.securityManager.getLoggedUser().internal;
    }

    /**
     * Method responsible for returning the customer logo url
     *
     * @returns a photo url or null
     */
    getCustomerLogo(): SafeStyle | null {
        return this.sanitizer.bypassSecurityTrustUrl(environment.storagebaseUrl + '/' + this.customerLogoUrl);
    }

    /**
    * Verify if should display the general information card.
    *
    * @returns boolean indicating if should display the card.
    */
    shouldDisplayGeneralInformationCard(): boolean {
        const hasAllValues: boolean = !isNullOrUndefined(this.tractionaryCoordinatorHomeReport.currentMonth) && this.tractionaryCoordinatorHomeReport.currentMonth > 0 && !isNullOrUndefined(this.tractionaryCoordinatorHomeReport.currentYear) && this.tractionaryCoordinatorHomeReport.currentYear > 0 && !isNullOrUndefined(this.tractionaryCoordinatorHomeReport.monthProgress);
        return this.homeFilter.customerId && this.homeFilter.customerId > 0 && hasAllValues;
    }

    /**
     * Verify if should display the average time for maintenance card.
     *
     * @returns boolean indicating if should display the card.
     */
    shouldDisplayAverageTimeForMaintenanceCard(): boolean {
        const hasAnyValue: boolean = this.tractionaryCoordinatorHomeReport.batteryAverageReplacementTime > 0 || this.tractionaryCoordinatorHomeReport.batteryAverageDisconnectFromChargerTime > 0 || this.tractionaryCoordinatorHomeReport.batteryAverageWaterReplacementTime > 0;
        return this.homeFilter.customerId && this.homeFilter.customerId > 0 && hasAnyValue;
    }

    /**
     * Verify if should display the average time for operation card.
     *
     * @returns boolean indicating if should display the card.
     */
    shouldDisplayAverageTimeForOperationCard(): boolean {
        const hasAnyValue: boolean = this.tractionaryCoordinatorHomeReport.batteryAverageCycleTime > 0 || this.tractionaryCoordinatorHomeReport.batteryAverageWorkingTime > 0 || this.tractionaryCoordinatorHomeReport.batteryAverageChargeTime > 0 || this.tractionaryCoordinatorHomeReport.batteryAverageRestTime > 0;
        return this.homeFilter.customerId && this.homeFilter.customerId > 0 && hasAnyValue;
    }

    /**
     * Method responsible to verify if customer logo is avaliable.
     *
     * @returns boolean indicating if customer logo is avaliable to display.
     */
    isCustomerLogoAvaliable(): boolean {
        return this.customerLogoUrl && this.customerLogoUrl !== '' && !environment.local;
    }

    /**
     * Method responsible for defining if the last data update info must be displayed
     *
     * @returns flag indicating if the last data update info must be displayed
     */
    shouldShowLastUpdateInformation(): boolean {
        return !isNullOrUndefined(this.tractionaryCoordinatorHomeReport.updatedDate);
    }

    /**
     * Method responsible to load the coordinator report of tractionary home.
     */
    private getTractionaryCoordinatorReport() {
        let tractionaryCoordinatorHomeReportObservable = null;

        // initialize the report
        this.clearReport();

        if (this.homeFilter.unitBatteryRoomId > 0) {
            // set the request to get the tractionary coordinator report by unit battery room id
            tractionaryCoordinatorHomeReportObservable = this.reportService.getCoordinatorTractionaryHomeReportByBatteryRoom(this.homeFilter.unitBatteryRoomId);
        }
        else if (this.homeFilter.customerUnitId > 0) {
            // set the request to get the tractionary coordinator report by customer unit id
            tractionaryCoordinatorHomeReportObservable = this.reportService.getCoordinatorTractionaryHomeReportByUnit(this.homeFilter.customerUnitId);
        }
        else if (this.homeFilter.customerId > 0) {
            // set the request to get the tractionary coordinator report by customer id
            tractionaryCoordinatorHomeReportObservable = this.reportService.getCoordinatorTractionaryHomeReportByCustomer(this.homeFilter.customerId);
        }

        if (tractionaryCoordinatorHomeReportObservable) {
            // perfom the request and treat reponse
            tractionaryCoordinatorHomeReportObservable.subscribe((response) => {
                // set the report data
                this.tractionaryCoordinatorHomeReport = response;

                // build the average time card data
                this.buildAverageTimeData();

                // create the battery average time by status chart data
                this.buildBatteryAverageTimeByStatusChartData();

                // load battery status time summary for last 24 hours
                this.loadBatteryStatusTimeSummaryForTheLast24Hours();

                // update customer information
                this.updateCustomerInformation();
            });
        }
    }

    /**
     * Builds average time card data
     */
    buildAverageTimeData() {
        // set the average time of maintenance
        this.averageTimeMaintenance = [
            {
                descriptionTitle: 'Tempo médio para troca',
                description: 'Média mensal de tempo entre as baterias ficaram descarregada e serem colocadas para carregar',
                styleClass: '',
                label: 'Tempo médio para troca',
                value: this.tractionaryCoordinatorHomeReport.batteryAverageReplacementTime,
            },
            {
                descriptionTitle: 'Tempo médio para retirada do carregador',
                description: 'Média mensal de tempo entre as baterias sendo carregadas e retiradas do carregador',
                styleClass: '',
                label: 'Tempo médio para retirada do carregador',
                value: this.tractionaryCoordinatorHomeReport.batteryAverageDisconnectFromChargerTime,
            },
            {
                descriptionTitle: 'Tempo médio para reposição de água',
                description: 'Média mensal de tempo entre as baterias com nível de eletrólito baixo e após a reposição de água',
                styleClass: '',
                label: 'Tempo médio para reposição de água',
                value: this.tractionaryCoordinatorHomeReport.batteryAverageWaterReplacementTime,
            },
        ];

        // set the average time of operation
        this.averageTimeOperations = [
            {
                descriptionTitle: 'Tempo médio por ciclo',
                description: 'Média mensal de tempo em que as baterias ficam em cada estado',
                styleClass: 'battery-average-cycle-time',
                label: 'Tempo médio por ciclo',
                value: this.tractionaryCoordinatorHomeReport.batteryAverageCycleTime,
            },
            {
                descriptionTitle: 'Tempo médio de uso',
                description: 'Média mensal de tempo em que as baterias ficam em uso a cada ciclo de operação',
                styleClass: 'battery-average-working-time',
                label: 'Tempo médio de uso',
                value: this.tractionaryCoordinatorHomeReport.batteryAverageWorkingTime,
            },
            {
                descriptionTitle: 'Tempo médio de carga',
                description: 'Média mensal de tempo em que as baterias ficam carregando',
                styleClass: 'battery-average-charge-time',
                label: 'Tempo médio de carga',
                value: this.tractionaryCoordinatorHomeReport.batteryAverageChargeTime,
            },
            {
                descriptionTitle: 'Tempo médio de descanso',
                description: 'Média mensal de tempo em que as baterias ficam em descanso',
                styleClass: 'battery-average-rest-time',
                label: 'Tempo médio de descanso',
                value: this.tractionaryCoordinatorHomeReport.batteryAverageRestTime,
            },
        ];
    }

    /**
     * Method responsible to clear the variables.
     */
    private clearReport() {
        this.tractionaryCoordinatorHomeReport = new TractionaryCoordinatorHomeReport();
        this.batteryAverageTimeByStatusChartData = null;
        this.batteryChartData = null;
        this.averageTimeMaintenance = [];
        this.averageTimeOperations = [];
    }

    /**
     * Method responsible to updating the reference to customer logo and name
     */
    private updateCustomerInformation() {
        this.customerService.getEntityById(this.homeFilter.customerId).subscribe((response) => {
            // set customer logo url and customer name
            this.customerLogoUrl = response.logoUrl;
            this.customerName = response.name;
        });
    }

    /**
     * Method responsible to create the chart object for the average time by status.
     */
    private buildBatteryAverageTimeByStatusChartData() {
        const labels = [];
        const data = [];
        const backgroundColor = [];
        const batteryAverageTimeGroupedByStatus = this.tractionaryCoordinatorHomeReport.batteryAverageTimeGroupedByStatus;

        for (const status in batteryAverageTimeGroupedByStatus) {
            if (batteryAverageTimeGroupedByStatus.hasOwnProperty(status)) {
                labels.push(this.batteryStatusPipe.transform(status, BatteryType.TRACTIONARY));
                data.push(Math.round(batteryAverageTimeGroupedByStatus[status] / (1000 * 60 * 60)));
                backgroundColor.push(this.batteryStatusColorPalette[status]);
            }
        }

        if (data.length > 0) {
            this.batteryAverageTimeByStatusChartData = {
                labels: labels,
                datasets: [
                    {
                        data: data,
                        backgroundColor: backgroundColor,
                    },
                ],
                showMiddleText: false,
            };
        }
    }

    /**
     * Method responsible for loading the Battery chart content
     */
    private loadBatteryStatusTimeSummaryForTheLast24Hours(): void {
        // reset chart data
        this.batteryChartData = [];

        // set the batteryStatusSummaryForTheLastDay
        const batteryStatusSummaryForTheLastDay = this.tractionaryCoordinatorHomeReport.batteryStatusSummaryForTheLastDay;

        // iterate in summary items to create chart data structure
        for (const batteryStatusSummary in batteryStatusSummaryForTheLastDay) {
            if (batteryStatusSummaryForTheLastDay.hasOwnProperty(batteryStatusSummary)) {
                this.batteryChartData.push(this.tranformStatusSummaryToChartData(batteryStatusSummary, this.groupAndFilterBatteryStatus(batteryStatusSummaryForTheLastDay[batteryStatusSummary])));
            }
        }
    }

    /**
     * Method responsible for converting the battery status summary to structured data to feed the Battery Chart
     *
     * @param batteryStatusSummaryKey battery's status summary key (composed by battery id | battery unique key)
     * @param batteryStatusSummary summary about status changes from the desired battery
     */
    private tranformStatusSummaryToChartData(batteryStatusSummaryKey: string, batteryStatusSummary: any): IdLabeledRow {
        // init column array
        const columns = [];

        // extract data from battery status summary key
        const batteryId: number = parseInt(batteryStatusSummaryKey.split('|')[0], 10);
        const batteryUniqueIdentifier: string = batteryStatusSummaryKey.split('|')[1];
        for (const summaryItem in batteryStatusSummary) {
            // check if key is present in summary
            if (batteryStatusSummary.hasOwnProperty(summaryItem)) {
                // add column to array, with time in rounded hours (2 decimal places)
                columns.push({
                    label: this.batteryStatusPipe.transform(batteryStatusSummary[summaryItem]['status'], BatteryType.TRACTIONARY),
                    value: Math.round(batteryStatusSummary[summaryItem]['time'] * 1000 / (1000 * 60 * 60)) / 1000,
                    backgroundColor: this.batteryStatusColorPalette[batteryStatusSummary[summaryItem]['status']],
                });
            }
        }

        // return a new chart row
        return {
            id: batteryId,
            label: batteryUniqueIdentifier,
            columns: columns.length !== 0 ? columns : [ { label: '', value: 0 } ],
        };
    }

    /**
     * Method responsible to group the status and filter status greater than a half hour
     *
     * @param batteryStatusSummary battery's status summary key (composed by battery id | battery unique key)
     * @returns the new battery status summary
     */
    private groupAndFilterBatteryStatus(batteryStatusSummary: any) {
        // the new battery status summary
        const groupedBatteryStatusSummary: Array<any> = [];
        for (const summaryItem in batteryStatusSummary) {
            // check if key is present in summary
            if (batteryStatusSummary.hasOwnProperty(summaryItem)) {
                // if the current status is different from the last status, push to the array
                if (!groupedBatteryStatusSummary.length || groupedBatteryStatusSummary[groupedBatteryStatusSummary.length - 1]['status'] !== batteryStatusSummary[summaryItem]['status']) {
                    groupedBatteryStatusSummary.push(batteryStatusSummary[summaryItem]);
                }
                else {
                    // if the current status is equal from the last status, sum the time
                    groupedBatteryStatusSummary[groupedBatteryStatusSummary.length - 1]['time'] += batteryStatusSummary[summaryItem]['time'];
                }
            }
        }

        return groupedBatteryStatusSummary;
    }
}
