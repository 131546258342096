import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GenericComponent } from '../../generic-component.component';
import { Page } from '../../model/page.model';
import { Pageable } from '../../model/pageable.model';

/**
 * Component for showing pagination control
 */
@Component({ selector: 'app-pagination', templateUrl: './pagination.component.html'})
export class PaginationComponent extends GenericComponent {
     /**
     * Pageable object to decide over pagination
     */
    @Input() pageable: Pageable;

    /**
     * Page object to decide over pagination
     */
    @Input() results: Page<Object>;

    /**
     * Pagination changed event
     */
    @Output() paginationChanged = new EventEmitter();

    /**
     * Default constructor for menu component
     *
     * @param toastService toast service
     * @param userService user service instance
     * @param securityManager mechanism to deal with security matters
     * @param router router service
     */
    constructor(protected toasterService: ToastrService) {
        // call super
        super(toasterService);
    }

    /**
     * Method responsible for emmiting a new pagination changed event
     *
     * @param newPageNumber new page number
     */
    changePagination(newPageNumber: number) {
        // update pageable object
        this.pageable.page = newPageNumber;

        // emit pagination changed event
        this.paginationChanged.emit();
    }
}
