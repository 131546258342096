import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GenericComponent } from '../../shared/generic-component.component';
import { BatteryRoom } from '../../shared/model/battery-room.model';

/**
 * Maintain battery room component controller class
 */
@Component({ selector: 'app-maintain-battery-room', templateUrl: './maintain-battery-room.component.html' })
export class MaintainBatteryRoomComponent extends GenericComponent {

    /**
     * Battery room model
     */
    model: BatteryRoom;

    /**
     * Event emitter that emits the inputted battery room data after the user click on the add button
     */
    @Output() saveBatteryRoomClick: EventEmitter<BatteryRoom> = new EventEmitter();

    /**
     * Default constructor
     *
     * @param modalReference reference to modal opener
     * @param toastService toast service
     */
    constructor(public modalReference: BsModalRef, protected toastService: ToastrService) {
        // call super
        super(toastService);

        // initialize model
        this.model = new BatteryRoom();
    }

    /**
     * Method that is called when save battery room button was clicked, so it can emit a event to a consumer catch the room model
     */
    addBatteryRoom() {
        this.saveBatteryRoomClick.emit(this.model);
    }
}
