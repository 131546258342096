import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { GenericPageableSearchComponent } from '../../shared/generic-pageable-search-component.component';
import { SecurityManager } from '../../shared/mechanism/security-manager.service';
import { Customer } from '../../shared/model/customer.model';
import { ServiceOrderStatus } from '../../shared/model/enum/service-order-status.enum';
import { ServiceOrderType } from '../../shared/model/enum/service-order-type.enum';
import { ServiceOrderSearchParameters } from '../../shared/model/search/service-order-search-parameters.model';
import { ServiceOrderItem } from '../../shared/model/service-order-item.model';
import { ServiceOrder } from '../../shared/model/service-order.model';
import { User } from '../../shared/model/user.model';
import { CustomerService } from '../customer/customer.service';
import { ServiceOrderService } from './service-order.service';
import { UserProfile } from '../../shared/model/enum/user-profile.enum';
import { HeadOffice } from '../../shared/model/head-office.model';
import { HeadOfficeService } from '../headoffice/head-office.service';

/**
 * Controller class for the Search Service Order Component.
 */
@Component({ selector: 'app-search-service-orders', templateUrl: './search-service-orders.component.html' })
export class SearchServiceOrdersComponent extends GenericPageableSearchComponent<ServiceOrder> implements OnInit {

    /**
     * Filters object. Overwrites the inherited generic filters object.
     */
    filters: ServiceOrderSearchParameters;

    /**
     * Head offices to beused as filter's content
     */
    headOffices: HeadOffice[];

    /**
     * Variable to hold loaded customers.
     */
    loadedCustomers: Customer[];

    /**
     * Customer to be used as filter's content
     */
    customers: Customer[];

    /**
    * Service order items that will be show in details by modal
    */
    selectedServiceOrder: ServiceOrder;

    /**
     * Service order items that will be show in details by modal
     */
    selectedServiceOrderItems: ServiceOrderItem[] = [];

    /**
     * Default constructor for generic application component
     *
     * @param toastService toast service
     * @param serviceOrderService service for service order matters
     * @param router router service
     * @param securityManager mechanism to deal with security matters
     * @param customerService service for customer matters
     * @param headOfficeService service for head office matters
     */
    constructor(protected toastService: ToastrService, protected serviceOrderService: ServiceOrderService, protected router: Router, private securityManager: SecurityManager, private customerService: CustomerService, protected headOfficeService: HeadOfficeService) {
        // call super
        super(toastService, serviceOrderService, router, 'serviceorders');
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // call super
        super.ngOnInit();

        // load customers
        this.loadCustomers();

        const loggedUser = this.securityManager.getLoggedUser();

        if (loggedUser.profile === UserProfile.ADMIN || loggedUser.profile === UserProfile.EXECUTIVE) {
            // load head offices
            this.loadHeadOffices();
        }

        // instantiate filters
        this.filters = new ServiceOrderSearchParameters();
        this.filters.businessAreaId = loggedUser.businessAreaId;
        this.filters.customers = loggedUser.internal && loggedUser.profile === UserProfile.COORDINATOR ? loggedUser.customers.map((customer: Customer) => customer.id) : !loggedUser.internal || (loggedUser.internal && loggedUser.profile === UserProfile.OPERATOR) ? loggedUser.customers.map((customer: Customer) => customer.id) : [0];
        // setup pageable object
        this.pageable.sortField = 'id';

        // perform initial search
        this.search();
    }

    /**
     * Method responsible for viewing service order items by setting choosen service order
     *
     * @param serviceOrder service order to be viewed
     */
    viewServiceOrderItems(serviceOrder: ServiceOrder) {
        this.selectedServiceOrder = serviceOrder;
        this.selectedServiceOrderItems = serviceOrder.items;
    }

    /**
     * Method responsible for setting period date filter after user's selection
     *
     * @param customDateSelectionEvent provided only when a custom date is selected by the component
     */
    setPeriodDateFilter(customDateSelectionEvent: any) {
        this.filters.startDate = customDateSelectionEvent[0];
        this.filters.endDate = customDateSelectionEvent[1];
    }

    /**
     * Method responsible for getting period date filter
     *
     * @returns formatted period date filter
     */
    getFormattedPeriodDateFilter(): string {
        // formatted period date filter to be returned
        let formattedPeriodDateFilter: string = null;

        // if start and end date are present, show them
        if (this.filters.startDate && this.filters.endDate) {
            formattedPeriodDateFilter = moment(this.filters.startDate).format('DD/MM/YYYY');
            formattedPeriodDateFilter += '-';
            formattedPeriodDateFilter += moment(this.filters.endDate).format('DD/MM/YYYY');
        }

        return formattedPeriodDateFilter;
    }

    /**
     * Method responsible for clearing filters
     */
    cleanFilters() {
        const loggedUser = this.securityManager.getLoggedUser();

        this.filters.id = null;
        this.filters.status = '';
        this.filters.startDate = null;
        this.filters.endDate = null;
        this.filters.type = '';
        this.filters.headOfficeId = 0;
        this.filters.customers = loggedUser.internal && loggedUser.profile === UserProfile.COORDINATOR ? loggedUser.customers.map((customer: Customer) => customer.id) : !loggedUser.internal || (loggedUser.internal && loggedUser.profile === UserProfile.OPERATOR) ? loggedUser.customers.map((customer: Customer) => customer.id) : [0];

        // reset the customers list
        this.customers = this.loadedCustomers;
    }

    /**
     * Method responsible for indicating if a given service order is editable
     *
     * @param serviceOrder service order to be evaluated
     * @returns flag indicating if the given service order is editable
     */
    isServiceOrderEditable(serviceOrder: ServiceOrder): boolean {
        return serviceOrder.status === ServiceOrderStatus.OPEN;
    }

    /**
     * Method responsible for indicating if a given service order has any details to be shown
     *
     * @param serviceOrder service order to be evaluated
     * @returns flag indicating if the given service order has any details to be shown
     */
    isServiceOrderHasAnyItems(serviceOrder: ServiceOrder): boolean {
        return serviceOrder.items && serviceOrder.items.length > 0;
    }

    /**
     * Method responsible for indicating if a given service order is removable
     *
     * @param serviceOrder service order to be evaluated
     * @returns flag indicating if the given service order is editable
     */
    isServiceOrderRemovable(serviceOrder: ServiceOrder): boolean {
        return serviceOrder.status === ServiceOrderStatus.OPEN;
    }

    /**
     * Method responsible for indicating if the uninstall option should be displayed
     *
     * @param serviceOrder service order to be evaluated
     * @returns flag indicating if the uninstall option should be displayed
     */
    shouldShowUninstallOption(serviceOrder: ServiceOrder): boolean {
        return (serviceOrder.status === ServiceOrderStatus.OPEN || serviceOrder.status === ServiceOrderStatus.ONGOING) && serviceOrder.type === ServiceOrderType.UNINSTALL;
    }

    /**
     * Method responsible for verify if the current user should edit service oder.
     *
     * @return the boolean result if should edit service order
     */
    shouldEditServiceOrder(): boolean {
        // get the logged user profile
        const userProfile: UserProfile = this.securityManager.getLoggedUser().profile;

        // return if should edit service order
        return userProfile === UserProfile.ADMIN || userProfile === UserProfile.COORDINATOR;
    }

    /**
     * Method responsible for redireting user to the uninstall operarion page relative to the chosen service order
     *
     * @param serviceOrderId service order identifier
     */
    goToUninstallation(serviceOrderId: number) {
        this.router.navigate([`/${this.routeElement}/${serviceOrderId}/uninstall`]);
    }

    /**
     * Method responsible to verify if user can filter service order by customer
     *
     * @returns flag indicating if can filter service order by customer
     */
    canUserFilterServiceOrderByCustomer() {
        return this.securityManager.getLoggedUser().profile !== UserProfile.OPERATOR;
    }

    /**
     * Method responsible for loading all customers
     */
    private loadCustomers() {
        // business area associated to logged user
        const loggedUser: User = this.securityManager.getLoggedUser();

        // in according to the user profile, check what customers should be seen by logged user
        if (loggedUser.profile === UserProfile.EXECUTIVE || loggedUser.profile === UserProfile.ADMIN) {
            this.customerService.getAllEntities().subscribe(
                (data) => this.assignAndSortCustomerData(data),
                (responseError) => this.handleErrors(responseError)
            );
        }
        else {
            this.customerService.getCustomersByBusinessArea(loggedUser.businessAreaId).subscribe(
                (data) => this.assignAndSortCustomerData(loggedUser.profile !== UserProfile.COORDINATOR ? data : data.filter((customer: Customer) => loggedUser.customers.findIndex((loggedUserCustomer: Customer) => customer.id === loggedUserCustomer.id) !== -1)),
                (responseError) => this.handleErrors(responseError)
            );
        }
    }

    /**
     * Method responsible to verify if should display head office filter
     *
     * @returns flag indicating if should display head office filter
     */
    shouldDisplayHeadOfficeFilter(): boolean {
        // get logged user
        const loggedUser = this.securityManager.getLoggedUser();

        return loggedUser.profile === UserProfile.ADMIN || loggedUser.profile === UserProfile.EXECUTIVE;
    }

    /**
     * Method responsible to filter customers list by head office if necessary
     */
    filterCustomersByHeadOffice() {
        // filter the list of customer if necessary
        this.customers = Number(this.filters.headOfficeId) !== 0 ? this.loadedCustomers.filter((customer: Customer) => customer.headOfficeId === Number(this.filters.headOfficeId)) : this.loadedCustomers;
    }

    /**
     * Method responsible for assigning and sorting customer data (callback for loading customers)
     *
     * @param customers customers to be assigned and ordered
     */
    private assignAndSortCustomerData(customers: Customer[]) {
        // get all customers
        this.customers = customers;

        // set the loaded customers
        this.loadedCustomers = customers;

        // sort customers
        this.customers.sort((a: Customer, b: Customer) => a.name.localeCompare(b.name));

    }

    /**
     * Method responsible for loading all head offices
     */
    private loadHeadOffices(): void {
        this.headOfficeService.getAllEntities().subscribe(
            (data) => {
                // get all head offices
                this.headOffices = data;

                // sort head offices
                this.headOffices.sort((a: HeadOffice, b: HeadOffice) => a.name.localeCompare(b.name));
            },
            (responseError) => this.handleErrors(responseError)
        );
    }
}
