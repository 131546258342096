import { Component, Input, OnChanges } from '@angular/core';
import { DoughnutData } from '../../../shared/model/interface/doughnut-data';

/**
 * Batteries In Use Card component controller class
 */
@Component({ selector: 'app-batteries-in-use-card', templateUrl: './batteries-in-use-card.component.html', styleUrls: ['./batteries-in-use-card.component.scss'] })
export class BatteriesInUseCardComponent implements OnChanges {

    /**
     * The doughtnut batteries data.
     * TODO Leonardo: Remove the DoughtnutData object, using the elements of the object as the chart input instead.
     */
    batteriesChartData: DoughnutData;

    /**
     * Variable that holds if should maintain aspect ratio.
     */
    @Input() maintainAspectRatio: boolean;

    /**
     * Flag if the report is loaded.
     */
    @Input() isContentLoaded: any;

    /**
     * The number of batteries.
     */
    @Input() numberOfBatteries: number;

    /**
     * THe number of batteries out of usage.
     */
    @Input() numberOfOutOfUsageBatteries: number;

    /**
     * @see @angular/core/OnChanges/ngOnChanges()
     */
    ngOnChanges() {
        // Build the chart data.
        this.buildBatteriesChartData();
    }

    /**
     * Method responsible to create the chart object for the batteries.
     */
    private buildBatteriesChartData() {
        // set battery chart data structure
        this.batteriesChartData = {
            labels: ['Baterias em uso', 'Baterias fora de uso'],
            datasets: [
                {
                    data: [this.numberOfBatteries - this.numberOfOutOfUsageBatteries, this.numberOfOutOfUsageBatteries],
                    backgroundColor: ['rgb(186, 223, 143)', 'rgb(137, 204, 207)'],
                },
            ],
            showMiddleText: true
        };
    }
}
