import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { GenericMaintainEntityComponent } from '../../shared/generic-maintain-entity-component';
import { DeviceSoftware } from '../../shared/model/device-software.model';
import { DeviceSoftwareService } from './device-software.service';
import { isNullOrUndefined } from 'util';

/**
 * Maintain device software controller class
 */
@Component({ selector: 'app-maintain-device-software', templateUrl: './maintain-device-software.component.html', })
export class MaintainDeviceSoftwareComponent extends GenericMaintainEntityComponent<DeviceSoftware> implements OnInit {

    /**
     * The file that will be uploaded
     */
    file: File;

    /**
     * Default constructor
     *
     * @param toastrService toast service
     * @param deviceSoftwareService device software service
     * @param router router service
     * @param activatedRoute activated route service
     */
    constructor(protected toastrService: ToastrService, protected deviceSoftwareService: DeviceSoftwareService, router: Router, activatedRoute: ActivatedRoute) {
        // call super
        super(toastrService, deviceSoftwareService, router, activatedRoute, 'devicesoftwares');

        // initalize file
        this.file = null;

        // initialize model
        this.model = new DeviceSoftware();
        this.model.active = true;
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // call super
        super.ngOnInit();

        // define page title
        this.pageTitle = this.isEdition ? 'Editar versão de software do dispositivo' : 'Nova versão de software do dispositivo';
    }

    /**
     * Method responsible by saving a device software
     */
    save() {
        if (!this.isEdition && isNullOrUndefined(this.file)) {
            this.file = undefined;
        }
        else {
            // disable the submit button
            this.isSubmitDisabled = true;

            // check which operation is begin performed
            if (this.isEdition) {
                // update device software
                this.service.updateEntity(this.entityId, this.model).subscribe(
                    (responseSuccess) => {
                        // show a success message
                        this.showSuccessMessage(responseSuccess['message']);

                        // back to search page
                        this.goToSearch();
                    },
                    (responseError) => {
                        this.handleErrors(responseError);

                        // enable the submit button
                        this.isSubmitDisabled = false;
                    }
                );
            }
            else {
                // new device software
                this.deviceSoftwareService.createDeviceSoftware(this.model, this.file).subscribe(
                    (responseSuccess) => {
                        // show a success message
                        this.showSuccessMessage(responseSuccess['message']);

                        // back to search page
                        this.goToSearch();
                    },
                    (responseError) => {
                        this.handleErrors(responseError);

                        // enable the submit button
                        this.isSubmitDisabled = false;
                    }
                );
            }
        }
    }
}
