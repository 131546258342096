import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { GenericPageableSearchComponent } from '../../shared/generic-pageable-search-component.component';
import { LogEntry } from '../../shared/model/log-entry.model';
import { LogEntrySearchParameters } from '../../shared/model/search/log-entry-search-parameters.model';
import { LogEntryService } from './log-entry.service';

@Component({ selector: 'app-search-log-entry', templateUrl: './search-log-entries.component.html' })
export class SearchLogEntriesComponent extends GenericPageableSearchComponent<LogEntry> implements OnInit {

    /**
     * Filters object. Overwrites the inherited generic filter object.
     */
    filters: LogEntrySearchParameters;

    /**
     * Log entry that will be show in the modal
     */
    selectedLogEntry: LogEntry;

    /**
     * Default constructor for generic application component
     * @param toastService toast service
     * @param logEntryService service for log entry matters
     * @param router  router service
     */
    constructor(protected toastService: ToastrService, logEntryService: LogEntryService, protected router: Router) {
        // call super
        super(toastService, logEntryService, router, 'logs');
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // call super
        super.ngOnInit();

        // initialize selected log entry
        this.selectedLogEntry = new LogEntry();

        // instantiate filters
        this.filters = new LogEntrySearchParameters();

        // setup pageable object
        this.pageable.sortField = 'createdDate';
        this.pageable.sortOrder = 'DESC';

        // perform initial search
        this.search();
    }

    /**
     * Method responsible for setting period date filter after user's selection
     *
     * @param customDateSelectionEvent provided only when a custom date is selected by the component
     */
    setPeriodDateFilter(customDateSelectionEvent: any) {
        this.filters.startDate = moment(customDateSelectionEvent[0]).set({hour: 0, minute: 0, second: 0}).toDate();
        this.filters.endDate = moment(customDateSelectionEvent[1]).set({hour: 23, minute: 59, second: 59}).toDate();
    }

    /**
     * Method responsible for getting period date filter
     *
     * @returns formatted period date filter
     */
    getFormattedPeriodDateFilter(): String {
        // formatted period date filter to be returned
        let formattedPeriodDateFilter: String = null;

        // if start and end date are present, show them
        if (this.filters.startDate && this.filters.endDate) {
            formattedPeriodDateFilter = moment(this.filters.startDate).format('DD/MM/YYYY');
            formattedPeriodDateFilter += '-';
            formattedPeriodDateFilter += moment(this.filters.endDate).format('DD/MM/YYYY');
        }

        return formattedPeriodDateFilter;
    }

    /**
     * Method responsible for viewing log entry details
     *
     * @param selectedLogEntry selected log entry to be viewed
     */
    viewLogEntryDetails(selectedLogEntry: LogEntry) {
        this.selectedLogEntry = selectedLogEntry;
    }

    /**
     * Method responsible for cleaning the search parameters
     */
    cleanFilters() {
        // clear the filter with the default value
        this.filters.user = '';
        this.filters.location = '';
        this.filters.source = '';
        this.filters.startDate = null;
        this.filters.endDate = null;
    }
}
