import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';

import { GenericMaintainEntityComponent } from '../../shared/generic-maintain-entity-component';
import { SecurityManager } from '../../shared/mechanism/security-manager.service';
import { Customer } from '../../shared/model/customer.model';
import { DeviceSoftwareUpdateRequisition } from '../../shared/model/device-software-update-requisition.model';
import { UserProfile } from '../../shared/model/enum/user-profile.enum';
import { Unit } from '../../shared/model/unit.model';
import { User } from '../../shared/model/user.model';
import { CustomerService } from '../customer/customer.service';
import { DeviceSoftwareUpdateRequisitionService } from './device-software-update-requisition.service';
import { MaintainDeviceSoftwareUpdateRequisitionItemComponent } from './maintain-device-software-update-requisition-item.component';
import { DeviceSoftwareUpdateRequisitionType } from '../../shared/model/enum/device-software-update-requisition-type.enum';

/**
 * Maintain device software update requisition component controller class
 */
@Component({ selector: 'app-maintain-device-software-update-requisition', templateUrl: './maintain-device-software-update-requisition.component.html', })
export class MaintainDeviceSoftwareUpdateRequisitionComponent extends GenericMaintainEntityComponent<DeviceSoftwareUpdateRequisition> implements OnInit {

    /**
     * Customers to be loaded on options component
     */
    customers: Customer[];

    /**
     * Units to be loaded on options component
     */
    units: Unit[];

    /**
     * Property responsible for definiing the minimum date for the device software update requisition
     */
    minDeviceSoftwareUpdateRequisitionDate: Date;

    /**
     * Modal Reference for manipulating new item
     */
    modalReference: BsModalRef;

    /**
     * Flag responsible to check if is in field requisition
     */
    inFieldRequisition: boolean;

    /**
     * The default constructor
     *
     * @param toastService toast service
     * @param deviceSoftwareUpdateRequisitionService service for device software update requisition matters
     * @param customerService service for customer matters
     * @param router router service
     * @param activatedRoute activated router
     * @param securityManager mechanism to deal with security matters
     * @param modalService modal service for manipulating item
     */
    constructor(protected toastService: ToastrService, protected deviceSoftwareUpdateRequisitionService: DeviceSoftwareUpdateRequisitionService, private customerService: CustomerService, protected router: Router, protected activatedRoute: ActivatedRoute, private securityManager: SecurityManager, private modalService: BsModalService) {
        // call super
        super(toastService, deviceSoftwareUpdateRequisitionService, router, activatedRoute, 'devicesoftwareupdaterequisitions');

        // initialize model
        this.model = new DeviceSoftwareUpdateRequisition();
        this.model.items = [];
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // call super
        super.ngOnInit();

        // set minimum device software update requisition component date
        this.minDeviceSoftwareUpdateRequisitionDate = new Date();
        this.minDeviceSoftwareUpdateRequisitionDate = moment(this.minDeviceSoftwareUpdateRequisitionDate).set({ day: this.minDeviceSoftwareUpdateRequisitionDate.getDay() + 1 }).startOf('d').toDate();

        // load customers
        this.loadCustomers();

        // as soon as we have the model loaded (edition mode) cut off itself as parent
        this.modelLoaded.subscribe(() => {
            // We need to perform this instruction due to a conjunction of 2 issues:
            // - bsdatepicker only accepts pure Date objects, otherwise a 'Invalid Date' is displayed
            // - typescript generates a Date from JSON conversion from Rest API but internally it is a number, which cause the problem described above
            this.model.executionDate = new Date(this.model.executionDate);
            this.model.executionDate = new Date(this.model.executionDate.valueOf() + this.model.executionDate.getTimezoneOffset() * 60000);

            // verify if should display customer and unit
            this.setInFieldRequisition();

            if (this.inFieldRequisition) {
                // load unit
                this.loadCustomerUnits();
            }
        });

        // define page title
        this.pageTitle = this.isEdition ? 'Editar Requisição de Atualização de Software de Dispositivo' : 'Nova Requisição de Atualização de Software de Dispositivo';
    }

    /**
     * Method responsible for loading all unit after customer selection by user
     */
    loadCustomerUnits() {
        if (this.isEdition) {
            this.units = this.model.customer.units;
        }
        else if (this.model.customerId) {
            this.units = this.customers.find(c => c.id === Number(this.model.customerId)).units;
        }
        else {
            this.units = null;
        }
    }

    /**
     * Method responsible to assign business area to the user's model as soon as customer is selected
     */
    setBusinessAreaToModel() {
        // select customer object associated to user's selection
        const selectedCustomer: Customer = this.customers.find((customer: Customer) => customer.id === Number(this.model.customerId));
        if (isNullOrUndefined(selectedCustomer) ) {
            this.model.businessAreaId = null;
        }
        else {
            this.model.businessAreaId = selectedCustomer.businessAreaId;
        }
    }

    /**
     * Method responsible for opening new item popup
     */
    goToNewItem() {
        // initial state will contain a callback function
        const initialState = { itemList: this.model.items, isInFieldRequisition: this.inFieldRequisition };

        // call modal
        this.modalReference = this.modalService.show(MaintainDeviceSoftwareUpdateRequisitionItemComponent, { initialState, class: 'modal-lg' });
    }

    /**
     * Method responsible for opening an item edition popup
     *
     * @param itemId id of item that will be removed
     */
    goToEditItem(itemId: number) {
        // initial state will contain a callback function
        const initialState = {
            itemList: this.model.items.filter((item) => item.id !== itemId),
            model: this.model.items.filter((item) => item.id === itemId)[0],
            isEdition: true,
            isInFieldRequisition: this.inFieldRequisition
        };

        // call modal
        this.modalReference = this.modalService.show(MaintainDeviceSoftwareUpdateRequisitionItemComponent, { initialState, class: 'modal-lg' });
    }

    /**
     * Method responsible for removing an item
     *
     * @param itemIndex item's index to be removed
     */
    deleteItem(itemIndex: number) {
        this.model.items.splice(itemIndex, 1);
    }

    /**
     * Method responsible to check if should display customer and unit
     */
    setInFieldRequisition() {
        this.inFieldRequisition = this.model.type === DeviceSoftwareUpdateRequisitionType.IN_THE_FIELD;

        if (!this.inFieldRequisition) {
            this.model.businessArea = null;
            this.model.businessAreaId = null;
            this.model.customer = null;
            this.model.customerId = null;
            this.model.unit = null;
            this.model.unitId = null;
        }

        if (!this.isEdition) {
            this.model.items = [];
        }
    }

    /**
     * Method responsible to validate if is in filed requisition
     *
     * @returns true if in field requisition
     */
    isInFieldRequisition(): boolean {
        return this.inFieldRequisition;
    }

    /**
     * Method responsible for loading all customers
     */
    private loadCustomers() {
        // business area associated to logged user
        const loggedUser: User = this.securityManager.getLoggedUser();

        // in according to the user profile, check what customers should be seen by logged user
        if (loggedUser.profile === UserProfile.EXECUTIVE || loggedUser.profile === UserProfile.ADMIN) {
            this.customerService.getAllEntities().subscribe(
                (data) => this.assignAndSortCustomerData(data),
                (responseError) => this.handleErrors(responseError)
            );
        }
        else {
            this.customerService.getCustomersByBusinessArea(loggedUser.businessAreaId).subscribe(
                (data) => this.assignAndSortCustomerData(loggedUser.profile !== UserProfile.COORDINATOR ? data : data.filter((customer: Customer) => loggedUser.customers.findIndex((loggedUserCustomer: Customer) => customer.id === loggedUserCustomer.id) !== -1)),
                (responseError) => this.handleErrors(responseError)
            );
        }
    }

    /**
     * Method responsible for assigning and sorting customer data (callback for loading customers)
     *
     * @param customers customers to be assigned and ordered
     */
    private assignAndSortCustomerData(customers: Customer[]) {
        // get all customers
        this.customers = customers;

        // sort customers
        this.customers.sort((a: Customer, b: Customer) => a.name.localeCompare(b.name));
    }

}
