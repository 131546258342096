import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';
import { GenericComponent } from '../../../shared/generic-component.component';
import { SecurityManager } from '../../../shared/mechanism/security-manager.service';
import { AlertType } from '../../../shared/model/alert-type.model';
import { Alert } from '../../../shared/model/alert.model';
import { AlertTypeCode } from '../../../shared/model/enum/alert-type-code.enum';
import { BatteryType } from '../../../shared/model/enum/battery-type.enum';
import { UserProfile } from '../../../shared/model/enum/user-profile.enum';

/**
 * Controller class for the home alarms component view
 */
@Component({ selector: 'app-home-alerts', templateUrl: './home-alerts.component.html', styleUrls: ['home-alerts.component.scss'] })
export class HomeAlertsComponent extends GenericComponent implements OnChanges {

    /**
     * Default constructor for generic application component
     *
     * @param toastService toast service
     * @param securityManager security Manager service
     */
    constructor(protected toastService: ToastrService, private securityManager: SecurityManager) {
        // call super
        super(toastService);
    }

    /**
     * Expression representing the height of the component. For example: '700px'.
     * By setting this property, the component is able to create a scrollbar automatically,
     * if necessary.
     */
    @Input()
    height: string;

    /**
     * Expression representing the battery type used to load alerts of this specific type.
     */
    @Input()
    batteryType: BatteryType;

    /**
     * Variable holding the open alerts from the report.
     */
    @Input()
    alerts: Alert[] = [];

    /**
     * Variable holding the alert types
     */
    @Input()
    alertTypes: AlertType[] = [];

    /**
     * hold the current customer id and customer unit id.
     */
    @Input()
    homeFilter: any;

    /**
     * Variable to hold if is small device screen
     */
    @Input()
    isSmallDeviceScreen: boolean;


    /**
     * List of badges to be displayed in the table header, counting the number of alerts of each type.
     */
    badges: any[];

    /**
     * @see @angular/core/OnChanges/ngOnChanges(SimpleChanges)
     */
    ngOnChanges(changes: SimpleChanges) {
        if (this.alerts && this.alertTypes) {
            this.generateBadges();
        }
    }

    /**
     * Method responsible for generating all alert information necessary to this component
     *
     * TODO Taufic: imporve code design, to do not need stringfy and parse operations
     */
    private generateBadges() {
        // first of all is necessary to filter visible alerts
        this.alerts = this.alerts.filter(alert => alert.battery.type === this.batteryType && alert.type.batteryTypeVisibility === this.batteryType && !isNullOrUndefined(alert.type.priority));
        this.alerts = this.alerts.sort((a1, a2) => (a1.type.priority > a2.type.priority) ? 1 : -1);

        // get all alerts in order to group them
        const groupedAlerts: Map<AlertTypeCode, number> = new Map<AlertTypeCode, number>();
        this.alerts.forEach(alert => {
            groupedAlerts.set(alert.typeCode, (groupedAlerts.get(alert.typeCode) || 0) + 1);
        });

        // generate badges
        this.badges = [];
        groupedAlerts.forEach((value, key) => this.badges.push(this.buildBadge(key, value)));
    }

    /**
    * Builds a badge object from an alert type and a value.
    *
    * @param typeCode alert type code
    * @param value The value to be shown
    * @returns object that represents a badge structure
    */
    private buildBadge(alertTypeCode: AlertTypeCode, value: number): { [key: string]: any } {
        // get alert type object
        const alertType: AlertType = this.alertTypes.find(at => at.code === alertTypeCode);

        // define badge icon name
        const badgeIconName: string = 'icon-alert-' + alertType.code.toLowerCase().replace(new RegExp('_', 'g'), '-') + '-tag';
        const badgeStyleName: string = 'badge-' + alertType.code.toLowerCase().replace(new RegExp('_', 'g'), '-');

        // create badge object
        const badge = {
            icon: badgeIconName,
            badgeStyle: badgeStyleName,
            value: value,
            alertType: alertType
        };

        return badge;
    }

    /**
     * build the batteries List query params object and return it
     *
     * @param status the battery status
     * @returns query params object
     */
    buildBatteriesListQueryParams(alertTypeCode: AlertTypeCode): { [key: string]: string } {
        // The query param object with the selected status, battery type and selected unit id
        const queryParams: any = {
            alertTypeCode: alertTypeCode,
            type: this.batteryType,
        };

        // if is internal and customer id is set, get the the selected customer id
        if (this.securityManager.getLoggedUser().internal && Number(this.homeFilter.customerId) !== 0) {
            queryParams.customersId = [this.homeFilter.customerId];
        }

        // if is not external or is not a OPERATOR and customer unit id is set, get the selected unit id
        if ((this.securityManager.getLoggedUser().internal || this.securityManager.getLoggedUser().profile !== UserProfile.OPERATOR) && Number(this.homeFilter.customerUnitId)) {
            queryParams.unitId = this.homeFilter.customerUnitId;
        }

        return queryParams;
    }

    /**
     * Method responsible to verify if user can access battery details.
     *
     * @returns flag indicating if ca access battery details
     */
    canUserGoToBatteryDetails(): boolean {
        return this.securityManager.getLoggedUser().internal && !this.isSmallDeviceScreen;
    }
}

