import { Battery } from './battery.model';
import { BusinessArea } from './business-area.model';
import { Unit } from './unit.model';
import { HeadOffice } from './head-office.model';

/**
 * Model class representing a customer
 */
export class Customer {
    id: number;
    name: string;
    socialName: string;
    cnpj: string;
    businessArea: BusinessArea;
    businessAreaId: number;
    units: Unit[];
    batteries: Battery[];
    modifiedDate: Date;
    legacyCode: string;
    logoUrl: string;
    headOffice: HeadOffice;
    headOfficeId: number;
}
