/**
 * Model class representing coordinator report of tractionary batteries.
 */
export class TractionaryCoordinatorHomeReport {
    currentMonth: number;
    currentYear: number;
    monthProgress: number;
    replacementsWithDeepDischargePercentage: number;
    numberOfAlerts: number;
    highRiskAlertPercentage: number;
    numberOfBatteryReplacements: number;
    batteryIdlenessIndex: number;
    batteryAverageReplacementTime: number;
    batteryAverageWorkingTime: number;
    batteryAverageDisconnectFromChargerTime: number;
    batteryAverageWaterReplacementTime: number;
    batteryAverageCycleTime: number;
    batteryAverageChargeTime: number;
    batteryAverageRestTime: number;
    alertSummaryGroupedByType: number;
    batteryStatusSummaryForTheLastDay: any;
    batteryAverageTimeGroupedByStatus: any;
    batteryIdlenessIndexSummary: any;
    deepDischargeSummary: any;
    batteryReplacementSummary: any;
    updatedDate: Date;

    constructor() {
        this.currentMonth = 0;
        this.currentYear = 0;
        this.monthProgress = 0;
        this.replacementsWithDeepDischargePercentage = 0;
        this.numberOfAlerts = 0;
        this.highRiskAlertPercentage = 0;
        this.numberOfBatteryReplacements = 0;
        this.batteryIdlenessIndex = 0;
        this.batteryAverageReplacementTime = 0;
        this.batteryAverageWorkingTime = 0;
        this.batteryAverageDisconnectFromChargerTime = 0;
        this.batteryAverageWaterReplacementTime = 0;
        this.batteryAverageCycleTime = 0;
        this.batteryAverageChargeTime = 0;
        this.batteryAverageRestTime = 0;
        this.alertSummaryGroupedByType = 0;
        this.batteryStatusSummaryForTheLastDay = null;
        this.batteryAverageTimeGroupedByStatus = null;
        this.batteryIdlenessIndexSummary = null;
        this.deepDischargeSummary = null;
        this.batteryReplacementSummary = null;
        this.updatedDate = null;
    }
}
