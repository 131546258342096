import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GenericMaintainEntityComponent } from '../../shared/generic-maintain-entity-component';
import { BusinessArea } from '../../shared/model/business-area.model';
import { BusinessAreaService } from './business-area.service';

/**
 * Maintain business area component controller class
 */
@Component({ selector: 'app-maintain-business-area', templateUrl: './maintain-business-area.component.html' })
export class MaintainBusinessAreaComponent extends GenericMaintainEntityComponent<BusinessArea> implements OnInit {
    /**
     * Business areas to be used as filter's content
     */
    businessAreas: BusinessArea[];

    /**
     * Default constructor
     *
     * @param toastService toast service
     * @param businessAreaService service for business area matters
     * @param router router service
     * @param activatedRoute activated route service
     */
    constructor(protected toastService: ToastrService, protected businessAreaService: BusinessAreaService, protected router: Router, protected activatedRoute: ActivatedRoute) {
        // call super
        super(toastService, businessAreaService, router, activatedRoute, 'businessareas');

        // initialize model
        this.model = new BusinessArea();

        // load business areas
        this.loadBusinessAreas();
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // call super
        super.ngOnInit();

        // as soon as we have the model loaded (edition mode) cut off itself as parent
        this.modelLoaded.subscribe(() => {
            this.businessAreas = this.businessAreas.filter((a: BusinessArea) => a.id !== this.model.id);
        });

        // define page title
        this.pageTitle = this.isEdition ? 'Editar Área de Negócio' : 'Nova Área de Negócio';
    }

    /**
     * Method responsible for loading all business areas
     */
    private loadBusinessAreas() {
        this.businessAreaService.getAllEntities().subscribe(
            (data) => {
                // get all business areas
                this.businessAreas = data;

                // sort business areas
                this.businessAreas.sort((a: BusinessArea, b: BusinessArea) => a.name.localeCompare(b.name));
            },
            (responseError) => this.handleErrors(responseError)
        );
    }
}
