import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GenericMaintainEntityComponent } from '../../shared/generic-maintain-entity-component';
import { SecurityManager } from '../../shared/mechanism/security-manager.service';
import { BusinessArea } from '../../shared/model/business-area.model';
import { Customer } from '../../shared/model/customer.model';
import { UserProfile } from '../../shared/model/enum/user-profile.enum';
import { BusinessAreaService } from '../businessarea/business-area.service';
import { MaintainUnitComponent } from '../unit/maintain-unit.component';
import { CustomerService } from './customer.service';

/**
 * Maintain business area component controller class
 */
@Component({ selector: 'app-maintain-customer', templateUrl: './maintain-customer.component.html' })
export class MaintainCustomerComponent extends GenericMaintainEntityComponent<Customer> implements OnInit {

    /**
     * Business areas to be used as filter's content
     */
    businessAreas: BusinessArea[];

    /**
     * Modal Reference for manipulating new unit
     */
    modalReference: BsModalRef;

    /**
     * The file that will be uploaded
     */
    file: File;

    /**
     * Default constructor
     *
     * @param toastService toast service
     * @param customerService service for customer matters
     * @param businessAreaService service for business area matters
     * @param securityManager mechanism to deal with security matters
     * @param router router service
     * @param activatedRoute activated route service
     * @param modalService modal service for manipulating unit
     */
    constructor(protected toastService: ToastrService, protected customerService: CustomerService, private businessAreaService: BusinessAreaService, private securityManager: SecurityManager, protected router: Router, protected activatedRoute: ActivatedRoute, private modalService: BsModalService) {
        // call super
        super(toastService, customerService, router, activatedRoute, 'customers');

        // initialize model
        this.model = new Customer();
        this.model.units = [];

        // setup model's business area when user can't select it
        if (!this.canSelectBusinessArea()) {
            this.model.businessAreaId = this.securityManager.getLoggedUser().businessAreaId;
        }

        // initalize file
        this.file = null;
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // load business areas
        this.loadBusinessAreas();

        // call super
        super.ngOnInit();

        // define page title
        this.pageTitle = this.isEdition ? 'Editar Cliente' : 'Novo Cliente';
    }

    /**
     * Method responsible for indicating if user can change customer's business area
     *
     * @returns flag indicating if user can select business area
     */
    canSelectBusinessArea(): boolean {
        return this.securityManager.getLoggedUser().profile === UserProfile.ADMIN;
    }

    /**
     * Method responsible for opening new unit popup
     */
    goToNewUnit() {
        // initial state will contain a callback function
        const initialState = { unitList: this.model.units };

        // call modal
        this.modalReference = this.modalService.show(MaintainUnitComponent, { initialState, class: 'modal-lg' });
    }

    /**
     * Method responsible for opening an unit edition popup
     *
     * @param unitId id of unit that will be removed
     */
    goToEditUnit(unitId: number) {
        // initial state will contain a callback function
        const initialState = {
            unitList: this.model.units.filter((unit) => unit.id !== unitId),
            model: this.model.units.filter((unit) => unit.id === unitId)[0],
            isEdition: true
        };

        // call modal
        this.modalReference = this.modalService.show(MaintainUnitComponent, { initialState, class: 'modal-lg' });
    }

    /**
     * Method responsible for removing an unit
     *
     * @param unitIndex unit's index to be removed
     */
    deleteUnit(unitIndex: number) {
        this.model.units.splice(unitIndex, 1);
    }

    /**
     * Method responsible by saving a customer
     */
    saveCustomer() {
        // disable the submit button
        this.isSubmitDisabled = true;

        // check which operation is begin performed
        if (this.isEdition) {
            // update customer
            this.service.updateEntity(this.entityId, this.model).subscribe(
                (responseSuccess) => {
                    // show a success message
                    this.showSuccessMessage(responseSuccess['message']);

                    // back to search page
                    this.goToSearch();
                },
                (responseError) => {
                    this.handleErrors(responseError);

                    // enable the submit button
                    this.isSubmitDisabled = false;
                }
            );
        }
        else {
            // new customer
            this.customerService.createCustomer(this.model, this.file).subscribe(
                (responseSuccess) => {
                    // show a success message
                    this.showSuccessMessage(responseSuccess['message']);

                    // back to search page
                    this.goToSearch();
                },
                (responseError) => {
                    this.handleErrors(responseError);

                    // enable the submit button
                    this.isSubmitDisabled = false;
                }
            );
        }
    }

    /**
     * Method responsible for loading all business areas
     */
    private loadBusinessAreas() {
        this.businessAreaService.getAllEntities().subscribe(
            (data) => {
                // get all business areas
                this.businessAreas = data;

                // sort business areas
                this.businessAreas.sort((a: BusinessArea, b: BusinessArea) => a.name.localeCompare(b.name));
            },
            (responseError) => this.handleErrors(responseError)
        );
    }
}
