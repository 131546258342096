import { AfterContentInit, Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { BaseChartDirective } from 'ng2-charts';
import { LabeledColumn } from '../../shared/model/interface/labeled-column.interface';
import { LabeledRow } from '../../shared/model/interface/labeled-row.interface';

/**
 * Controller class for the Horizontal Bar Chart Card Component.
 */
@Component({ selector: 'app-horizontal-barchart-card', templateUrl: './horizontal-barchart-card.component.html', styleUrls: ['./horizontal-barchart-card.component.scss'] })
export class HorizontalBarchartCardComponent implements OnInit, AfterContentInit {

    /**
     * Variable to handle the chart.
     */
    @ViewChild(BaseChartDirective) chart: BaseChartDirective;

    /**
     * The title of the chart
     */
    @Input() chartTitle: string;

    /**
     * Expression representing the height of the component. For example: '700px'.
     * By setting this property, the component is able to create a scrollbar automatically,
     * if necessary.
     */
    @Input() height: number;

    /**
     * Height of each row in the graph, in pixels. Has a default value of 50.
     */
    @Input() rowHeightInPixels = 50;

    /**
     * Padding that avoid the tooltip being cut.
     */
    @Input() chartPaddingInPixels = 60;

    /**
     * Optional url for a image to be displayed at the side left of each row.
     */
    @Input() rowIconUrl: string = '';

    /**
     * The unit of the values in the chart, to de displayed in the legend.
     */
    @Input() valuesUnit: string;

    /**
     * Event emitter that emits, after the user has clicked on the chart, the LabeledRow that has been clicked.
     */
    @Output() rowClick: EventEmitter<LabeledRow> = new EventEmitter();

    /**
     * Template for the icons section
     */
    @ContentChild('iconsTemplate') iconsTemplate: TemplateRef<ElementRef>;

    /**
     * Flag indicating whether the component has finished loading its content.
     */
    contentLoaded: boolean;

    /**
     * Variable to hold labels array.
     */
    labels: any[];

    /**
     * Variable to hold datasets input
     */
    datasets: any[];

    /**
     * Variable to hold chart colors settings
     */
    chartColors: any[];

    /**
     * Variable to store chart options
     */
    options: any;

    /**
     * Rows to be displayed in the chart.
     */
    private _rows: LabeledRow[];

    /**
     * The matrix of LabeledColumns which values will be present in the ChartJS dataset.
     * The index of the element in this matrix is the same index of the corresponding item in the dataset. This can be used to retrieve
     * the correct label to be displayed in the tooltip, or handle click events, for example.
     */
    private _cols: LabeledColumn[][];

    /**
     * The color sequence of the columns that are shown a single row. The sequence can have any number of colors, and if there are more columns
     * than colors, the sequence starts to repeat itself. All the rows will follow this color sequence. Default value is blue, green and red.
     */
    private _colorsSequence = ['rgba(59,135,232,0.2)', 'rgba(66, 160, 0, 0.2)', 'rgba(237, 11, 19, 0.2)'];

    /**
     * Default constructor.
     */
    constructor() {
        this.contentLoaded = false;
    }

    /**
     * Setter for the rows property. Updates the 'this._rows' content and rearranges the dataset and other internal chart properties.
     */
    @Input()
    set rows(rows: LabeledRow[]) {
        this._rows = rows;
        this.updateChart();
    }

    /**
     * Getter for the rows property.
     */
    get rows(): LabeledRow[] {
        return this._rows;
    }

    /**
     * Sets the color sequence of the columns that are shown a single row.
     * The sequence can have any number of colors, and if there are more columns than colors, the sequence starts to repeat itself. All the rows will follow this color sequence.
     */
    @Input()
    set colorsSequence(colors: string[]) {
        this._colorsSequence = colors;
        this.updateColors();
    }

    /**
     * Gets the colors sequence.
     */
    get colorsSequence(): string[] {
        return this._colorsSequence;
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // set chart options
        this.options = {
            maintainAspectRatio: false,
            scaleShowVerticalLines: false,
            responsive: true,
            layout: {
                padding: {
                    bottom: this.chartPaddingInPixels
                }
            },
            tooltips: {
                enabled: true,
                callbacks: {
                    label: (tooltipItem) => {
                        if (isNaN(tooltipItem.xLabel)) {
                            return '';
                        }
                        // get the hours and minutes from the status
                        const hours = Math.trunc(tooltipItem.xLabel);
                        const minutes = Math.trunc((tooltipItem.xLabel * 60) % 60);
                        // display in the tooltip
                        return ` ${this._cols[tooltipItem.datasetIndex][tooltipItem.index].label}: ${hours}${this.valuesUnit ? this.valuesUnit : ''}${minutes > 0 ? minutes + 'min' : ''}`;
                    },
                    footer: (tooltipItems, data) => {
                        // get the column index and initialize the total duration
                        const index = tooltipItems[0].index;
                        let totalDuration = 0;

                        for (let i = 0; i < tooltipItems[0].datasetIndex + 1; i++) {
                            // sum the total duration with the duration of the selected column and all before
                            totalDuration += data.datasets[i].data[index];
                        }
                        // set the start time
                        const startTime = totalDuration - tooltipItems[0].xLabel;

                        // display the begin end of the status
                        return ['', `Início: ${moment().subtract(24, 'hours').add(startTime, 'hours').format('DD/MM HH:mm')}`, `Fim: ${moment().subtract(24, 'hours').add(totalDuration, 'hours').format('DD/MM HH:mm')}`];
                    }
                },
                mode: 'point'
            },
            legend: {
                display: false,
            },
            scales: {
                yAxes: [{
                    position: 'left',
                    maxBarThickness: 36,
                    stacked: true,
                    barPercentage: 0.9,
                    ticks: {
                        display: true,
                        padding: 10,
                    },
                    gridLines: {
                        drawTicks: false,
                    }
                },
                // this second Y axes is used to draw a border around the grid
                {
                    position: 'right',
                    ticks: {
                        display: false
                    },
                    gridLines: {
                        display: false,
                        drawTicks: true
                    }
                }],
                xAxes: [{
                    position: 'bottom',
                    stacked: true,
                    ticks: {
                        min: 0,
                        max: 24,
                        autoSkip: false,
                        padding: 5,
                        maxTicksLimit: 9,
                        display: true,
                        callback: (value) => {
                            return moment().subtract(24, 'hours').add(value, 'hours').format('DD/MM H') + 'h';
                        }
                    },
                    gridLines: {
                        drawTicks: false
                    }
                },
                // this second X axes is used to draw a border around the grid
                {
                    display: true,
                    position: 'top',
                    ticks: {
                        display: false
                    },
                    gridLines: {
                        display: false,
                        drawTicks: false
                    }
                }]
            },
            onClick: (_: any, elements: any[]) => {
                if (elements.length > 0) {
                    // emits the row that has been clicked.
                    this.rowClick.emit(this.rows[elements[0]._index]);
                }
            }
        };
    }

    /**
     * @see @angular/core/OnInit/ngAfterContentInit()
     */
    ngAfterContentInit() {
        this.contentLoaded = true;
    }

    /**
     * Updates the chart dataset and labels according to the content of the variable this._rows
     */
    private updateChart() {
        this.labels = [];
        this.datasets = [];

        if (this.rows && this.rows.length > 0) {
            // sets Y-axis the labels
            this.labels = this.rows.map(d => d.label);

            // retrieves the largest number of columns among the rows.
            const maxColumns = Math.max(...this.rows.map(d => d.columns.length));

            // creates a rectangular matrix with the N rows and M cols, where N is the number of LabeledRows and M
            // has the size of the largest LabeledColumn list.
            const matrix: LabeledColumn[][] = new Array(this.labels.length);

            for (let i = 0; i < matrix.length; i++) {
                matrix[i] = new Array(maxColumns).fill(0);

                for (let j = 0; j < this.rows[i].columns.length; j++) {
                    matrix[i][j] = this.rows[i].columns[j];
                }
            }

            // transposes the matrix to match the chartjs structure of dataset.
            this._cols = matrix[0].map((x, i) => matrix.map(m => m[i]));

            this.datasets = this._cols.map(row => {
                return {
                    data: row.map(col => col.value),
                    backgroundColor: row.map(col => col.backgroundColor)
                };
            });

            // Recreate the chart. Angular isn't able to update the chart correctly.
            if (this.chart) {
                this.chart.chart.destroy();
                this.chart.chart = 0;

                this.chart.datasets = this.datasets;
                this.chart.options = this.options;
                this.chart.labels = this.labels;
                this.chart.colors = this.datasets;
                this.chart.chart = this.chart.getChartBuilder(this.chart.ctx);
            }

            this.updateColors();
        }
    }

    /**
     * Updates the ChartJS color data according to the 'this._colorsSequence' content and the dataset.
     */
    private updateColors() {
        this.chartColors = [];

        if (this._colorsSequence && this._colorsSequence.length > 0 && this.rows && this.rows.length > 0) {
            // retrieves the largest number of columns among the rows.
            const maxColumns = Math.max(...this.rows.map(d => d.columns.length));

            this.chartColors = new Array(maxColumns);

            // fills the chartColors array with the colors sequence. If the sequence ends before it fills all columns,
            // starts from the beginning.
            for (let i = 0; i < this.chartColors.length; i++) {
                const color = this._colorsSequence[i % this._colorsSequence.length];

                this.chartColors[i] = {
                    backgroundColor: color,
                    pointBackgroundColor: color,
                    pointHoverBackgroundColor: color,
                    borderColor: color,
                    pointBorderColor: color,
                    pointHoverBorderColor: color
                };
            }
        }
    }
}
