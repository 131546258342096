import { Pipe, PipeTransform } from '@angular/core';
import { ScheduledTaskRunStatus } from '../model/enum/scheduled-task-run-status.enum';

/**
 * Pipe responsible to translate the scheduled task run status
 */
@Pipe({ name: 'scheduledTaskRunStatus' })
export class ScheduledTaskRunStatusPipe implements PipeTransform {

    /**
     * @see @angular/core/PipeTransform/transform(value: any, ...args: any[])
     */
    transform(scheduledTaskRunStatus: ScheduledTaskRunStatus): string {
        let scheduledTaskRunStatusName: string;

        switch (scheduledTaskRunStatus) {
            case ScheduledTaskRunStatus.FAILURE:
                scheduledTaskRunStatusName = 'Falha';
                break;
            case ScheduledTaskRunStatus.ONGOING:
                scheduledTaskRunStatusName = 'Em andamento';
                break;
            case ScheduledTaskRunStatus.NO_INFO:
                scheduledTaskRunStatusName = 'Sem informação';
                break;
            case ScheduledTaskRunStatus.SUCCESS:
                scheduledTaskRunStatusName = 'Sucesso';
                break;
            default:
                scheduledTaskRunStatusName = 'Nenhuma descrição para ' + scheduledTaskRunStatus;
                break;
        }

        return scheduledTaskRunStatusName;
    }
}
