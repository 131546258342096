import { Component, Input } from '@angular/core';

/**
 * Controller class for the Home Indicator Component.
 */
@Component({ selector: 'app-home-indicators', templateUrl: './home-indicators.component.html', styleUrls: ['./home-indicators.component.scss'] })
export class HomeIndicatorsComponent {

    /**
     * Varible to hold title input
     */
    @Input() title: string;

    /**
     * Variable to hold value input
     */
    @Input() value: string;

    /**
     * Variable to hold icon name input
     */
    @Input() iconName: string;

    /**
     * Default constructor
     */
    constructor() { }
}
