import { AfterContentChecked, Component } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { UserService } from '../../../core/user/user.service';
import { GenericComponent } from '../../generic-component.component';
import { SecurityManager } from '../../mechanism/security-manager.service';
import { UserProfile } from '../../model/enum/user-profile.enum';
import { User } from '../../model/user.model';

@Component({ selector: 'app-menu', templateUrl: './menu.component.html', styleUrls: ['./menu.component.scss'] })
export class MenuComponent extends GenericComponent implements AfterContentChecked {
    /**
     * Menu items class variable
     */
    menuItems: [{text: string, icon: string, submenu: [{text: string, link: string}]}] = [{text: '', icon: '', submenu: [{text: '', link: ''}]}];

    /**
     * Flag to control when menu needs to be rebuilt (this control fixes an issue where, after login, new permissions were not reflected at menu)
     */
    menuNeedsToBeRebuilt: boolean = true;

    /**
     * Flag to control when password redefinition modal component should be shown
     */
    hidePasswordModal: boolean = false;

    /**
     * Default constructor for menu component
     *
     * @param toastService toast service
     * @param userService user service instance
     * @param securityManager mechanism to deal with security matters
     * @param router router service
     */
    constructor(protected toasterService: ToastrService, private userService: UserService, private securityManager: SecurityManager, private router: Router, protected sanitizer: DomSanitizer) {
        // call super
        super(toasterService);
    }

    /**
     * @see @angular/core/AfterContentChecked/ngAfterContentChecked()
     */
    ngAfterContentChecked() {
        // build menu options on user
        if (this.securityManager.isSessionValid() && this.menuNeedsToBeRebuilt) {
            this.buildMenuOptions();
        }
    }

    /**
     * Method responsible for defining component's visibility
     *
     * @returns flag indicating component visibility
     */
    isComponentVisible(): boolean {
        return this.securityManager.isSessionValid();
    }

    /**
    * Method responsible for log user out and redirect to login
    */
    logout() {
        // remove user's session data
        this.securityManager.logout();

        // redirect user to login
        this.router.navigate(['/login']);

        // after logout, force menu to be rebuilt
        this.menuNeedsToBeRebuilt = true;
    }

    /**
     * Method responsible for resetting user's password
     */
    resetPassword() {
        // call security manager
        this.securityManager.resetPassword(this.getUserEmail()).subscribe(
            () => {
                // show success message
                this.showWarningMessage('Sua senha foi inutilizada com sucesso. Você receberá um e-mail com uma senha provisória para redefinição');
            },
            responseError => {
                // show error message
                this.handleErrors(responseError);
            }
        );
    }

    /**
     * Method responsible for perfoming password change, using Confirmation Code as validation
     *
     * @param password user new password
     * @param passwordConfirmation user new password confirmation
     * @param confirmationCode confirmation code
     */
    changePassword(password: string, passwordConfirmation: string, confirmationCode: string) {
        // call security manager
        this.securityManager.changePassword(this.getUserEmail(), password, passwordConfirmation, confirmationCode, false).subscribe(
            () => {
                // show success message
                this.showSuccessMessage('Senha definada com sucesso, será necessário fazer login novamente');

                // after change, hide modal
                this.hidePasswordModal = true;

                // perform logout
                this.logout();
            },
            responseError => {
                // show error message
                this.handleErrors(responseError);
            }
        );
    }

    /**
     * Method responsible for responding if user has a profile picture and if it is valid
     *
     * @returns boolean indicating if user has a valid profile picture or not
     */
    isProfilePictureAvaliable(): boolean {
        // get photo url from logged user
        const user = this.securityManager.getLoggedUser();

        return (user != null && user.photoUrl != null && user.photoUrl !== '' && !environment.local);
    }

    /**
     * Method responsible for returning the logged user name
     *
     * @returns a name or null
     */
    getUserName(): string | null {
        // return name of logged user
        return this.securityManager.getLoggedUser() != null ? this.securityManager.getLoggedUser().name : null;
    }

    /**
     * Method responsible for returning the logged user email
     *
     * @returns a e-mail or null
     */
    getUserEmail(): string | null {
        // return email of logged user
        return this.securityManager.getLoggedUser() != null ? this.securityManager.getLoggedUser().email : null;
    }

    /**
     * Method responsible for returning the logged user picture url
     *
     * @returns a photo url or null
     */
    getProfilePicture(): SafeStyle | null {
        // generate url
        const url: string = environment.storagebaseUrl + '/' + this.securityManager.getLoggedUser().photoUrl;

        // return photo url from logged user
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    /**
     * Method responsible for opening upload inpu file dialog
     *
     * @param profilePictureInputField html reference for input file in order to open it
     */
    openProfilePictureDialog(profilePictureInputField: HTMLInputElement) {
        profilePictureInputField.click();
    }

    /**
     * Method responsible for uploading a profile picture to the server
     *
     * @param fileListForUploading list of files to be uploaded
     */
    uploadProfilePicture(fileListForUploading: FileList) {
        // get first file
        const fileToBeUploaded: File = fileListForUploading.item(0);

        // call picture upload service
        return this.userService.uploadProfilePicture(fileToBeUploaded).subscribe(
            () => {
                // show sucess message
                this.showSuccessMessage('Foto atualizada com sucesso. No próximo login ela já poderá ser visualizada.');
            },
            responseError => {
                // provide generic error message
                this.showErrorMessage('Não foi possível atualizar a foto do usuário. Verifique se a foto fornecidade é realmente um formato de arquivo de foto e/ou se o tamanho da foto é apropriado');
            }
        );
    }

    /**
     * Method responsible for building menu options
     */
    private buildMenuOptions() {
        // reset menu items
        this.menuItems = [{text: '', icon: '', submenu: [{text: '', link: ''}]}];
        this.menuItems.shift();

        // add management element
        this.menuItems.push({
            text: 'Gestão da Informação',
            icon: 'icon-menubar fas fa-chart-bar',
            submenu: this.getManagementSubMenu()
        });

        // get logged user
        const loggedUser: User = this.securityManager.getLoggedUser();
        if (loggedUser.internal) {
            // add tractionary battery element
            this.menuItems.push({
                text: 'Baterias Tracionárias',
                icon: 'icon-menubar icon-menu-battery',
                submenu: this.getTractionaryBatteriesSubMenu()
            });
        }

        // add settings element
        const settingsSubMenu: [{text: string, link: string}] = this.getSettingsSubMenu();
        if (settingsSubMenu.length > 0) {
            this.menuItems.push({
                text: 'Configurações',
                icon: 'icon-menubar icon-menu-settings',
                submenu: settingsSubMenu
            });
        }

        // menu has been just rebuilt
        this.menuNeedsToBeRebuilt = false;
    }

    /**
     * Method responsible for building options for management menu
     *
     * @returns an array containing a list of options to be accessed
     */
    private getManagementSubMenu(): [{text: string, link: string}]  {
        // sub menu to be returned
        const managementSubMenu: [{text: string, link: string}] = [{text: '', link: ''}];
        managementSubMenu.shift();

        // add options to menu
        managementSubMenu.push({ text: 'Painel de Controle', link: '/home' });
        managementSubMenu.push({ text: 'Lista de Baterias', link: '/batteries/list' });
        managementSubMenu.push({ text: 'Histórico de Alertas', link: '/alerts' });

        return managementSubMenu;
    }

    /**
     * Method responsible for building options for tractionary batteries menu
     *
     * @returns an array containing a list of options to be accessed
     */
    private getTractionaryBatteriesSubMenu(): [{text: string, link: string}]  {
        // get logged user
        const loggedUser: User = this.securityManager.getLoggedUser();

        // sub menu to be returned
        const batteriesSubMenu: [{text: string, link: string}] = [{text: '', link: ''}];
        batteriesSubMenu.shift();

        // add options to menu
        batteriesSubMenu.push({ text: 'Baterias', link: '/batteries' });
        if (loggedUser.profile === UserProfile.ADMIN) {
            // if logged user is an admin, add device item
            batteriesSubMenu.push({ text: 'Dispositivos', link: '/devices' });
        }
        batteriesSubMenu.push({ text: 'Ordens de Serviço', link: '/serviceorders' });
        if (loggedUser.profile === UserProfile.ADMIN) {
            // if logged user is an admin, add device software and device software update request item
            batteriesSubMenu.push({ text: 'Versões de Software do dispositivo', link: '/devicesoftwares' });
            batteriesSubMenu.push({ text: 'Requisição de Atualização de Software de Dispositivo', link: '/devicesoftwareupdaterequisitions' });
        }

        return batteriesSubMenu;
    }

    /**
     * Method responsible for building options for settings menu considering if the logged user is internal or not
     *
     * @returns an array containing a list of options to be accessed
     */
    private getSettingsSubMenu(): [{text: string, link: string}] {
        // get logged user
        const loggedUser: User = this.securityManager.getLoggedUser();

        // sub menu to be returned
        const settingsSubMenu: [{text: string, link: string}] = [{text: '', link: ''}];
        settingsSubMenu.shift();

        // check if user is internal
        if (loggedUser.internal) {
            if (loggedUser.profile === UserProfile.ADMIN) {
                settingsSubMenu.push({text: 'Áreas de Negócio', link: '/businessareas'});
                settingsSubMenu.push({text: 'Clientes', link: '/customers' });
                settingsSubMenu.push({text: 'Matrizes', link: '/headoffices' });
                settingsSubMenu.push({text: 'Modelos', link: '/models'});
                settingsSubMenu.push({text: 'Registro de erros', link: '/logEntries'});
                settingsSubMenu.push({text: 'Tarefas Agendadas', link: '/scheduledtasks'});
                settingsSubMenu.push({text: 'Usuários', link: '/users' });
            }
            else {
                // if logged user is RSM Manager, provide access to customers
                if (loggedUser.profile === UserProfile.MANAGER) {
                    settingsSubMenu.push({text: 'Clientes', link: '/customers' });
                }

                // add common elements
                settingsSubMenu.push({text: 'Baterias', link: '/batteries'});
                settingsSubMenu.push({text: 'Ordens de Serviço', link: '/serviceorders'});
            }
        }

        return settingsSubMenu;
    }
}
