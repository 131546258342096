import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SecurityManager } from './security-manager.service';

/**
 * Interceptor class responsible for handling with all http requests that are passing thru the application
 */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

    /**
     * Default Constructor
     *
     * @param securityManager security manager
     */
    constructor(private securityManager: SecurityManager) {}

    /**
     * @see @angular/common/http/HttpInterceptor#intercept(HttpRequest<any>, HttpHandler)
     */
    intercept(httpRequest: HttpRequest<any>, httpHandler: HttpHandler): Observable<HttpEvent<any>> {
        // check if its demanded to the request an authentication token
        const authenticationToken: string = this.securityManager.getAuthenticationToken();
        const bypassURL: boolean = environment.bypassAuthenticationURLs.some(element => httpRequest.url.indexOf(element) > -1);
        if (authenticationToken != null && !bypassURL) {
            // clone the given request and add the authentication token
            const clonedHttpRequest = httpRequest.clone({ headers: httpRequest.headers.set('Authorization', authenticationToken) });

            return httpHandler.handle(clonedHttpRequest);
        }
        else {
            return httpHandler.handle(httpRequest);
        }
    }
}
