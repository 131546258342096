import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';
import { GenericPageableSearchComponent } from '../../shared/generic-pageable-search-component.component';
import { SecurityManager } from '../../shared/mechanism/security-manager.service';
import { Battery } from '../../shared/model/battery.model';
import { BatterySearchParameters } from '../../shared/model/search/battery-search-parameters.model';
import { ServiceOrderItem } from '../../shared/model/service-order-item.model';
import { ServiceOrder } from '../../shared/model/service-order.model';
import { BatteryService } from '../battery/battery.service';
import { CustomerService } from '../customer/customer.service';
import { ServiceOrderService } from './service-order.service';
import { ServiceOrderStatus } from '../../shared/model/enum/service-order-status.enum';

/**
 * Controller class for the Uninstall Service Order Component.
 */
@Component({ selector: 'app-uninstall-service-orders', templateUrl: './uninstall-service-orders.component.html' })
export class UninstallServiceOrdersComponent extends GenericPageableSearchComponent<Battery> implements OnInit {

    /**
     * Filters object. Overwrites the inherited generic filters object.
     */
    filters: BatterySearchParameters;

    /**
    * Service order used to search batteries and preform uninstallation operations
    */
    serviceOrder: ServiceOrder;

    /**
     * Service order items that will be show in details by modal
     */
    serviceOrderItems: ServiceOrderItem[] = [];

    /**
     * Variable that indicates which battery was selectd
     */

    selectedBattery: Battery;

    /**
     * Variable that holds the batteries description
     */
    batteriesDescription: Map<number, string>;

    /**
     * Variable that holds the description
     */
    description: string;

    /**
     * Default constructor for generic application component
     * @param toastService toast service
     * @param batteryService battery service
     * @param serviceOrderService service for service order matters
     * @param router router service
     * @param securityManager mechanism to deal with security matters
     * @param customerService service for customer matters
     */
    constructor(protected toastService: ToastrService, protected batteryService: BatteryService, protected serviceOrderService: ServiceOrderService, protected router: Router, private securityManager: SecurityManager, private customerService: CustomerService, private activatedRoute: ActivatedRoute) {
        // call super
        super(toastService, batteryService, router, 'batteries');
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // call super
        super.ngOnInit();

        // initalize variable
        this.batteriesDescription = new Map();
        this.description = '';


        // instantiate filters
        this.filters = new BatterySearchParameters();
        this.filters.businessAreaId = this.securityManager.getLoggedUser().businessAreaId;
        this.filters.hasDevice = true;

        // load tables
        this.loadTables();
    }

    /**
     * Method responsible for loading Service Order Items table
     */
    loadTables() {
        // load service order
        this.serviceOrderService.getEntityById(this.activatedRoute.snapshot.params['id']).subscribe(
            (data) => {
                this.serviceOrder = data;

                // check if service order was completed
                if (this.serviceOrder.status === ServiceOrderStatus.CLOSED) {
                    // go to search service order page
                    this.goToSearchServiceOrder();
                }

                // setup filters
                this.filters.customersId = [this.serviceOrder.customerId];
                this.filters.unitId = this.serviceOrder.unitId;

                // set service order items list
                this.serviceOrderItems = this.serviceOrder.items;

                // set sort
                this.pageable.sortField = 'id';

                // perform search
                this.search();
            },
            (responseError) => this.handleErrors(responseError)
        );
    }

    /**
     * Method responsible for clearing filters
     */
    cleanFilters() {
        this.filters.operationsIdentification = '';
    }

    /**
     * Method responsible for setting the property that indicates which battery was selectd
     *
     * @param battery battery that was selected
     */
    setSelectedBattery(battery: Battery) {
        this.selectedBattery = battery;
    }

    /**
     * Method responsible for setting the property that indicates the description
     *
     * @param batteryID battery related to the desired description
     */
    setModelDescription(batteryId: number) {
        this.description = this.batteriesDescription.get(batteryId);
    }

    /**
     * Method responsible for setting the description for the selected battery uninstallation
     */
    setSelectedBatteryDescription() {
        this.batteriesDescription.set(this.selectedBattery.id, this.description);
    }

    /**
    * Method responsible for redireting user to the uninstall operarion page relative to the chosen service order
    */
    goToSearchServiceOrder() {
        this.router.navigate([`/serviceorders`]);
    }

    /**
     * Method responsible for calling the service that performs the uninstall operation
     */
    uninstall() {
        this.serviceOrderService.uninstall(this.serviceOrder.id, this.selectedBattery.id, this.selectedBattery.device.id, this.batteriesDescription.get(this.selectedBattery.id)).subscribe(
            (responseSuccess) => {
                // show a success message
                this.showSuccessMessage(responseSuccess['body']['message']);

                // load tables
                this.loadTables();
            },
            (responseError) => this.handleErrors(responseError)
        );
    }

    /**
     * Method responsible for indicating if the comment related to a selected battery uninstallation is empty
     *
     * @param batteryId battery that is related with the comment
     * @returns a flag indicating if the comment related to a selected battery is empty or not
     */
    isDescriptionEmpty(batteryId: number): Boolean {
        return isNullOrUndefined(this.batteriesDescription) || isNullOrUndefined(this.batteriesDescription.get(batteryId)) || this.batteriesDescription.get(batteryId).length === 0;
    }
}
