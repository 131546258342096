import { HttpParams } from '@angular/common/http';
import { GenericSearchParameters } from './generic-search-parameters.model';

/**
 * Model class representing business area search parameters
 */
export class BusinessAreaSearchParameters implements GenericSearchParameters {
    name: string;
    ownerBusinessAreaId: number;

    /**
     * Default Constructor
     */
    constructor() {
        this.name = '';
        this.ownerBusinessAreaId = 0;
    }

    /**
     * @see GenericSearchParameters/generateHttpParams()
     */
    generateHttpParams(): HttpParams {
        // initialize parameter list
        let httpParams: HttpParams = new HttpParams();

        // assign parameter list
        if (this.name && this.name.trim().length > 0) {
            httpParams = httpParams.append('name', this.name);
        }

        if (this.ownerBusinessAreaId > 0) {
            httpParams = httpParams.append('ownerBusinessAreaId', this.ownerBusinessAreaId.toString());
        }

        return httpParams;
    }
  }
