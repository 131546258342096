import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';
import { GenericPageableSearchComponent } from '../../shared/generic-pageable-search-component.component';
import { ExportDataManager } from '../../shared/mechanism/export-data-manager.service';
import { SecurityManager } from '../../shared/mechanism/security-manager.service';
import { AlertType } from '../../shared/model/alert-type.model';
import { Battery } from '../../shared/model/battery.model';
import { Customer } from '../../shared/model/customer.model';
import { AlertTypeCode } from '../../shared/model/enum/alert-type-code.enum';
import { UserProfile } from '../../shared/model/enum/user-profile.enum';
import { BatterySearchParameters } from '../../shared/model/search/battery-search-parameters.model';
import { User } from '../../shared/model/user.model';
import { BatteryStatusPipe } from '../../shared/pipe/battery-status.pipe';
import { MeasurePipe } from '../../shared/pipe/measure.pipe';
import { AlertTypeService } from '../alert/alert-type.service';
import { CustomerService } from '../customer/customer.service';
import { BatteryService } from './battery.service';
import { HeadOffice } from '../../shared/model/head-office.model';
import { HeadOfficeService } from '../headoffice/head-office.service';

/**
 * List batteries component controller class
 */
@Component({ selector: 'app-list-batteries', templateUrl: './list-batteries.component.html', styleUrls: ['./list-batteries.component.scss'] })
export class ListBatteriesComponent extends GenericPageableSearchComponent<Battery> implements OnInit {
    /**
     * Filters object. Overwrites the inherited generic filters object.
     */
    filters: BatterySearchParameters;

    /**
     * Head offices to be used as filter's content
     */
    headOffices: HeadOffice[];

    /**
     * Variable to hold loaded customers.
     */
    loadedCustomers: Customer[];

    /**
     * Customer to be used as filter's content
     */
    customers: Customer[];

    /**
   * Alert type list
   */
    alertTypes: AlertType[] = [];

    /**
     * Default constructor for generic application component
     *
     * @param toastService toast service
     * @param batteryService service for battery matters
     * @param router router service
     * @param activateRoute activated route service
     * @param securityManager security manager
     * @param customerService service for customer matters
     * @param alertTypeService service for alert type matters
     * @param exportDataManager export data manager
     * @param batteryStatusPipe battery status pipe
     * @param alertTypePipe alert type pipe
     * @param measurePipe measure pipe
     * @param headOfficeService head office service
     */
    constructor(protected toastService: ToastrService, protected batteryService: BatteryService, protected router: Router, protected activatedRoute: ActivatedRoute, private securityManager: SecurityManager,
        private exportDataManager: ExportDataManager, private batteryStatusPipe: BatteryStatusPipe, private measurePipe: MeasurePipe,
        private customerService: CustomerService, private alertTypeService: AlertTypeService, private headOfficeService: HeadOfficeService) {
        // call super
        super(toastService, batteryService, router, 'batteries');
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // call super
        super.ngOnInit();

        // get logged user
        const loggedUser = this.securityManager.getLoggedUser();

        // check user profile
        if (loggedUser.profile === UserProfile.ADMIN || loggedUser.profile === UserProfile.EXECUTIVE) {
            // load head offices
            this.loadHeadOffices();
        }

        // load customers
        this.loadCustomers();

        // load alert types
        this.loadAlertTypes();

        // instantiate filters
        this.filters = new BatterySearchParameters();

        // setup pageable object
        this.pageable.sortField = 'operationsIdentification';

        // set the user customer id and business area in order to show information in the correct way
        this.filters.businessAreaId = loggedUser.businessAreaId;
        this.filters.customersId = loggedUser.internal && loggedUser.profile === UserProfile.COORDINATOR ? loggedUser.customers.map((customer: Customer) => customer.id) : !loggedUser.internal || (loggedUser.internal && loggedUser.profile === UserProfile.OPERATOR) ? loggedUser.customers.map((customer: Customer) => customer.id) : [0];

        // get filters from query params
        this.activatedRoute.queryParams.subscribe(
            (params) => {
                for (const key in params) {
                    if (this.filters.hasOwnProperty(key) && !isNullOrUndefined(params[key])) {
                        this.filters[key] = params[key];
                    }
                }
            }
        );

        // set the unit if user is external with OPERATOR profile
        if (!this.canUserChangeUnit()) {
            this.filters.unitId = loggedUser.unitId;
        }

        // perform initial search
        this.search();
    }

    /**
     * Method responsible for indicating if the customer information can be selected by logged user
     *
     * @returns flag responsible for indicating if the customer information can be selected by logged user
     */
    canUserChangeCustomer(): boolean {
        return this.securityManager.getLoggedUser().internal && this.securityManager.getLoggedUser().profile !== UserProfile.OPERATOR;
    }

    /**
     * Method responsible for indicating if the unit information can be selected by logged user
     *
     * @returns flag responsible for indicating if the unit information can be selected by logged user
     */
    canUserChangeUnit(): boolean {
        return this.securityManager.getLoggedUser().internal || this.securityManager.getLoggedUser().profile !== UserProfile.OPERATOR;
    }

    /**
     * Method responsible for getting the customer selected by the search filter
     *
     * @returns a customer instance or null
     */
    getSelectedCustomer(): Customer | null {
        if (this.securityManager.getLoggedUser().internal) {
            // get customers by the selected ID
            return this.customers !== undefined ? this.customers.filter((c) => this.filters.customersId[0] !== 0 && c.id === +this.filters.customersId[0])[0] : undefined;
        }
        else {
            // return external user's customer
            return this.securityManager.getLoggedUser().customers[0];
        }
    }

    /**
     * Method responsible for returning image correspondent to the battery status
     *
     * @param batteryStatus battery status
     * @param batteryType battery type
     * @returns battery status image path
     */
    getBatteryStatusImagePath(batteryStatus: string | null, batteryType: string | null): string | null {
        return isNullOrUndefined(batteryStatus) ? null : ('assets/images/ic_battery_list_state_' + batteryStatus.toLowerCase() + '_' + batteryType.toLowerCase() + '.svg');
    }

    /**
     * Method responsible for filtering current alerts to display only the correct ones
     *
     * @param battery battery owning alerts
     * @returns an array of filtered alert type coedes
     */
    getVisibleAlertsForBattery(battery: Battery): AlertTypeCode[] {
        return isNullOrUndefined(battery.currentAlerts) ? [] : battery.currentAlerts.filter((atc: AlertTypeCode) => this.alertTypes.find((at: AlertType) => at.batteryTypeVisibility === battery.type && at.code === atc && !isNullOrUndefined(at.priority)));
    }

    /**
     * Method responsible for returning image correspondent to the alert type
     *
     * @param alertTypeCode alert type code to be analyzed
     * @returns alert type image path
     */
    getAlertTypeImagePath(alertTypeCode: AlertTypeCode): string {
        return 'assets/images/ic_alert_' + alertTypeCode.toLowerCase() + '.svg';
    }

    /**
     * Method responsible for returning alert type's name
     *
     * @param alertTypeCode alert type code to be analyzed
     * @returns alert type's name
     */
    getAlertTypeName(alertTypeCode: AlertTypeCode): string {
        // get alert type
        const alertType: AlertType = this.alertTypes.find(alert => alert.code === alertTypeCode);

        // check if alert was found, then return its name
        return isNullOrUndefined(alertType) ? '-' : alertType.name;
    }

    /**
     * Method responsible for returning a css class correspondent to the battery state of charge radial
     *
     * @param batteryStateOfCharge battery's state of charge
     * @returns a css class correspondent to the battery state of charge radial or null
     */
    getBatteryStateOfChargeClass(batteryStateOfCharge: number | null): string {
        // css to be returned
        let baseCssClass: string = 'radial-bar radial-bar-sm radial-bar-primary';

        // check if base css class must be changed
        if (!isNullOrUndefined(batteryStateOfCharge)) {
            // calculate (radial componen supports only multiples of 5)
            const radialBarPercentage: number = batteryStateOfCharge - (batteryStateOfCharge % 5);

            // add base css
            baseCssClass += ' radial-bar-' + Math.round(radialBarPercentage);
        }

        return baseCssClass;
    }

    /**
     * Method responsible for returning an integer state of charge value to be used like a label
     *
     * @param batteryStateOfCharge battery's state of charge
     * @returns an integer state of charge value or null
     */
    getBatteryStateOfChargeToLabel(batteryStateOfCharge: number | null): number {
        return !isNullOrUndefined(batteryStateOfCharge) ? Math.round(batteryStateOfCharge) : batteryStateOfCharge;
    }

    /**
     * Method responsible for redirecting user to battery details information
     *
     * @param batteryId battery identifier
     */
    goToBatteryDetails(batteryId: number) {
        this.router.navigate([`/${this.routeElement}/${batteryId}/details`]);
    }

    /**
     * Method responsible to verify if user can access battery details.
     *
     * @returns flag indicating if can access battery details
     */
    canUserGoToBatteryDetails(): boolean {
        return this.securityManager.getLoggedUser().internal;
    }

    /**
     * Method responsible for cleaning the search parameters
     */
    cleanFilters() {
        // clean customer parameter if user may change it
        if (this.canUserChangeCustomer()) {
            this.filters.customersId = [0];

            // reset the customers list
            this.customers = this.loadedCustomers;
        }

        // clean unit parameter if user may change it
        if (this.canUserChangeUnit()) {
            this.filters.unitId = 0;
        }

        // return the others to default values
        this.filters.headOfficeId = 0;
        this.filters.operationsIdentification = '';
        this.filters.deviceChipIdentification = '';
        this.filters.uniqueIdentifier = '';
        this.filters.usage = '';
        this.filters.type = '';
        this.filters.status = '';
        this.filters.alertTypeCode = '';
        this.filters.hasDevice = null;
    }

    /**
     * Exports the current data content to an Excel file.
     */
    exportToExcel() {
        this.exportDataManager.exportAsExcelFile(this.results.content.map(this.buildExportableBattery.bind(this)), 'Lista de Baterias');
    }

    /**
     * Method responsible to verify if should display head office filter
     *
     * @returns flag indicating if should display head office filter
     */
    shouldDisplayHeadOfficeFilter(): boolean {
        // get logged user
        const loggedUser = this.securityManager.getLoggedUser();

        return loggedUser.profile === UserProfile.ADMIN || loggedUser.profile === UserProfile.EXECUTIVE;
    }

    /**
     * Method responsible to filter customers list by head office if necessary
     */
    filterCustomersByHeadOffice() {
        // filter the list of customer if necessary
        this.customers = Number(this.filters.headOfficeId) !== 0 ? this.loadedCustomers.filter((customer: Customer) => customer.headOfficeId === Number(this.filters.headOfficeId)) : this.loadedCustomers;

        // sort customers
        this.customers.sort((a: Customer, b: Customer) => a.name.localeCompare(b.name));
    }

    /**
     * Method responsible for loading all head offices
     */
    private loadHeadOffices() {
        this.headOfficeService.getAllEntities().subscribe(
            (data) => {
                // get all head offices
                this.headOffices = data;

                // sort head offices
                this.headOffices.sort((a: HeadOffice, b: HeadOffice) => a.name.localeCompare(b.name));
            },
            (responseError) => this.handleErrors(responseError)
        );
    }

    /**
     * Method responsible for loading all customers
     */
    private loadCustomers() {
        // get logged user
        const loggedUser: User = this.securityManager.getLoggedUser();

        // if logged user is administrator or executive show all customers
        if (loggedUser.profile === UserProfile.ADMIN || loggedUser.profile === UserProfile.EXECUTIVE) {
            // get all customers
            this.customerService.getAllEntities().subscribe(
                (data) => {
                    // get all customers
                    this.customers = data;

                    // set the loaded customers
                    this.loadedCustomers = data;

                    // sort customers
                    this.customers.sort((a: Customer, b: Customer) => a.name.localeCompare(b.name));

                    // verify if head office id is set
                    if (this.filters.headOfficeId !== 0) {
                        // filter the customers result
                        this.filterCustomersByHeadOffice();
                    }
                },
                (responseError) => this.handleErrors(responseError)
            );
        }
        else {
            // get all customers associated to a given business area
            this.customerService.getCustomersByBusinessArea(loggedUser.businessAreaId).subscribe(
                (data) => {
                    // get customers by business area
                    this.customers = loggedUser.profile !== UserProfile.COORDINATOR ? data : data.filter((customer: Customer) => loggedUser.customers.findIndex((loggedUserCustomer: Customer) => customer.id === loggedUserCustomer.id) !== -1);

                    // sort customers
                    this.customers.sort((a: Customer, b: Customer) => a.name.localeCompare(b.name));
                },
                (responseError) => this.handleErrors(responseError)
            );
        }
    }

    /**
     * Takes a Battery object and returns its representation in an Excel row, which has more presentable column names and formatted values.
     *
     * @param battery the battery to be converted.
     * @returns object that represents an exportable battery
     * - The object has the following structure:
     *      {
     *          'Identificador único': string,
     *          'Cliente': string,
     *          'Unidade': string,
     *          'Status': string,
     *          'Estado de Carga': string,
     *          'Autonomia': string,
     *          'Alertas': string,
     *          'Tensão': srting,
     *          'Temperatura': string,
     *          'Corrente': string,
     *      }
     */
    private buildExportableBattery(battery: Battery): { [key: string]: string } {
        return {
            'Identificador único': battery.uniqueIdentifier,
            'Cliente': battery.customer.name,
            'Unidade': battery.unit.description,
            'Status': this.batteryStatusPipe.transform(battery.status, battery.type),
            'Estado de Carga': !isNullOrUndefined(battery.stateOfCharge) ? battery.stateOfCharge + '%' : 'Desconhecido',
            'Autonomia': this.measurePipe.transform(battery.autonomy, 'autonomy'),
            'Alertas': battery.currentAlerts ? battery.currentAlerts.map(alertTypeCode => this.alertTypes.find(alertType => alertType.code === alertTypeCode).name).join(',') : '',
            'Tensão': this.measurePipe.transform(battery.tension, 'V'),
            'Temperatura': this.measurePipe.transform(battery.temperature, 'ºC'),
            'Corrente': this.measurePipe.transform(battery.electricCurrent, 'A'),
        };
    }

    /**
     * Method responsible for loading all alert types
    */
    private loadAlertTypes() {
        this.alertTypeService.getAllEntities().subscribe(
            (data) => {
                // get all alert types
                this.alertTypes = data;

                // sort alert types
                this.alertTypes.sort((a: AlertType, b: AlertType) => a.name.localeCompare(b.name));
            },
            (responseError) => this.handleErrors(responseError)
        );
    }
}
