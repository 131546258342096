import { Component, Input } from '@angular/core';

import { Pageable } from '../../model/pageable.model';

/**
 * Component to show the sortable table headers
 */
@Component({ selector: 'app-sortable-table-header', templateUrl: './sortable-table-header.component.html'})
export class SortableTableHeaderComponent  {
    /**
     * Table Header title
     */
    @Input() title: string;

    /**
     * Sort property name used to sort the table
     */
    @Input() sortPropertyName: string;

    /**
     * Pageable property used for displaying sort indicator
     */
    @Input() pageable: Pageable;
}
