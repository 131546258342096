import { Pipe, PipeTransform } from '@angular/core';
import { BatteryType } from '../model/enum/battery-type.enum';

/**
 * Pipe responsible to translate the battery status
 */
@Pipe({ name: 'batteryStatus' })
export class BatteryStatusPipe implements PipeTransform {

    /**
     * @see @angular/core/PipeTransform/transform(value: any, ...args: any[])
     */
    transform(batteryStatus: string | null, batteryType: BatteryType | null = null): string {
        let batteryStatusName: string;

        switch (batteryStatus) {
            case 'READY':
                batteryStatusName = batteryType ? batteryType === BatteryType.STATIONARY ? 'Em flutuação' : 'Pronto para uso' : 'Em flutuação/Pronto para uso';
                break;
            case 'RESTING':
                batteryStatusName = 'Descanso';
                break;
            case 'CHARGING':
                batteryStatusName = 'Carregando';
                break;
            case 'USING':
                batteryStatusName = 'Em uso';
                break;
            case 'STOPPED':
                batteryStatusName = 'Parada';
                break;
            case 'DISCHARGED':
                batteryStatusName = 'Descarregada';
                break;
            case 'CHARGED':
                batteryStatusName = 'Carregada';
                break;
            default:
                batteryStatusName = 'Desconhecido';
                break;
        }

        return batteryStatusName;
    }
}
