import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe responsible to translate the battery usage
 */
@Pipe({ name: 'batteryUsage' })
export class BatteryUsagePipe implements PipeTransform {

  /**
   * @see @angular/core/PipeTransform/transform(value: any, ...args: any[])
   */
  transform(batteryUsage: string): string {
    let batteryUsageName: string;

    switch (batteryUsage) {
      case 'RBS':
        batteryUsageName = 'Estação Rádio Base';
        break;
      case 'FORKLIFT':
        batteryUsageName = 'Empilhadeira';
        break;
      case 'NOBREAK':
        batteryUsageName = 'NoBreak';
        break;
      default:
        batteryUsageName =  'Nenhuma descrição para ' + batteryUsage;
        break;
    }

    return batteryUsageName;
  }
}
