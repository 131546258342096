import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';

import { GenericMaintainEntityComponent } from '../../shared/generic-maintain-entity-component';
import { SecurityManager } from '../../shared/mechanism/security-manager.service';
import { BusinessArea } from '../../shared/model/business-area.model';
import { Customer } from '../../shared/model/customer.model';
import { UserProfile } from '../../shared/model/enum/user-profile.enum';
import { Unit } from '../../shared/model/unit.model';
import { User } from '../../shared/model/user.model';
import { BusinessAreaService } from '../businessarea/business-area.service';
import { CustomerService } from '../customer/customer.service';
import { UserService } from './user.service';

/**
 * Maintain business area component controller class
 */
@Component({ selector: 'app-maintain-user', templateUrl: './maintain-user.component.html' })
export class MaintainUserComponent extends GenericMaintainEntityComponent<User> implements OnInit {
    /**
     * Business Area to be loaded on options component
     */
    businessAreas: BusinessArea[];

    /**
     * Customers to be loaded on options component
     */
    customers: Customer[];

    /**
     * Customers filtered by business area
     */
    customersFilteredByBusinessArea: Customer[];

    /**
     * Units to be laoded on options component
     */
    units: Unit[];

    /**
     * Domains for internal users. Important to define if user is internal or external
     */
    domainsForInternalUsers: string[];

    /**
     * The dropdown settings.
     */
    dropdownSettings: IDropdownSettings;

    /**
     * Default constructor
     *
     * @param toastService toast service
     * @param userService service for user matters
     * @param businessAreaService  service for business area matters
     * @param customerService  service for customer matters
     * @param securityManager mechanism to deal with security matters
     * @param router router service
     * @param activatedRoute activated router
     */
    constructor(protected toastService: ToastrService, protected userService: UserService, private businessAreaService: BusinessAreaService, private customerService: CustomerService, private securityManager: SecurityManager, protected router: Router, protected activatedRoute: ActivatedRoute) {
        // call super
        super(toastService, userService, router, activatedRoute, 'users');

        // initialize model
        this.model = new User();

        // initialize customer filtered by business area
        this.customersFilteredByBusinessArea = [];

        // initialize email domains for internal users
        this.domainsForInternalUsers = ['moura.int', 'grupomoura.com', 'itemm.org.br', 'icmoura.org', 'moura.com.br'];

        this.dropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            itemsShowLimit: 3,
            allowSearchFilter: true,
            enableCheckAll: false,
            searchPlaceholderText: 'Buscar',
            noDataAvailablePlaceholderText: 'Sem clientes disponiveis!',
            limitSelection: 1000,
        };
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // call super
        super.ngOnInit();

        // load business areas
        this.loadBusinessAreas();

        // if is edition, wait util load model
        if (this.isEdition) {
            this.modelLoaded.subscribe(() => {
                // load customers
                this.loadCustomers();
            });
        }
        else {
            // load customers
            this.loadCustomers();
        }

        // define page title
        this.pageTitle = this.isEdition ? 'Editar Usuário' : 'Novo Usuário';
    }

    /**
     * Method responsible for returning a list of available profiles for selection
     *
     * @return available user profiles
     */
    getAvailableProfiles(): UserProfile[] {
        // depending on the profile of the logged user we need to check which one should be selected by him/her
        let availableProfilesForSelection: UserProfile[] = [];

        if (this.model.email) {
            if (this.isEmailInternal(this.model.email)) {
                switch (this.securityManager.getLoggedUser().profile) {
                    case UserProfile.ADMIN:
                        availableProfilesForSelection = [UserProfile.ADMIN, UserProfile.EXECUTIVE, UserProfile.MANAGER, UserProfile.COORDINATOR, UserProfile.OPERATOR];
                        break;
                    case UserProfile.EXECUTIVE:
                        availableProfilesForSelection = [UserProfile.EXECUTIVE];
                        break;
                    case UserProfile.MANAGER:
                        availableProfilesForSelection = [UserProfile.COORDINATOR, UserProfile.OPERATOR];
                        break;
                    case UserProfile.COORDINATOR:
                        availableProfilesForSelection = [UserProfile.OPERATOR];
                        break;
                    default:
                        availableProfilesForSelection = [];
                        break;
                }
            }
            else {
                availableProfilesForSelection = [UserProfile.COORDINATOR, UserProfile.OPERATOR];
            }
        }

        return availableProfilesForSelection;
    }

    /**
     * Method responsible for checking if the customer can be selected by the user
     *
     * @returns flag indicating if the customer can be selected by the user
     */
    canUserSelectCustomer(): boolean {
        return this.model.email && (!this.isEmailInternal(this.model.email) || (this.isEmailInternal(this.model.email) && this.model.profile && (this.model.profile === UserProfile.OPERATOR || this.model.profile === UserProfile.COORDINATOR))) && !isNullOrUndefined(this.model.businessAreaId);
    }

    /**
     * Method responsible for checking if the unit can be selected by the user
     *
     * @returns flag indication if the unit can be selected by user
     */
    canUserSelectUnit(): boolean {
        return this.model.email && (!this.isEmailInternal(this.model.email) && this.model.profile === UserProfile.OPERATOR) && !isNullOrUndefined(this.model.customers) && this.model.customers.length > 0;
    }

    /**
     * Method responsible for checking if the business area can be selected by the user
     *
     * @returns flag indicating if the business area can be selected by the user
     */
    canUserSelectBusinessArea(): boolean {
        // check if the new user has the correct profile to have business area associated
        const isNewUserHasCorrectProfile: boolean = this.model.profile && this.model.profile !== UserProfile.ADMIN && this.model.profile !== UserProfile.EXECUTIVE;

        return isNewUserHasCorrectProfile;
    }

     /**
     * Method responsible for resetting model's business area, if the model's profile doesn't need it
     */
    resetModelBusinessArea() {
        // clear model's business area
        this.model.businessArea = null;
        this.model.businessAreaId = null;
    }

    /**
     * Method responsible for resetting model's customers, if the model's profile doesn't need it
     */
    resetModelCustomers() {
        // clear model's customer
        this.model.customers = [];
    }

    /**
     * Method responsible for resetting model's unit
     */
    resetModelUnit() {
        // clear model's unit
        this.model.unitId = null;
        this.model.unit = null;
    }

    /**
     * Method responsible for loading all units from the current customer
     */
    loadUnits(newCustomer: Customer) {
        const index = this.model.customers.findIndex((customer: Customer) => customer.id === newCustomer.id);
        this.model.customers[index] = this.customers.find(customer => customer.id === Number(newCustomer.id));
    }

     /**
     * Method responsible to update the dropdown limit selection
     */
    updateDropdownLimitSelection(): void {
        this.dropdownSettings = this.model.email && (this.isEmailInternal(this.model.email) && this.model.profile === UserProfile.COORDINATOR) ? Object.assign({}, this.dropdownSettings, { limitSelection: 1000 }) : Object.assign({}, this.dropdownSettings, { limitSelection: 1 });
    }

    /**
     * Method responsible to set the list of customers filtered by business area
     */
    setCustomersFilteredByBusinessArea(): void {
        this.customersFilteredByBusinessArea = this.customers.filter((customer: Customer) => customer.businessAreaId === Number(this.model.businessAreaId));
    }

    /**
     * Method responsible for checking if a given e-mail (coming from model) is internal
     *
     * @param email email to be checked
     * @returns flag indicating if a given e-mail (coming from model) is internal
     */
    private isEmailInternal(email: string): boolean {
        return this.domainsForInternalUsers.includes(email.substring(email.indexOf('@') + 1, email.length));
    }

    /**
     * Method responsible for loading all business areas
     */
    private loadBusinessAreas() {
        this.businessAreaService.getAllEntities().subscribe(
            (data) => {
                // get all business areas
                this.businessAreas = data;

                // sort business areas
                this.businessAreas.sort((a: BusinessArea, b: BusinessArea) => a.name.localeCompare(b.name));
            },
            (responseError) => this.handleErrors(responseError)
        );
    }

    /**
     * Method responsible for loading all customers
     */
    private loadCustomers() {
        // get logged user
        const loggedUser: User = this.securityManager.getLoggedUser();

        // get all customers to be filtered in according to the logged user
        this.customerService.getAllEntities().subscribe(
            (data) => {
                // filter customers if necessary
                if (loggedUser.profile === UserProfile.ADMIN || loggedUser.profile === UserProfile.EXECUTIVE) {
                    this.customers = data;
                    // set the customers Filtered By Business Area
                    this.setCustomersFilteredByBusinessArea();
                }
                else {
                    this.customers = data.filter((customer: Customer) => customer.businessAreaId === loggedUser.businessAreaId);
                }

                // sort customers
                this.customers.sort((a: Customer, b: Customer) => a.name.localeCompare(b.name));
            },
            (responseError) => this.handleErrors(responseError)
        );
    }
}
