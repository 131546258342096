import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { GenericPageableSearchComponent } from '../../shared/generic-pageable-search-component.component';
import { UserProfile } from '../../shared/model/enum/user-profile.enum';
import { DeviceSoftware } from '../../shared/model/device-software.model';
import { DeviceSoftwareSearchParameters } from '../../shared/model/search/device-software-search-parameters.model';
import { User } from '../../shared/model/user.model';
import { UserService } from '../user/user.service';
import { DeviceSoftwareService } from './device-software.service';

/**
 * Search softwares component controller class
 */
@Component({ selector: 'app-search-device-softwares', templateUrl: './search-device-softwares.component.html', })
export class SearchDeviceSoftwaresComponent extends GenericPageableSearchComponent<DeviceSoftware> implements OnInit {
    /**
     * Filters object. Overwrites the inherited generic filters object.
     */
    filters: DeviceSoftwareSearchParameters;

    /**
     * Variable responsible to hold the list of users.
     */
    users: Array<User>;

    /**
     * Default contructor for generic application component
     *
     * @param toastrService toast service
     * @param deviceSoftwareService service from device software service
     * @param router router service
     * @param userService user service
     */
    constructor(protected toastrService: ToastrService, protected deviceSoftwareService: DeviceSoftwareService, protected router: Router, private userService: UserService) {
        // call super
        super(toastrService, deviceSoftwareService, router, 'devicesoftwares');
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // call super
        super.ngOnInit();

        // instantiate filters
        this.filters = new DeviceSoftwareSearchParameters();

        // load the list of users
        this.loadUsers();

        // perfom initial search
        this.search();
    }

    /**
     * Method responsible for cleaning the search parameters
     */
    cleanFilters() {
        this.filters.userId = 0;
        this.filters.version = '';
        this.filters.releaseNotes = '';
        this.filters.active = '';
    }

    /**
     * Method responsible to load users
     */
    private loadUsers() {
        this.userService.getAllEntities().subscribe(response => this.users = response.filter((user: User) => user.internal === true && user.profile === UserProfile.ADMIN));
    }
}
