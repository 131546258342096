import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Suggestion } from './suggestion.model';

/**
 * Class which holds methods to access here's api endpoints
 */
@Injectable()
export class GeoLocationService {

    /**
     * The request parameters
     */
    private requestParameters: HttpParams;

    /**
     * Holds the here's api url
     */
    private apiUrl: Map<string, string>;

    /**
     * Default constructor
     *
     * @param httpClient http client to perform calls to the server
     */
    constructor(private httpClient: HttpClient) {
        // initialize the request parameters with the api authentication
        this.requestParameters = new HttpParams()
            .set('app_id', environment.hereAppId)
            .set('app_code', environment.hereAppCode);

        // initialize the api url array
        this.apiUrl = new Map([
            ['autocomplete', 'https://autocomplete.geocoder.api.here.com/6.2/suggest.json'],
            ['geocoder', 'https://geocoder.api.here.com/6.2/geocode.json']
        ]);
    }

    /**
     * Method responsible to get a list of unit that matches the current query.
     *
     * @param query the query of the desired unit.
     * @returns observable object containing a list of suggestion response from Here API
     */
    getSugestions(query: string): Observable<Array<Suggestion>> {
        // set the params
        const params = this.requestParameters.set('query', query)
            .set('country', 'BRA')
            .set('beginHighlight', '<b>')
            .set('endHighlight', '</b>');

        // make the request
        return this.httpClient.get<any>(this.apiUrl.get('autocomplete'), { params: params }).pipe(map((response) => response.suggestions));
    }

    /**
     * Method responsible to get a unit information using the location id.
     *
     * @param locationId the location id of the unit
     * @returns observable object containing geocode response from Here API
     */
    getGeocoder(locationId: string): Observable<any> {
        // set the params
        const params = this.requestParameters.set('locationId', locationId).set('jsonattributes', '1');

        // make the request
        return this.httpClient.get<any>(this.apiUrl.get('geocoder'), { params: params });
    }
}
