import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GenericService } from '../../shared/generic-service.service';
import { MouraConnectServer } from '../../shared/mechanism/moura-connect-server.service';
import { Customer } from '../../shared/model/customer.model';
import { isNullOrUndefined } from 'util';

/**
 * Class responsible for dealing with customer matters
 */
@Injectable()
export class CustomerService extends GenericService<Customer> {

    /**
     * Default Constuctor
     *
     * @param mouraConnectServer moura connect server instance
     */
    constructor(protected mouraConnectServer: MouraConnectServer) {
        // call super
        super('/customers', mouraConnectServer);
    }

    /**
     * Method responsible to get all customers associated to a given business area
     *
     * @param businessAreaId The business area id
     * @returns observable object containing object or an error
     */
    getCustomersByBusinessArea(businessAreaId: number): Observable<any> {
        // call method using business area information as filter
        return this.mouraConnectServer.get(`/customers/businessarea/${businessAreaId}`, null, null);
    }

    /**
     * Method responsible for creating a customer
     *
     * @param customer customer to be created
     * @param picture customer picture
     * @returns observable object containing object or an error
     */
    createCustomer(customer: Customer, picture: File): Observable<string> {
        // build specific structure to make the request
        const formData: FormData = new FormData();
        formData.append('customer', new Blob([JSON.stringify(customer)], {type: 'application/json'}));
        if (!isNullOrUndefined(picture)) {
            formData.append('picture', picture, picture.name);
        }

        return this.mouraConnectServer.post<string>(this.restAPIElement, formData, false);
    }

    /**
     * Method responsible for calling API to upload the desired picture
     *
     * @param customerId customer identification of the object which picture will be uploaded
     * @param fileToBeUploaded file to be uploaded
     */
    uploadCustomerPicture(customerId: number, fileToBeUploaded: File): Observable<any> {
        // build specific structure to send file
        const formData: FormData = new FormData();
        formData.append('picture', fileToBeUploaded, fileToBeUploaded.name);

        // call moura connect server to perform the call
        return this.mouraConnectServer.post(`/customers/${customerId}/picture`, formData, true);
    }
}
