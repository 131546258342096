import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgIdleModule } from '@ng-idle/core';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { ChartsModule } from 'ng2-charts';
import { CpfCnpjModule } from 'ng2-cpf-cnpj';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { CustomFormsModule } from 'ngx-custom-validators';
import { NgxMaskModule } from 'ngx-mask';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { routes } from './app.routes';
import { AlertTypeService } from './core/alert/alert-type.service';
import { AlertService } from './core/alert/alert.service';
import { SearchAlertsComponent } from './core/alert/search-alerts.component';
import { ApplicationService } from './core/application.service';
import { BatteryService } from './core/battery/battery.service';
import { ListBatteriesComponent } from './core/battery/list-batteries.component';
import { MeasurementService } from './core/battery/measurement.service';
import { SearchBatteriesComponent } from './core/battery/search-batteries.component';
import { ViewBatteryDataComponent } from './core/battery/view-battery-data.component';
import { MaintainBatteryComponent } from './core/battery/maintain-battery.component';
import { MaintainBatteryRoomComponent } from './core/batteryroom/maintain-battery-room.component';
import { BusinessAreaService } from './core/businessarea/business-area.service';
import { MaintainBusinessAreaComponent } from './core/businessarea/maintain-business-area.component';
import { SearchBusinessAreasComponent } from './core/businessarea/search-business-areas.component';
import { CustomerService } from './core/customer/customer.service';
import { MaintainCustomerComponent } from './core/customer/maintain-customer.component';
import { SearchCustomersComponent } from './core/customer/search-customers.component';
import { DeviceService } from './core/device/device.service';
import { SearchDevicesComponent } from './core/device/search-devices.component';
import { DeviceSoftwareService } from './core/devicesoftware/device-software.service';
import { MaintainDeviceSoftwareComponent } from './core/devicesoftware/maintain-device-software.component';
import { SearchDeviceSoftwaresComponent } from './core/devicesoftware/search-device-softwares.component';
import { HeadOfficeService } from './core/headoffice/head-office.service';
import { MaintainHeadOfficeComponent } from './core/headoffice/maintain-head-office.component';
import { SearchHeadOfficesComponent } from './core/headoffice/search-head-offices.component';
import { BatteriesInUseCardComponent } from './core/home/components/batteries-in-use-card.component';
import { BatteryAlertsSummaryCardComponent } from './core/home/components/battery-alerts-summary-card.component';
import { BatteryDeepDischargeCardComponent } from './core/home/components/battery-deep-discharge-card.component';
import { BatteryIdlenessIndexCardComponent } from './core/home/components/battery-idleness-index-card.component';
import { BatteryReplacementCardComponent } from './core/home/components/battery-replacement-card.component';
import { DoughnutChartCardComponent } from './core/home/components/doughnut-chart-card.component';
import { HomeAlertsComponent } from './core/home/components/home-alerts.component';
import { HomeDataCardComponent } from './core/home/components/home-data-card.component';
import { HomeIndicatorsComponent } from './core/home/components/home-indicators.component';
import { HomeSiteInformationComponent } from './core/home/components/home-site-information.component';
import { HomeComponent } from './core/home/home.component';
import { HorizontalBarchartCardComponent } from './core/home/horizontal-barchart-card.component';
import { StationaryHomeComponent } from './core/home/stationary-home.component';
import { TractionaryCoordinatorHomeComponent } from './core/home/tractionary-coordinator-home.component';
import { TractionaryOperatorHomeComponent } from './core/home/tractionary-operator-home.component';
import { LogEntryService } from './core/log/log-entry.service';
import { SearchLogEntriesComponent } from './core/log/search-log-entries.component';
import { LoginComponent } from './core/login/login.component';
import { MaintainModelComponent } from './core/model/maintain-model.component';
import { ModelService } from './core/model/model.service';
import { SearchModelsComponent } from './core/model/search-models.component';
import { ReportService } from './core/report/report.service';
import { ScheduledTaskService } from './core/scheduledtask/scheduled-task.service';
import { SearchScheduledTasksComponent } from './core/scheduledtask/search-scheduled-tasks.component';
import { MaintainServiceOrderComponent } from './core/serviceorder/maintain-service-order.component';
import { SearchServiceOrdersComponent } from './core/serviceorder/search-service-orders.component';
import { ServiceOrderService } from './core/serviceorder/service-order.service';
import { UninstallServiceOrdersComponent } from './core/serviceorder/uninstall-service-orders.component';
import { MaintainUnitComponent } from './core/unit/maintain-unit.component';
import { MaintainUserComponent } from './core/user/maintain-user.component';
import { SearchUsersComponent } from './core/user/search-users.component';
import { UserService } from './core/user/user.service';
import { ChartComponent } from './shared/component/chart/chart.component';
import { ErrorMessageComponent } from './shared/component/error-message/error-message.component';
import { FooterComponent } from './shared/component/footer/footer.component';
import { MapComponent } from './shared/component/map/map.component';
import { MenuComponent } from './shared/component/menu/menu.component';
import { PaginationComponent } from './shared/component/pagination/pagination.component';
import { SortableTableHeaderComponent } from './shared/component/sortable-table-header/sortable-table-header.component';
import { AutoFocusDirective } from './shared/directive/auto-focus.directive';
import { GeoLocationAutocompleteDirective } from './shared/directive/geolocation-autocomplete.directive';
import { ScrollableDirective } from './shared/directive/scrollable.directive';
import { AuthenticationGuard } from './shared/mechanism/authentication.guard';
import { DefaultErrorHandler } from './shared/mechanism/default-error-handler.service';
import { ExportDataManager } from './shared/mechanism/export-data-manager.service';
import { GeoLocationService } from './shared/mechanism/geolocation/geolocation.service';
import { HttpRequestInterceptor } from './shared/mechanism/http-request.interceptor';
import { MouraConnectServer } from './shared/mechanism/moura-connect-server.service';
import { ProfileGuard } from './shared/mechanism/profile.guard';
import { SecurityManager } from './shared/mechanism/security-manager.service';
import { AlertTypeIconPipe } from './shared/pipe/alert-type-icon.pipe';
import { BatteryStatusPipe } from './shared/pipe/battery-status.pipe';
import { BatteryTypePipe } from './shared/pipe/battery-type.pipe';
import { BatteryUsagePipe } from './shared/pipe/battery-usage.pipe';
import { CnpjPipe } from './shared/pipe/cnpj.pipe';
import { LogEntrySourcePipe } from './shared/pipe/log-entry-source.pipe';
import { MeasurePipe } from './shared/pipe/measure.pipe';
import { MonthPipe } from './shared/pipe/month.pipe';
import { ScheduledTaskRunStatusPipe } from './shared/pipe/scheduled-task-run-status.pipe';
import { ServiceOrderItemIotNetworkIssuePipe } from './shared/pipe/service-order-item-iot-network-issue.pipe';
import { ServiceOrderStatusPipe } from './shared/pipe/service-order-status.pipe';
import { ServiceOrderTypePipe } from './shared/pipe/service-order-type.pipe';
import { UserProfilePipe } from './shared/pipe/user-profile.pipe';
import { UserTypePipe } from './shared/pipe/user-type.pipe';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { SearchDeviceSoftwareUpdateRequisitionsComponent } from './core/devicesoftwareupdaterequisition/search-device-software-update-requisitions.component';
import { MaintainDeviceSoftwareUpdateRequisitionComponent } from './core/devicesoftwareupdaterequisition/maintain-device-software-update-requisition.component';
import { DeviceSoftwareUpdateRequisitionService } from './core/devicesoftwareupdaterequisition/device-software-update-requisition.service';
import { DeviceSoftwareUpdateRequisitionStatusPipe } from './shared/pipe/device-software-update-requisition-status.pipe';
import { MaintainDeviceSoftwareUpdateRequisitionItemComponent } from './core/devicesoftwareupdaterequisition/maintain-device-software-update-requisition-item.component';
import { AverageTimeCardComponent } from './core/home/components/average-time-card.component';
import { DeviceSoftwareUpdateRequisitionTypePipe } from './shared/pipe/device-software-update-requisition-type.pipe';

// define locale for NgBoostrap.
// TODO Celarino: besides the fact of the mandatority of this construction, we need to figure out a way to do it better
defineLocale('pt-br', ptBrLocale);

@NgModule({
    declarations: [
        AppComponent,
        // pipes
        AlertTypeIconPipe,
        BatteryUsagePipe, BatteryTypePipe, BatteryStatusPipe, BatteryUsagePipe,
        CnpjPipe,
        DeviceSoftwareUpdateRequisitionStatusPipe, DeviceSoftwareUpdateRequisitionTypePipe,
        LogEntrySourcePipe,
        MeasurePipe, MonthPipe,
        ServiceOrderTypePipe, ServiceOrderStatusPipe, ServiceOrderItemIotNetworkIssuePipe, ScheduledTaskRunStatusPipe,
        UserProfilePipe, UserTypePipe,
        // directives
        AutoFocusDirective,
        GeoLocationAutocompleteDirective,
        ScrollableDirective,
        // core components
        AverageTimeCardComponent,
        BatteriesInUseCardComponent, BatteryReplacementCardComponent, BatteryDeepDischargeCardComponent, BatteryAlertsSummaryCardComponent, BatteryIdlenessIndexCardComponent,
        DoughnutChartCardComponent,
        HomeComponent, HomeAlertsComponent, HomeDataCardComponent, HorizontalBarchartCardComponent, HomeSiteInformationComponent, HomeIndicatorsComponent,
        ListBatteriesComponent, LoginComponent,
        MenuComponent, MaintainBatteryComponent, MaintainBatteryRoomComponent, MaintainBusinessAreaComponent, MaintainCustomerComponent, MaintainDeviceSoftwareComponent,
        MaintainDeviceSoftwareUpdateRequisitionComponent, MaintainDeviceSoftwareUpdateRequisitionItemComponent, MaintainHeadOfficeComponent, MaintainModelComponent,
        MaintainServiceOrderComponent, MaintainUnitComponent, MaintainUserComponent,
        StationaryHomeComponent, SearchAlertsComponent, SearchBatteriesComponent, SearchBusinessAreasComponent, SearchCustomersComponent, SearchDevicesComponent, SearchDeviceSoftwaresComponent,
        SearchDeviceSoftwareUpdateRequisitionsComponent, SearchHeadOfficesComponent, SearchLogEntriesComponent, SearchModelsComponent, SearchScheduledTasksComponent, SearchServiceOrdersComponent, SearchUsersComponent,
        TractionaryOperatorHomeComponent, TractionaryCoordinatorHomeComponent,
        UninstallServiceOrdersComponent,
        ViewBatteryDataComponent,
        // shared components
        ChartComponent,
        ErrorMessageComponent,
        FooterComponent,
        MapComponent,
        PaginationComponent,
        SortableTableHeaderComponent,
    ],
    imports: [
        HttpClientModule, BrowserModule, BrowserAnimationsModule, RouterModule.forRoot(routes), CommonModule, FormsModule,
        AccordionModule.forRoot(), AlertModule.forRoot(), ButtonsModule.forRoot(), CarouselModule.forRoot(), CollapseModule.forRoot(),
        TypeaheadModule.forRoot(), DatepickerModule.forRoot(), BsDatepickerModule.forRoot(), BsDropdownModule.forRoot(), ModalModule.forRoot(),
        PaginationModule.forRoot(), ProgressbarModule.forRoot(), RatingModule.forRoot(), TabsModule.forRoot(), TimepickerModule.forRoot(),
        TooltipModule.forRoot(), PopoverModule.forRoot(), ToastrModule.forRoot(), NgHttpLoaderModule, SweetAlert2Module.forRoot(), ChartsModule,
        NgxMaskModule.forRoot(), CpfCnpjModule, NgIdleModule.forRoot(), CustomFormsModule, LeafletModule.forRoot(), NgMultiSelectDropDownModule.forRoot(), DeviceDetectorModule.forRoot()
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true }, { provide: ErrorHandler, useClass: DefaultErrorHandler },
        MouraConnectServer,
        ProfileGuard,
        // managers
        SecurityManager, AuthenticationGuard, ExportDataManager,
        // pipes
        BatteryStatusPipe, BatteryTypePipe, MeasurePipe, DatePipe, UserTypePipe, MonthPipe,
        // services
        UserService, BusinessAreaService, BatteryService, CustomerService, AlertService, AlertTypeService, ApplicationService, MeasurementService,
        ServiceOrderService, LogEntryService, DeviceService, ReportService, ModelService, GeoLocationService, ScheduledTaskService, DeviceSoftwareService, HeadOfficeService, DeviceSoftwareUpdateRequisitionService],
    entryComponents: [MaintainUnitComponent, MaintainBatteryRoomComponent, MaintainDeviceSoftwareUpdateRequisitionItemComponent],
    bootstrap: [AppComponent]
})
export class AppModule { }
