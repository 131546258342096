import { HttpParams } from '@angular/common/http';
import { GenericSearchParameters } from './generic-search-parameters.model';

/**
 * Model class representing scheduled task search parameters
 */
export class ScheduledTaskSearchParameters implements GenericSearchParameters {
    group: string;
    status: string;

    /**
     * Default Constructor
     */
    constructor() {
        this.group = '';
        this.status = '';
    }

    /**
     * @see GenericSearchParameters/generateHttpParams()
     */
    generateHttpParams(): HttpParams {
        // initialize parameter list
        let httpParams: HttpParams = new HttpParams();

        // assign group parameter
        if (this.group && this.group.trim().length > 0) {
            httpParams = httpParams.append('group', this.group);
        }

        // assign status parameter
        if (this.status && this.status.trim().length > 0) {
            httpParams = httpParams.append('status', this.status);
        }

        return httpParams;
    }
}
