import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from '../../shared/generic-service.service';
import { MouraConnectServer } from '../../shared/mechanism/moura-connect-server.service';
import { ScheduledTask } from '../../shared/model/scheduled-task.model';

/**
 * Class responsible for dealing with scheduled tasks
 */
@Injectable()
export class ScheduledTaskService extends GenericService<ScheduledTask> {
    /**
     * Default Constuctor
     *
     * @param mouraConnectServer Moura Connect server instance
     */
    constructor(protected mouraConnectServer: MouraConnectServer) {
        // call super
        super('/scheduledtasks', mouraConnectServer);
    }

    /**
     * Method responsible for executing manually a scheduled task
     *
     * @param key scheduled task's key
     * @returns observable object containing object or an error
     */
    executeScheduledTaskManually(key: string): Observable<string> {
        return this.mouraConnectServer.post<string>(`${this.restAPIElement}/${key}`, null, false);
    }
}
