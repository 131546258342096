import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';
import { GenericComponent } from '../../shared/generic-component.component';
import { SecurityManager } from '../../shared/mechanism/security-manager.service';
import { SiteStatus } from '../../shared/model/enum/site-status.enum';
import { UserProfile } from '../../shared/model/enum/user-profile.enum';
import { StationaryHomeReport } from '../../shared/model/report/stationary-home-report.model';
import { Site } from '../../shared/model/report/stationary/site.model';
import { ReportService } from '../report/report.service';
import { DeviceDetectorService } from 'ngx-device-detector';

/**
 * Home component controller class for Stationary batteries
 */
@Component({ selector: 'app-stationary-home', templateUrl: './stationary-home.component.html', styleUrls: ['stationary-home.component.scss']})
export class StationaryHomeComponent extends GenericComponent implements OnInit, OnChanges {

    /**
     * Site information list (Site information object is a model belonging to home component)
     */
    siteInformationList: Site[];

    /**
     * Number of batteries using.
     */
    numberOfBatteriesUsing: number;

    /**
     * Number of batteries charging.
     */
    numberOfBatteriesCharging: number;

    /**
     * Number of batteries ready for use.
     */
    numberOfBatteriesReady: number;

    /**
     * Number of unknown batteries.
     */
    numberOfBatteriesUnknow: number;

    /**
     * Variable to hold map's marker array
     */
    mapMarkerArray: any[];

    /**
     * Variable to hold if is small device screen
     */
    isSmallDeviceScreen: boolean;

    /**
     * hold the current customer id and customer unit id.
     */
    @Input() homeFilter: any;

    /**
     * data content the report of the stationary home.
     */
    stationaryHomeReport: StationaryHomeReport;

    /**
     * data of the batteries models.
     */
    batteriesModelsData: any[];

    /**
     * Flag if the user is internal
    */
    isInternalUser: boolean;

    /**
     * Default constructor
     *
     * @param toastService toast service
     * @param reportService report service
     * @param securityManager security Manager service
     * @param deviceService device detector service
     */
    constructor(protected toastService: ToastrService, private reportService: ReportService, private securityManager: SecurityManager, deviceService: DeviceDetectorService) {
        // call super
        super(toastService);

        // set if is small device screen
        this.isSmallDeviceScreen = deviceService.isMobile() || deviceService.isTablet();
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // set the internal user flag
        this.isInternalUser = this.securityManager.getLoggedUser().internal;

        // initialize the report
        this.clearReport();
    }

    /**
     * @see @angular/core/Onchanges/ngOnChanges()
     */
    ngOnChanges() {
        // load the stationary report
        this.getStationaryReport();
    }

    /**
     * Loads the Batteries Status indicator card content.
     */
    private loadBatteryStatusCount() {
        this.numberOfBatteriesCharging = this.stationaryHomeReport.batteryCountGroupedByStatus['CHARGING'];
        this.numberOfBatteriesReady = this.stationaryHomeReport.batteryCountGroupedByStatus['READY'];
        this.numberOfBatteriesUnknow = this.stationaryHomeReport.batteryCountGroupedByStatus['UNKNOW'];
        this.numberOfBatteriesUsing = this.stationaryHomeReport.batteryCountGroupedByStatus['USING'];
    }

    /**
     * Method responsible for loading map markers
     */
    private loadMapMarkers() {
        // clean array
        this.mapMarkerArray = [];

        // iterate in site informations
        this.siteInformationList.forEach((siteInformation: Site) => {
            // add a new marker with information regarding a site
            this.mapMarkerArray.push({
                latitude: siteInformation.unit.latitude,
                longitude: siteInformation.unit.longitude,
                iconUrl: siteInformation.status === SiteStatus.CRITICAL ? 'assets/images/ic_pin_red.svg' : (siteInformation.status === SiteStatus.ON_ALERT ? 'assets/images/ic_pin_yellow.svg' : 'assets/images/ic_pin_green.svg'),
                tooltipText: (siteInformation.unit.description)
            });
        });
    }

    /**
     * Method responsible to load the report of the stationary home.
     */
    private getStationaryReport() {
        let stationaryHomeReportObservable = null;

        if (this.homeFilter.customerUnitId > 0) {
            // set the resquest to get the stationary report by customer unit id
            stationaryHomeReportObservable = this.reportService.getStationaryHomeReportByUnit(this.homeFilter.customerUnitId);
        }
        else if (this.homeFilter.customerId > 0) {
            // set the resquest to get the stationary report by customer id
            stationaryHomeReportObservable = this.reportService.getStationaryHomeReportByCustomer(this.homeFilter.customerId);
        }
        else {
            this.clearReport();
        }

        if (stationaryHomeReportObservable) {
            // perform request and treat response
            stationaryHomeReportObservable.subscribe(
                (response) => {
                    // get the response data
                    this.stationaryHomeReport = response;

                    // method responsible loading battery status count
                    this.loadBatteryStatusCount();

                    // create the battery models card
                    this.buildBatteriesModel();

                    // set site information
                    this.siteInformationList = this.stationaryHomeReport.sites;

                    // method responsible for populating marker array
                    this.loadMapMarkers();

                },
                (responseError) => {
                    // show error messages
                    this.handleErrors(responseError);
                }
            );
        }
    }

    /**
     * Method responsible to clear the variables.
     */
    clearReport() {
        this.siteInformationList = [];
        this.numberOfBatteriesCharging = 0;
        this.numberOfBatteriesReady = 0;
        this.numberOfBatteriesUnknow = 0;
        this.numberOfBatteriesUsing = 0;
        this.batteriesModelsData = [];
        this.stationaryHomeReport = new StationaryHomeReport();
    }

    /**
     * Verify if should display the report data.
     */
    shouldDisplayReportInformation() {
        return this.stationaryHomeReport && this.stationaryHomeReport.numberOfBatteries > 0;
    }

    /**
     * build the batteries List query params object and return it
     *
     * @param status the battery status
     * @returns query params object
     */
    buildBatteriesListQueryParams(status: string): { [key: string]: string } {
        // The query param object with the selected status, battery type and selected unit id
        const queryParams: any = {
            status: status,
            type: 'STATIONARY',
        };

        // if is internal and head office id is set, get the selected head office id
        if (this.isInternalUser && Number(this.homeFilter.headOfficeId) !== 0) {
            queryParams.headOfficeId = this.homeFilter.headOfficeId;
        }

        // if is internal and customer id is set, get the the selected customer id
        if (this.isInternalUser && Number(this.homeFilter.customerId) !== 0) {
            queryParams.customersId = [this.homeFilter.customerId];
        }

        // if is not external or is not a OPERATOR and customer unit id is set, get the selected unit id
        if ((this.isInternalUser || this.securityManager.getLoggedUser().profile !== UserProfile.OPERATOR) && Number(this.homeFilter.customerUnitId)) {
            queryParams.unitId = this.homeFilter.customerUnitId;
        }

        return queryParams;
    }

    /**
     * Method responsible for defining if the last data update info must be displayed
     *
     * @returns flag indicating if the last data update info must be displayed
     */
    shouldShowLastUpdateInformation(): boolean {
        return !isNullOrUndefined(this.stationaryHomeReport.updatedDate);
    }

    /**
     * Method responsible to create the array of models
     */
    private buildBatteriesModel() {
        const batteryModels = this.stationaryHomeReport.batteryCountGroupedByModel;

        this.batteriesModelsData = [];

        for (const model in batteryModels) {
            if (batteryModels.hasOwnProperty(model)) {
                this.batteriesModelsData.push(
                    {
                        model: model,
                        number: batteryModels[model],
                        percentage: (100 * batteryModels[model]) / this.stationaryHomeReport.numberOfBatteries,
                    }
                );
            }
        }
    }
}
