import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';

import { GenericComponent } from '../../../shared/generic-component.component';
import { AlertType } from '../../../shared/model/alert-type.model';
import { AlertTypeCode } from '../../../shared/model/enum/alert-type-code.enum';
import { MonthPipe } from '../../../shared/pipe/month.pipe';
import { AlertTypeService } from '../../alert/alert-type.service';
import { DeviceDetectorService } from 'ngx-device-detector';

/**
 * Batteries Alert Summary Card component controller class
 */
@Component({ selector: 'app-battery-alerts-summary-card', templateUrl: './battery-alerts-summary-card.component.html', styleUrls: ['./battery-alerts-summary-card.component.scss'] })
export class BatteryAlertsSummaryCardComponent extends GenericComponent implements OnInit, OnChanges {

    /**
     * Variable to handle the chart.
     */
    @ViewChild(BaseChartDirective) chart: BaseChartDirective;

    /**
     * Flag indicating whether the component has finished loading its content.
     */
    contentLoaded: boolean;

    /**
     * Variable to hold labels array.
     */
    labels: Array<string>;

    /**
     * Variable to hold data array.
     */
    data: Array<number>;

    /**
     * Variable to hold datasets input
     */
    datasets: any[];

    /**
     * Variable to store chart options
     */
    options: any;

    /**
     * Padding that avoid the tooltip being cut.
     */
    chartPaddingInPixels;

    /**
     * Variable to hold if is small device screen
     */
    isSmallDeviceScreen: boolean;

    /**
     * Alert type list
     */
    private alertTypes: Array<AlertType>;

    /**
     * The array of chart alert
     */
    private chartAlerts: Array<AlertType>;

    /**
     * The battery alerts summary chart data.
     */
    @Input() batteryAlertsSummaryChartData: any;

    /**
     * Variable to hold the number of alerts
     */
    @Input() numberOfAlerts: number;

    /**
     * Variable to hold the percentage of high risk alerts
     */
    @Input() highRiskAlertPercentage: number;

    /**
     * Default constructor
     *
     * @param toastService toast service
     * @param monthPipe month pipe
     * @param alertTypeService service for alert type matters
     * @param deviceService device detector service
     */
    constructor(toastService: ToastrService, private monthPipe: MonthPipe, private alertTypeService: AlertTypeService, deviceService: DeviceDetectorService) {
        // call super
        super(toastService);

        // set if is small device screen
        this.isSmallDeviceScreen = deviceService.isMobile() || deviceService.isTablet();

        // initialize variables
        this.contentLoaded = false;
        this.chartAlerts = [];
        this.chartPaddingInPixels = 50;

        // set chart options
        this.options = {
            maintainAspectRatio: true,
            scaleShowVerticalLines: false,
            responsive: true,
            layout: {
                padding: {
                    bottom: this.chartPaddingInPixels
                }
            },
            tooltips: {
                enabled: true,
                mode: 'point',
                callbacks: {
                    title: (tooltipItems) => {
                        // Return value for title
                        return tooltipItems[0].yLabel[tooltipItems[0].datasetIndex];
                    },
                    label: (tooltipItem, data) => {
                        const dataset = data.datasets[tooltipItem.datasetIndex];
                        const index = tooltipItem.index;
                        // return value for label
                        return `${dataset.labels[index].name}: ${dataset.data[index]}`;
                    }
                }
            },
            legend: {
                display: false,
            },
            scales: {
                yAxes: [{
                    position: 'left',
                    barPercentage: 1,
                    categoryPercentage: 0.8,
                    ticks: {
                        display: true,
                        padding: 10,
                    },
                    gridLines: {
                        drawTicks: false,
                    }
                },
                // this second Y axes is used to draw a border around the grid
                {
                    position: 'right',
                    ticks: {
                        display: false
                    },
                    gridLines: {
                        display: false,
                        drawTicks: true,
                        drawBorder: false,
                    }
                }],
                xAxes: [{
                    position: 'bottom',
                    ticks: {
                        display: false,
                    },
                    gridLines: {
                        drawTicks: false,
                        drawOnChartArea: false,
                        drawBorder: false,
                    }
                },
                // this second X axes is used to draw a border around the grid
                {
                    display: true,
                    position: 'top',
                    ticks: {
                        display: false
                    },
                    gridLines: {
                        display: false,
                        drawTicks: false
                    }
                }],
            }
        };
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // load the alert types
        this.loadAlertTypes();
    }

    /**
     * @see @angular/core/OnChange/ngOnChanges()
     */
    ngOnChanges() {
        // build the chart
        this.buildChart();
    }

    /**
     * Method responsible to return the alert image path
     *
     * @param alertTypeCode the alert type code
     * @returns the image path
     */
    public getAlertImage(alertTypeCode: AlertTypeCode): string {
        return `assets/images/ic_alert_${alertTypeCode.toLowerCase()}.svg`;
    }

    /**
     * Method responsible to build the chart, setting the chart label, data and background color.
     */
    private buildChart() {
        // create the battery alerts summary chart data
        this.buildBatteryAlertsSummaryChartData();

        // Recreate the chart. Angular isn't able to update the chart correctly.
        if (this.chart && this.contentLoaded) {
            // destroy the current chart.
            this.chart.chart.destroy();
            this.chart.chart = 0;

            // set the values, labels, colors and options to the new chart.
            this.chart.datasets = this.datasets;
            this.chart.options = this.options;
            this.chart.labels = this.labels;
            this.chart.colors = this.datasets;

            // build the chart with the new values.
            this.chart.chart = this.chart.getChartBuilder(this.chart.ctx);
        }
    }

    /**
     * Method responsible to create the object for the battery alerts summary chart.
     */
    private buildBatteryAlertsSummaryChartData() {
        // initialize variables
        let monthLabels = [];
        const alertValues = [];
        const chartData: Array<{ 'year': number, 'month': number, 'value': any }> = [];

        this.chartAlerts = [];
        this.contentLoaded = false;

        // build the chart data array
        for (const month in this.batteryAlertsSummaryChartData) {
            if (this.batteryAlertsSummaryChartData.hasOwnProperty(month)) {
                chartData.push({ 'year': Number(month.split('|')[0]), 'month': Number(month.split('|')[1]), 'value': this.batteryAlertsSummaryChartData[month] });
            }
        }

        // sort the chart data by month
        chartData.sort((a, b) => a.year - b.year || a.month - b.month);

        // iterate inside the object to get the list of month labels and alert types
        monthLabels = chartData.map(d => this.monthPipe.transform(d.month));
        chartData.map(d => Object.keys(d.value).map(alert => {
            // get the current alert type
            const alertType = !isNullOrUndefined(this.alertTypes) ? this.alertTypes.find(at => at.code === alert) : null;
            // verify if was already insert
            if (alertType !== null && this.chartAlerts.indexOf(alertType) === -1) {
                // push the alert type
                this.chartAlerts.push(alertType);
            }
        }));

        // check if has value
        if (this.chartAlerts.length > 0) {
            // loop inside the object to generate the chart data
            chartData.map(d => d.value).map(alertValue => {
                // initialize the array
                const data = [];
                // loop through the alerts
                for (const alert of this.chartAlerts) {
                    // push the value if present, otherwise push 0
                    data.push(alertValue[alert.code] ? alertValue[alert.code] : 0);
                }
                // push the array of values
                alertValues.push(data);
            });

            // create the array of labels with the months
            this.labels = Array(this.chartAlerts.length).fill(monthLabels);

            // set the datasets
            this.datasets = [
                {
                    labels: this.chartAlerts,
                    backgroundColor: 'rgb(29,115,181,0.8)',
                    data: alertValues[0]
                },
                {
                    labels: this.chartAlerts,
                    backgroundColor: 'rgb(61,138,201,0.8)',
                    data: alertValues[1]
                },
                {
                    labels: this.chartAlerts,
                    backgroundColor: 'rgb(116,168,212,0.8)',
                    data: alertValues[2]
                },
            ];

            // set content loaded
            this.contentLoaded = true;
        }
    }

    /**
     * Method responsible for loading all alert types
    */
    private loadAlertTypes() {
        this.alertTypeService.getAllEntities().subscribe(
            (data) => {
                // get all alert types
                this.alertTypes = data;

                // build the chart
                this.buildChart();

                // sort alert types
                this.alertTypes.sort((a: AlertType, b: AlertType) => a.name.localeCompare(b.name));
            },
            (responseError) => this.handleErrors(responseError)
        );
    }
}
