import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';
import { GenericComponent } from '../../shared/generic-component.component';
import { Suggestion } from '../../shared/mechanism/geolocation/suggestion.model';
import { BatteryRoom } from '../../shared/model/battery-room.model';
import { Unit } from '../../shared/model/unit.model';

/**
 * Maintain unit component controller class
 */
@Component({ selector: 'app-maintain-unit-modal', templateUrl: './maintain-unit.component.html' })
export class MaintainUnitComponent extends GenericComponent implements OnInit {
    /**
     * Unit model
     */
    model: Unit;

    /**
     * Updated unit model
     */
    updatedModel: Unit;

    /**
     * Variable responsible to hold the suggested addresses.
     */
    suggestedAddresses: Array<Suggestion>;

    /**
     * Variable responsible to hold the selected location id.
     */
    locationId: string;

    /**
     * Variable responsible to hold the page title string
     */
    pageTitle: string;

    /**
     * Variable responsible to hold the flag that indicates if the current operation is an edition
     */
    isEdition: boolean = false;

    /**
     * Variable responsible to hold the flag that indicates if the current operation is an edition
     */
    isNewBatteryRoomCardVisible = false;

    /**
     * Default constructor
     *
     * @param modalReference reference to modal opener
     * @param toastService toast service
     */
    constructor(public modalReference: BsModalRef, protected toastService: ToastrService) {
        // call super
        super(toastService);

        // initialize model
        this.model = new Unit();
    }

    /**
    * @see @angular/core/OnInit/ngOnInit()
    */
    ngOnInit() {
        // define page title
        this.pageTitle = this.isEdition ? 'Editar Unidade' : 'Nova Unidade';

        // init model that will be updated
        this.updatedModel = Object.create(this.model);
    }

    /**
     * Method responsible for adding a new unit or editing an existent one by calling a callback
     */
    recordUnit() {
        // check if its a edition
        if (this.isEdition) {
            // copy update model's properties to model
            Object.keys(this.updatedModel).forEach(key => this.model[key] = this.updatedModel[key]);
        }
        // if it's a creation
        else {
            // check if list must be initialized
            if (this.modalReference.content.unitList === undefined) {
                this.modalReference.content.unitList = [];
            }

            // callback to push the updated reference
            this.modalReference.content.unitList.push(this.updatedModel);
        }

        // hide modal
        this.modalReference.hide();
    }

    /**
     * Method responsible for cancelling unit maintainability
     */
    cancel() {
        // clear model in order to avoid the opener getting trash
        this.model = new Unit();

        // close it
        this.modalReference.hide();
    }

    /**
     * Method responsible for removing an battery rooom
     *
     * @param batteryRoomIndex room's index to be removed
     */
    deleteBatteryRoom(batteryRoomIndex: number) {
        this.model.batteryRooms.splice(batteryRoomIndex, 1);
    }

    /**
     * Method responsible for indicating if the battery room creation card is visible
     */
    shouldShowNewBatteryRoomCard(): boolean {
        return this.isNewBatteryRoomCardVisible;
    }

    /**
     * Method responsible for turning battery room creation card visible
     */
    showNewBatteryRoomCard() {
        this.isNewBatteryRoomCardVisible = true;
    }

    /**
     * Method responsible for adding a new battery room input and hidding the creation card
     */
    consumeBatteryRoom(batteryRoom: BatteryRoom) {
        // check if the room list is already initialized
        if (isNullOrUndefined(this.model.batteryRooms)) {
            this.model.batteryRooms = [];
        }

        // push the new room
        this.model.batteryRooms.push(batteryRoom);

        // set varible responsible for hidding the battery room creation card
        this.isNewBatteryRoomCardVisible = false;
    }
}
