import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import cronstrue from 'cronstrue';
import { ToastrService } from 'ngx-toastr';
import { GenericPageableSearchComponent } from '../../shared/generic-pageable-search-component.component';
import { ScheduledTask } from '../../shared/model/scheduled-task.model';
import { ScheduledTaskSearchParameters } from '../../shared/model/search/scheduled-task-search-parameters.model';
import { ScheduledTaskService } from './scheduled-task.service';

/**
 * Search scheduled tasks component controller class
 */
@Component({ selector: 'app-search-scheduld-tasks', templateUrl: './search-scheduled-tasks.component.html', })
export class SearchScheduledTasksComponent extends GenericPageableSearchComponent<ScheduledTask> implements OnInit {

    /**
     * Filters object. Overwrites the inherited generic filter object.
     */
    filters: ScheduledTaskSearchParameters;

    /**
     * Scheduled task that will be show in the modal
     */
    selectedScheduledTask: ScheduledTask;

    /**
     * Default constructor for generic application component
     * @param toastService toast service
     * @param logEntryService service for log entry matters
     * @param router  router service
     */
    constructor(protected toastService: ToastrService, protected scheduledTasksService: ScheduledTaskService, protected router: Router) {
        // call super
        super(toastService, scheduledTasksService, router, 'logs');
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // call super
        super.ngOnInit();

        // instantiate filters
        this.filters = new ScheduledTaskSearchParameters();

        // setup pageable object
        this.pageable.sortField = 'group';
        this.pageable.sortOrder = 'ASC';

        // perform initial search
        this.search();
    }

    /**
     * Method responsible for executing manually a scheduled task
     * @param key scheduled task's key
     */
    executeManuallyScheduledTask(key: string) {
        this.scheduledTasksService.executeScheduledTaskManually(key).subscribe(
            () => {
                // show a success message
                this.showSuccessMessage('Tarefa agendada executada manualmente com sucesso');

                // redo the search
                this.search();
            },
            () => {
                // show error message
                this.showErrorMessage('Não foi possível executar a tarefa agendada, por favor verifique os detalhes abaixo');

                // redo the search
                this.search();
            }
        );
    }


     /**
     * Method responsible for viewing scheduled task
     * @param selectedScheduledTask selected scheduled task to be viewed
     */
    viewScheduledTaskDetails(selectedScheduledTask: ScheduledTask) {
        this.selectedScheduledTask = selectedScheduledTask;
    }

    /**
     * Method responsible for translating a cron expression in words
     * @param cronExpression expression to be translated
     * @returns cron expression translated
     */
    getCronDescription(cronExpression: string): string {
        return cronstrue.toString(cronExpression, { locale: 'pt_BR', use24HourTimeFormat: true });
    }
}
