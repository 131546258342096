import { Site } from './stationary/site.model';

/**
 * Model class representing report of stationary batteries.
 */
export class StationaryHomeReport {
    numberOfOutOfUsageBatteries: number;
    numberOfBatteries: number;
    batteryCountGroupedByStatus: any;
    batteryCountGroupedByModel: any;
    sites: Site[];
    updatedDate: Date;

    constructor() {
        this.numberOfOutOfUsageBatteries = 0;
        this.numberOfBatteries = 0;
        this.batteryCountGroupedByStatus = null;
        this.batteryCountGroupedByModel = null;
        this.sites = [];
        this.updatedDate = null;
    }
}
