import { Injectable } from '@angular/core';
import { GenericService } from '../../shared/generic-service.service';
import { MouraConnectServer } from '../../shared/mechanism/moura-connect-server.service';
import { LogEntry } from '../../shared/model/log-entry.model';

/**
 * Class responsible for dealing with additional logging matters
 */
@Injectable()
export class LogEntryService extends GenericService<LogEntry> {
    /**
     * Default Constuctor
     *
     * @param mouraConnectServer Moura Connect server instance
     */
    constructor(protected mouraConnectServer: MouraConnectServer) {
        // call super
        super('/logs', mouraConnectServer);
    }
}
