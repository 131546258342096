import { AfterViewInit, Directive, ElementRef } from '@angular/core';

/**
 * Directive responsible for automatically setting focus to given field
 */
// tslint:disable-next-line:directive-selector
@Directive({selector: '[focus]'})
export class AutoFocusDirective implements AfterViewInit {

    /**
     * Default Constructor
     */
    constructor(private el: ElementRef) {
    }

    /**
     * @angular/core/AfterViewInit/ngAfterViewInit()
     */
    ngAfterViewInit() {
      this.el.nativeElement.focus();
    }
}
