import { Component, Input } from '@angular/core';
import * as moment from 'moment';

@Component({ selector: 'app-average-time-card', templateUrl: './average-time-card.component.html', styleUrls: [ './average-time-card.component.scss' ] })
export class AverageTimeCardComponent {
    /**
     * title of average time card component
     */
    @Input() title: string;

    /**
     * Array of average times of average time card component
     */
    @Input() averageTimes: Object[];

    /**
     * Flag that indicate if card is full height
     */
    @Input() isFullHeight: boolean;

    /**
     * Default constructor
     */
    constructor() {}

    /**
     * Converts average time values to hours and minutes
     *
     * @param averageTimeValue the average time value
     * @returns average time values to hours and minutes
     */
    convertAverageTimeValuesToHoursAndMinutes(averageTimeValue: number): string {
        return String(moment.duration(averageTimeValue, 'millisecond').hours()).padStart(2, '0') + 'h ' + String(moment.duration(averageTimeValue, 'millisecond').minutes()).padStart(2, '0') + 'm';
    }
}
