import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe responsible to translate the service order status
 */
@Pipe({ name: 'serviceOrderStatus' })
export class ServiceOrderStatusPipe implements PipeTransform {

    /**
   * @see @angular/core/PipeTransform/transform(value: any, ...args: any[])
   */
    transform(serviceOrderStatus: string): string {
        let serviceOrderStatusName: string;

        switch (serviceOrderStatus) {
            case 'OPEN':
                serviceOrderStatusName = 'Em aberto';
                break;
            case 'ONGOING':
                serviceOrderStatusName = 'Em andamento';
                break;
            case 'CANCELLED':
                serviceOrderStatusName = 'Cancelada';
                break;
            case 'CLOSED':
                serviceOrderStatusName = 'Concluída';
                break;
            default:
                serviceOrderStatusName = 'Nenhuma descrição para ' + serviceOrderStatus;
                break;
        }

        return serviceOrderStatusName;
    }
}