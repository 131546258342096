import { Injectable } from '@angular/core';
import { GenericService } from '../../shared/generic-service.service';
import { Model } from '../../shared/model/model.model';
import { MouraConnectServer } from '../../shared/mechanism/moura-connect-server.service';

/**
 * Class responsible for dealing with mdoel mmatters
 */
@Injectable()
export class ModelService extends GenericService<Model> {

    /**
     * Default constructor
     * @param mouraConnectServer moura connect server instance
     */
    constructor(protected mouraConnectServer: MouraConnectServer) {
        // call super
        super('/models', mouraConnectServer);
    }
}
