import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';

import { GenericComponent } from '../../shared/generic-component.component';
import { SecurityManager } from '../../shared/mechanism/security-manager.service';
import { BatteryRoom } from '../../shared/model/battery-room.model';
import { Customer } from '../../shared/model/customer.model';
import { BatteryType } from '../../shared/model/enum/battery-type.enum';
import { Preferences } from '../../shared/model/enum/preferences.enum';
import { UserProfile } from '../../shared/model/enum/user-profile.enum';
import { HeadOffice } from '../../shared/model/head-office.model';
import { Unit } from '../../shared/model/unit.model';
import { User } from '../../shared/model/user.model';
import { CustomerService } from '../customer/customer.service';
import { HeadOfficeService } from '../headoffice/head-office.service';

/**
 * Home component controller class
 */
@Component({ selector: 'app-home', templateUrl: './home.component.html', styleUrls: ['./home.component.scss'] })
export class HomeComponent extends GenericComponent implements OnInit {

    /**
     * Holds the head offices for the filter.
     */
    headOffices: HeadOffice[];

    /**
     * Holds the selected head office id.
     */
    headOfficeId: number;

    /**
     * Holds the loaded customers.
     */
    loadedCustomers: Customer[];

    /**
     * Holds the customers for the filter.
     */
    customers: Customer[];

    /**
     * Holds the selected customer id.
     */
    customerId: number;

    /**
     * Holds the customer units for the filter.
     */
    customerUnits: Unit[];

    /**
     * Holds the selected customer unit id.
     */
    customerUnitId: number;

    /**
     * Holds the selected unit battery room id.
     */
    unitBatteryRoomId: number;

    /**
     * Holds the unit battery rooms for the filter.
     */
    unitBatteryRooms: BatteryRoom[];

    /**
     * Holds if the tractionary home is selected
     */
    isTractionaryHomeSelected: boolean = true;

    /**
     * Holds if the tractionary operator home is selected
     */
    isTractionaryOperatorHomeSelected: boolean = true;

    /**
     * The dropdown settings.
     */
    dropdownSettings: IDropdownSettings;

    /**
     * Current customer filter of home component
     */
    currentCustomerFilter: Customer[];

    /**
     * Default constructor
     *
     * @param toastrService toastr service
     * @param securityManager security manager service
     * @param customerService customer service
     * @param headOfficeService head office service
     */
    constructor(protected toastrService: ToastrService, private securityManager: SecurityManager, private customerService: CustomerService, private headOfficeService: HeadOfficeService) {
        super(toastrService);
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // set the dropdown settings
        this.dropdownSettings = {
            singleSelection: true,
            idField: 'id',
            textField: 'name',
            itemsShowLimit: 1,
            allowSearchFilter: true,
            enableCheckAll: false,
            searchPlaceholderText: 'Buscar',
            noDataAvailablePlaceholderText: 'Sem clientes disponiveis!',
            closeDropDownOnSelection: true,
        };

        // set current customer filter
        this.currentCustomerFilter = [];

        // check what home should be displayed
        const preferredBatteryType: BatteryType = this.securityManager.getLoggedUserPreference(Preferences.BATTERY_TYPE);
        this.isTractionaryHomeSelected = isNullOrUndefined(preferredBatteryType) || preferredBatteryType === BatteryType.TRACTIONARY;

        // check if is a tractionary home
        if (this.isTractionaryHomeSelected) {
            // check which dashboard should be displayed
            this.setTractionaryDashboardView();
        }

        // in according with the user type, check what type of action should be taken
        const loggedUser: User = this.securityManager.getLoggedUser();
        if (loggedUser.internal) {
            if (loggedUser.profile !== UserProfile.OPERATOR) {
                // try retrieving logged user preferences
                this.headOfficeId = this.securityManager.getLoggedUserPreference(Preferences.HEAD_OFFICE) ? this.securityManager.getLoggedUserPreference(Preferences.HEAD_OFFICE) : 0;
                this.customerId = this.securityManager.getLoggedUserPreference(Preferences.CUSTOMER) ? this.securityManager.getLoggedUserPreference(Preferences.CUSTOMER) : 0;
                this.customerUnitId = this.securityManager.getLoggedUserPreference(Preferences.UNIT) ? this.securityManager.getLoggedUserPreference(Preferences.UNIT) : 0;
                this.unitBatteryRoomId = this.securityManager.getLoggedUserPreference(Preferences.BATTERY_ROOM) ? this.securityManager.getLoggedUserPreference(Preferences.BATTERY_ROOM) : 0;

                if (loggedUser.profile === UserProfile.ADMIN || loggedUser.profile === UserProfile.EXECUTIVE) {
                    // load possible head offices
                    this.loadHeadOfficesFilter();
                }
                // load possible customers
                this.loadCustomersFilter();
            }
            else {
                // initialize head office id
                this.headOfficeId = 0;
                // set customer id
                this.customerId = loggedUser.customers[0].id;
                this.currentCustomerFilter = loggedUser.customers;
                this.customerUnitId = this.securityManager.getLoggedUserPreference(Preferences.UNIT) ? this.securityManager.getLoggedUserPreference(Preferences.UNIT) : 0;
                this.unitBatteryRoomId = this.securityManager.getLoggedUserPreference(Preferences.BATTERY_ROOM) ? this.securityManager.getLoggedUserPreference(Preferences.BATTERY_ROOM) : 0;

                // load only customer units
                this.loadCustomerUnitsFilter();
            }
        }
        else {
            // initialize head office id
            this.headOfficeId = 0;

            if (loggedUser.profile !== UserProfile.OPERATOR) {
                // set customer id
                this.customerId = loggedUser.customers[0].id;
                this.currentCustomerFilter = loggedUser.customers;
                this.customerUnitId = this.securityManager.getLoggedUserPreference(Preferences.UNIT) ? this.securityManager.getLoggedUserPreference(Preferences.UNIT) : 0;
                this.unitBatteryRoomId = this.securityManager.getLoggedUserPreference(Preferences.BATTERY_ROOM) ? this.securityManager.getLoggedUserPreference(Preferences.BATTERY_ROOM) : 0;

                // load only customer units
                this.loadCustomerUnitsFilter();
            }
            else {
                this.currentCustomerFilter = loggedUser.customers;
                this.customerUnits = this.currentCustomerFilter[0].units;
                this.customerUnitId = loggedUser.unitId;
                this.unitBatteryRoomId = this.securityManager.getLoggedUserPreference(Preferences.BATTERY_ROOM) ? this.securityManager.getLoggedUserPreference(Preferences.BATTERY_ROOM) : 0;
            }
        }
    }

    /**
     * Method responsible for checking if head office filter should be displayed
     *
     * @returns flag identifying if head office filter should be displayed
     */
    shouldShowHeadOfficeFilter(): boolean {
        // get logged user
        const loggedUser: User = this.securityManager.getLoggedUser();

        return loggedUser.internal && (loggedUser.profile === UserProfile.ADMIN || loggedUser.profile === UserProfile.EXECUTIVE);
    }

    /**
     * Method responsible for checking if customer filter should be displayed
     *
     * @returns flag identifying if customer filter should be displayed
     */
    shouldShowCustomerFilter(): boolean {
        // get logged user
        const loggedUser: User = this.securityManager.getLoggedUser();

        return loggedUser.internal && loggedUser.profile !== UserProfile.OPERATOR;
    }

    /**
     * Method responsible for checking if unit filter should be displayed
     *
     * @returns flag identifying if unit filter should be displayed
     */
    shouldShowUnitAndRoomFilters(): boolean {
        // get logged user
        const loggedUser: User = this.securityManager.getLoggedUser();

        return loggedUser.internal || loggedUser.profile !== UserProfile.OPERATOR;
    }

    /**
     * Method responsible for checking if dashboard filter should be displayed
     *
     * @returns flag identifying if dashboard filter should be displayed
     */
    shouldShowDashboardFilter(): boolean {
        // get logged user and check profile
        return this.securityManager.getLoggedUser().profile !== UserProfile.OPERATOR;
    }

    /**
     * Method responsible for updating the current preferred battery type based on the selected type
     *
     * @param selectedBatteryType selected battery type
     */
    updatePreferredBatteryType(selectedBatteryType: BatteryType) {
        // update the battery type preference
        this.securityManager.updateUserPreference(Preferences.BATTERY_TYPE, selectedBatteryType);

        // update variable that holds home visualization
        this.isTractionaryHomeSelected = (selectedBatteryType === BatteryType.TRACTIONARY);

        // check if is a tractionary home
        if (this.isTractionaryHomeSelected) {
            // check which dashboard should be displayed
            this.setTractionaryDashboardView();
        }
    }

    /**
     * Method responsible for updating the current preferred tractionary dashboard view based on the selected type
     *
     * @param tractionaryDashboardView tractionary dashboard view
     */
    updatePreferredTractionaryDashboardView(tractionaryDashboardView: string) {
        // update the tractionary dashboard type preference
        this.securityManager.updateUserPreference(Preferences.TRACTIONARY_DASHBOARD_VIEW, tractionaryDashboardView);

        // update the variable that holds the dashboard type visualization
        this.isTractionaryOperatorHomeSelected = tractionaryDashboardView === 'OPERATOR';
    }

    updatePreferredHeadOfficeFilter() {
        // update the head office id preference.
        this.securityManager.updateUserPreference(Preferences.HEAD_OFFICE, this.headOfficeId);

        // update the customer id preference.
        this.updatePreferredCustomerFilter();
    }

    /**
     * Method responsible for updating the current preferred customer id.
     */
    updatePreferredCustomerFilter() {
        // check if belongs to head office
        if (Number(this.headOfficeId) !== 0 && this.customers.filter((customer: Customer) => customer.headOfficeId === Number(this.headOfficeId) && customer.id === Number(this.customerId)).length === 0) {
            this.customerId = 0;
            this.currentCustomerFilter = [];
        }

        // update the customer id preference.
        this.securityManager.updateUserPreference(Preferences.CUSTOMER, this.customerId);

        // reset the customer unit id.
        this.customerUnitId = 0;

        // update the customer unit id preference.
        this.updatePreferredCustomerUnitFilter();
    }

    /**
     * Method responsible for updating the current preferred customer unit id.
     */
    updatePreferredCustomerUnitFilter() {
        // update the customer unit id preference.
        this.securityManager.updateUserPreference(Preferences.UNIT, this.customerUnitId);

        // reset the unit battery room id.
        this.unitBatteryRoomId = 0;

        // load battery room filter
        this.loadUnitBatteryRoomsFilter();
    }

    /**
     * Method responsible for updating the currente preferred unit battey room d
     */
    updatePreferredUnitBatteryRoomFilter() {
        // update the customer unit id preference.
        this.securityManager.updateUserPreference(Preferences.BATTERY_ROOM, this.unitBatteryRoomId);
    }

    /**
     * Load customer filter in according to user profile. This method is called only when user is internal and its profile is not an operator
     */
    loadCustomersFilter() {
        // get logged user
        const loggedUser: User = this.securityManager.getLoggedUser();

        // if logged user is administrator or executive show all customers
        if (loggedUser.profile === UserProfile.ADMIN || loggedUser.profile === UserProfile.EXECUTIVE) {
            // get all customers
            this.customerService.getAllEntities().subscribe((response) => {
                // set customer array
                this.customers = response;

                // set current customer filter
                this.currentCustomerFilter = this.customers.filter((customer: Customer) => customer.id === this.customerId);

                // set loaded customers
                this.loadedCustomers = response;

                // verify if head office id is set
                if (Number(this.headOfficeId) !== 0) {
                    // filter the customers result
                    this.filterCustomersByHeadOffice();
                }

                // load the list of unit with the user unit as default
                this.loadCustomerUnitsFilter();
            });
        }
        else {
            // get all customers associated to a given business area
            this.customerService.getCustomersByBusinessArea(loggedUser.businessAreaId).subscribe((response) => {
                // set customer array
                this.customers = loggedUser.profile !== UserProfile.COORDINATOR ? response : response.filter((customer: Customer) => loggedUser.customers.findIndex((loggedUserCustomer: Customer) => customer.id === loggedUserCustomer.id) !== -1);

                // set current customer filter
                this.currentCustomerFilter = this.customers.filter((customer: Customer) => customer.id === this.customerId);

                // load the list of unit with the user unit as default
                this.loadCustomerUnitsFilter();
            });
        }
    }

    /**
     * Method responsible to load the custoemrs units using the customer id.
     */
    loadCustomerUnitsFilter() {
        // initialize the customer units
        this.customerUnits = [];

        // initialize the customer unit id
        if (!isNullOrUndefined(this.customerId) && this.customerId > 0) {
            // get the customer units
            this.customerService.getEntityById(this.customerId).subscribe((response) => {
                // set unit array
                this.customerUnits = response.units;

                // load unit battery rooms
                this.loadUnitBatteryRoomsFilter();
            });
        }
    }

    /**
     * Method responsible to filter customers list by head office if necessary
     */
    filterCustomersByHeadOffice() {
        // filter the list of customer if necessary
        this.customers = Number(this.headOfficeId) !== 0 ? this.loadedCustomers.filter((customer: Customer) => customer.headOfficeId === Number(this.headOfficeId)) : this.loadedCustomers;

        // verify if should reset filters
        if (Number(this.headOfficeId) !== 0 && this.customers.filter((customer: Customer) => customer.headOfficeId === Number(this.headOfficeId)).length === 0) {
            // reset filters
            this.customerId = 0;
            this.customerUnitId = 0;
            this.unitBatteryRoomId = 0;
            this.currentCustomerFilter = [];
        }
    }

    /**
     * Method responsible for updating the customer id filter
     */
    updateCustomerId(customer: Customer) {
        this.customerId = customer.id;
    }

    /**
     * Load head office filter in according to user profile. This method is called only when user is internal and its profile is equal to admin or executive
     */
    private loadHeadOfficesFilter() {
        this.headOfficeService.getAllEntities().subscribe((response) => {
            // set head offices
            this.headOffices = response;
        });
    }

    /**
    * Method responsible to load the unit baterry rooms using the selected unit.
    */
    private loadUnitBatteryRoomsFilter() {
        // initialize the unit battery rooms
        this.unitBatteryRooms = [];

        // initialize the battery room array
        if (!isNullOrUndefined(this.customerUnitId) && this.customerUnitId > 0 && this.customerUnits.length > 0) {
            this.unitBatteryRooms = this.customerUnits.filter((unit) => Number(unit.id) === Number(this.customerUnitId))[0].batteryRooms;
        }
    }

    /**
     * Method responsible to set the tractionary dashboard view
     */
    private setTractionaryDashboardView() {
        // check which dashboard should be displayed
        const preferredTractionaryDashboardView = this.securityManager.getLoggedUserPreference(Preferences.TRACTIONARY_DASHBOARD_VIEW);

        // check if user has preferred tractionary dashboard view
        if (!isNullOrUndefined(preferredTractionaryDashboardView)) {
            // set the dashboard view with the preferred
            this.isTractionaryOperatorHomeSelected = preferredTractionaryDashboardView === 'OPERATOR';
        }
        else {
            // set the dashboard by user profile
            this.isTractionaryOperatorHomeSelected = this.securityManager.getLoggedUser().profile === UserProfile.COORDINATOR || this.securityManager.getLoggedUser().profile === UserProfile.MANAGER ? false : true;
        }

    }
}
