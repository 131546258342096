import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe responsible to translate the alert type icon
 */
@Pipe({ name: 'alertTypeIcon' })
export class AlertTypeIconPipe implements PipeTransform {

    /**
     * @see @angular/core/PipeTransform/transform(value: any, ...args: any[])
     */
    transform(alertTypeCode: string): string {
        // icon to be returned
        let alertTypeIcon: string;

        // decide what icon should be returned
        switch (alertTypeCode) {

            case 'HIGH_TEMPERATURE':
                alertTypeIcon = 'icon-alert-high-temperature';
                break;

            case 'LOW_STATE_OF_CHARGE':
                alertTypeIcon = 'icon-alert-low-state-of-charge';
                break;

            case 'LOW_ELECTROLYTE_LEVEL':
                alertTypeIcon = 'icon-alert-low-electrolyte-level';
                break;

            case 'OVERVOLTAGE':
                alertTypeIcon = 'icon-alert-overvoltage';
                break;

            case 'IMPROPER_MOVEMENT':
                alertTypeIcon = 'icon-alert-improper-movement';
                break;

            case 'DISCONNECT_FROM_CHARGER':
                alertTypeIcon = 'icon-alert-disconnect-from-charger';
                break;

            case 'VIOLATED_REST':
                alertTypeIcon = 'icon-alert-violated-rest';
                break;

            case 'INCOMPLETED_RECHARGE':
                alertTypeIcon = 'icon-alert-incompleted-recharge';
                break;

            default:
                alertTypeIcon = 'UNKNOWN';
                break;
        }

        return alertTypeIcon;
    }
}
