import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from 'util';
import * as moment from 'moment';

/**
 * Pipe responsible to format measure values ( value + unit. Ex: 10 | measure:'A')
 */
@Pipe({ name: 'measure' })
export class MeasurePipe implements PipeTransform {

  /**
   * @see @angular/core/PipeTransform/transform(value: any, ...args: any[])
   */
  transform(value: number, unit: string, requiresConversion: boolean = true): string {
    let formattedMeasure: string;

    // check if the value is present
    if (isNullOrUndefined(value)) {
        formattedMeasure = 'N/D';
    }
    else {
        switch (unit) {
            case 'Ah': formattedMeasure = (requiresConversion ? (value / 3600).toFixed(2) : value) + unit; break;
            case 'autonomy':  formattedMeasure = moment.duration(value, 'second').hours() + 'h ' + moment.duration(value, 'second').minutes() + 'm'; break;
            default: formattedMeasure = value + ( isNullOrUndefined(unit) ? '' : unit );
        }
    }

    return formattedMeasure;
  }
}
