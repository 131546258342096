import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { GenericComponent } from './shared/generic-component.component';
import { SecurityManager } from './shared/mechanism/security-manager.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({selector: 'app-root', templateUrl: './app.component.html'})
export class AppComponent extends GenericComponent implements OnInit {
    /**
     * Flag responsible for controlling idle timeout warning
     */
    private idleTimeoutWarningEmitted: boolean = false;

    /**
     * Default constructor
     *
     * @param router see @angular/router.
     * @param idle see @ng-idle/corer.
     * @param toastService toast service
     * @param securityManager security manager
     * @param ngBsLocaleService ng bootstrap locale service
     * @param deviceService device detector service
     */
    constructor(private router: Router, private idle: Idle, protected toastService: ToastrService, private securityManager: SecurityManager, private ngBsLocaleService: BsLocaleService, private deviceService: DeviceDetectorService) {
        // call super
        super(toastService);
    }

    /**
     * @see @angular/core/OnInit#OnInit()
     */
    ngOnInit() {
        // configure idle component
        this.configureIdleComponent();

        // define datepicker locale
        this.ngBsLocaleService.use('pt-br');
    }

    /**
     * Informs whether the footer should be visible or not
     */
    isFooterVisible(): boolean {
        // the path /batteries/{id}/details should not have the footer because it has another one defined inside it that
        // follows the side menu.
        return !(/^\/batteries\/[0-9]+\/details$/.test(this.router.url));
    }

    /**
     * check if is small device screen
     * @returns flag indicating if is small device screen
     */
    isSmallDeviceScreen(): boolean {
        return this.deviceService.isMobile() || this.deviceService.isTablet();
    }

    /**
     * Method responsible for configuring idle component
     */
    private configureIdleComponent() {
        // sets an idle timeout
        this.idle.setIdle(environment.idleTime);

        // sets a timeout period
        this.idle.setTimeout(environment.idleTimeoutTime);

        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        // warn user that session is about to be timed out
        this.idle.onTimeoutWarning.subscribe( () => {
            if (!this.idleTimeoutWarningEmitted && this.router.url !== '/login') {

                // show warning
                this.showInfoMessage('Sua sessão expirará em ' + environment.idleTimeoutTime + ' segundos.');

                // change flag
                this.idleTimeoutWarningEmitted = true;
            }
        });

        // redirect user to login
        this.idle.onTimeout.subscribe(() => {
            if (this.router.url !== '/login') {
                // reset warning control
                this.idleTimeoutWarningEmitted = false;

                // logout user
                this.securityManager.logout();

                // redirect user to login page
                this.router.navigate(['login']);
            }

            // start watching
            this.idle.watch();
        });

        // start watching
        this.idle.watch();
    }
}
