import { AfterViewChecked, Component, Input, OnChanges, OnInit } from '@angular/core';
import { icon, latLng, LatLngBounds, Map, marker, tileLayer } from 'leaflet';
import { isNullOrUndefined } from 'util';

/**
 * Generic Map component
 */
@Component({selector: 'app-map', templateUrl: './map.component.html'})
export class MapComponent implements OnInit, OnChanges, AfterViewChecked {

    /**
     * Variable to receive the inputed map center latitude
     */
    @Input() centerLatitude: number;

    /**
     * Variable to receive the inputed map center longitude
     */
    @Input() centerLongitude: number;

    /**
     * Variable to receive the inputed map zoom
     */
    @Input() zoom: any;

    /**
    * Variable to receive the inputed map max zoom
    */
    @Input() maxZoom: any;

    /**
     * Variable to receive the inputed marker array
     */
    @Input() markers: any[];

    /**
     * Flag to indicate if bound ajust must be done
     */
    @Input() fitBoundEnabled: boolean;

    /**
     * Variable to hold map options
     */
    options: any;

    /**
     * Variable to hold Leaflet Map reference
     */
    map: Map;

    /**
     * Variable to hold the layers
     */
    layersArray: any[];

    /**
     * Default constructor method
     */
    constructor() { }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // set map options
        this.setMapOptions();
    }

    /**
     * @see @angular/core/OnChanges/ngOnchanges()
     */
    ngOnChanges() {
        if (this.map) {
            // set map options
            this.setMapOptions();
            // enable fit bounds
            this.setFitBounds();
        }
    }

    /**
     * Method responsible for setting options to map visualization
     */
    setMapOptions() {
        // set variable to hold layers array
        this.layersArray = [];

        // push Open Street Maps layer
        this.layersArray.push(tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: isNullOrUndefined(this.maxZoom) ? 18 : this.maxZoom,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }));

        // push markers layers
        this.markers.forEach((m) => {
            this.layersArray.push(marker([m.latitude, m.longitude], {
                icon: icon({
                    iconSize: [25, 41],
                    iconAnchor: [13, 41],
                    iconUrl: isNullOrUndefined(m.iconUrl) ? 'leaflet/marker-icon.png' : m.iconUrl,
                    tooltipAnchor: [0, -41]
                })
            }).bindTooltip(isNullOrUndefined(m.tooltipText) ? '' : m.tooltipText, {
                direction: 'top',
                opacity: isNullOrUndefined(m.tooltipText) ? 0 : 1
            }));
        });

        // set options property
        this.options = {
            layers: this.layersArray,
            zoom: isNullOrUndefined(this.zoom) ? 18 : this.zoom,
            center: latLng([this.centerLatitude, this.centerLongitude])
        };
    }

    /**
     * Method responsible for performing map's final configurations, after its ready to be shown
     *
     * @param map map element
     */
    onMapReady(map: Map) {
        // set map property
        this.map = map;

        // enable fit bounds
        this.setFitBounds();
    }

    /**
     * Method responsible for setting fit bounds option
     */
    setFitBounds() {
        // check if should fit using bounds
        if (this.fitBoundEnabled) {
            // apply fit using markers coordinates
            this.map.fitBounds(new LatLngBounds(this.markers.map((m) => {
                return [m.latitude, m.longitude];
            }) as [number, number][]));
        }
    }

    /**
     * @see @angular/core/AfterViewChecked/ngAfterViewChecked()
     */
    ngAfterViewChecked() {
        // force map resize after view init
        this.map.invalidateSize(true);
    }
}
