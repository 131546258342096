import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe responsible to translate the battery type
 */
@Pipe({ name: 'batteryType' })
export class BatteryTypePipe implements PipeTransform {

  /**
   * @see @angular/core/PipeTransform/transform(value: any, ...args: any[])
   */
  transform(batteryType: string): string {
    let batteryTypeName: string;

    switch (batteryType) {
        case 'STATIONARY':
            batteryTypeName = 'Estacionária';
            break;
        case 'TRACTIONARY':
            batteryTypeName = 'Tracionária';
            break;
        case 'RAILWAY':
            batteryTypeName = 'Ferroviária';
            break;
        case 'AUTOMOTIVE':
            batteryTypeName = 'Automotiva';
            break;
        default:
            batteryTypeName =  'Nenhuma descrição para ' + batteryType;
        break;
    }

    return batteryTypeName;
  }
}
