import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';
import { GenericPageableSearchComponent } from '../../shared/generic-pageable-search-component.component';
import { BusinessArea } from '../../shared/model/business-area.model';
import { BusinessAreaSearchParameters } from '../../shared/model/search/business-area-search-parameters.model';
import { BusinessAreaService } from './business-area.service';

/**
 * Search business areas component controller class
 */
@Component({ selector: 'app-search-business-areas', templateUrl: './search-business-areas.component.html' })
export class SearchBusinessAreasComponent extends GenericPageableSearchComponent<BusinessArea> implements OnInit {
    /**
     * Filters object. Overwrites the inherited generic filters object.
     */
    filters: BusinessAreaSearchParameters;

    /**
     * Business areas to be used as filter's content
     */
    businessAreas: BusinessArea[];

    /**
     * Default constructor for generic application component
     *
     * @param toastService toast service
     * @param businessAreaService service for business area matters
     * @param router router service
     */
    constructor(protected toastService: ToastrService, protected businessAreaService: BusinessAreaService, protected router: Router) {
        // call super
        super(toastService, businessAreaService, router, 'businessareas');
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // call super
        super.ngOnInit();

        // instantiate filters
        this.filters = new BusinessAreaSearchParameters();

        // setup pageable object
        this.pageable.sortField = 'name';

        // load business areas
        this.loadBusinessAreas();

        // perform initial search
        this.search();
    }

    /**
     * Method responsible for indicating if a given business area is editable
     *
     * @param businessArea business area to be evaluated
     * @returns flag indicating if the given business area is editable
     */
    isBusinessAreaEditable(businessArea: BusinessArea): boolean {
        return !isNullOrUndefined(businessArea.ownerBusinessAreaId);
    }

    /**
     * Method responsible for indicating if a given business area is removable
     *
     * @param businessArea business area to be evaluated
     * @returns flag indicating if the given business area is editable
     */
    isBusinessAreaRemovable(businessArea: BusinessArea): boolean {
        return !isNullOrUndefined(businessArea.ownerBusinessAreaId);
    }

    /**
     * @see GenericPageableSearchComponent#delete(number)
     */
    delete(bussinessAreaId: number) {
        this.businessAreaService.deleteEntity(bussinessAreaId).subscribe(
            (responseSuccess) => {
                // show a success message
                this.showSuccessMessage(responseSuccess['message']);

                // reload business areas
                this.loadBusinessAreas();

                // redo the search
                this.search();
            },
            (responseError) => {
                this.handleErrors(responseError);
            }
        );
    }

    /**
     * Method responsible for loading all business areas
     */
    private loadBusinessAreas() {
        this.businessAreaService.getAllEntities().subscribe(
            (data) => {
                // get all business areas
                this.businessAreas = data;

                // sort business areas
                this.businessAreas.sort((a: BusinessArea, b: BusinessArea) => a.name.localeCompare(b.name));
            },
            (responseError) => this.handleErrors(responseError)
        );
    }
    /**
     * Method responsible for cleaning the search parameters
     */
    cleanFilters() {
        // clear the filter with the default value
        this.filters.name = '';
        this.filters.ownerBusinessAreaId = 0;
    }

}
