import { HttpParams } from '@angular/common/http';
import { isNullOrUndefined } from 'util';

import { AlertTypeCode } from '../enum/alert-type-code.enum';
import { GenericSearchParameters } from './generic-search-parameters.model';

/**
 * Model class representing battery search parameters
 */
export class BatterySearchParameters implements GenericSearchParameters {
    operationsIdentification: string;
    deviceChipIdentification: string;
    uniqueIdentifier: string;
    headOfficeId: number;
    businessAreaId: number;
    unitId: number;
    batteryRoomId: number;
    usage: string;
    type: string;
    status: string;
    alertTypeCode: string;
    hasDevice: boolean;
    customersId: number[];

    /**
     * Default Constructor
     */
    constructor() {
        this.operationsIdentification = '';
        this.deviceChipIdentification = '';
        this.uniqueIdentifier = '';
        this.headOfficeId = 0;
        this.unitId = 0;
        this.businessAreaId = 0;
        this.batteryRoomId = 0;
        this.usage = '';
        this.type = '';
        this.status = '';
        this.alertTypeCode = '';
        this.hasDevice = null;
        this.customersId = [];
    }

    /**
     * @see GenericSearchParameters/generateHttpParams()
     */
    generateHttpParams(): HttpParams {
        // initialize parameter list
        let httpParams: HttpParams = new HttpParams();

        // assign parameter list
        if (this.operationsIdentification && this.operationsIdentification.trim().length > 0) {
            httpParams = httpParams.append('operationsIdentification', this.operationsIdentification);
        }

        if (this.deviceChipIdentification && this.deviceChipIdentification.trim().length > 0) {
            httpParams = httpParams.append('deviceChipIdentification', this.deviceChipIdentification);
        }

        if (this.uniqueIdentifier && this.uniqueIdentifier.trim().length > 0) {
            httpParams = httpParams.append('uniqueIdentifier', this.uniqueIdentifier);
        }

        if (this.usage && this.usage.trim().length > 0) {
            httpParams = httpParams.append('usage', this.usage);
        }

        if (this.type && this.type.trim().length > 0) {
            httpParams = httpParams.append('type', this.type);
        }

        if (this.headOfficeId > 0) {
            httpParams = httpParams.append('headOfficeId', this.headOfficeId.toString());
        }

        if (this.unitId > 0) {
            httpParams = httpParams.append('unitId', this.unitId.toString());
        }

        if (this.businessAreaId > 0) {
            httpParams = httpParams.append('businessAreaId', this.businessAreaId.toString());
        }

        if (this.batteryRoomId > 0) {
            httpParams = httpParams.append('batteryRoomId', this.batteryRoomId.toString());
        }

        if (!isNullOrUndefined(this.hasDevice)) {
            httpParams = httpParams.append('hasDevice', this.hasDevice.toString());
        }

        if (this.status && this.status.trim().length > 0) {
            httpParams = httpParams.append('status', this.status);
        }

        if (this.alertTypeCode && this.alertTypeCode.trim().length > 0) {
            if (this.alertTypeCode === AlertTypeCode.ANY) {
                httpParams = httpParams.append('onlyAlarmingBatteries', 'true');
            }
            else {
                httpParams = httpParams.append('alertTypeCode', this.alertTypeCode);
            }
        }

        if (this.customersId.length > 0) {
            for (const customer of this.customersId) {
                if (Number(customer) !== 0) {
                    httpParams = httpParams.append('customers', customer.toString());
                }
            }
        }

        return httpParams;
    }
}
