import { Routes } from '@angular/router';
import { SearchAlertsComponent } from './core/alert/search-alerts.component';
import { ListBatteriesComponent } from './core/battery/list-batteries.component';
import { SearchBatteriesComponent } from './core/battery/search-batteries.component';
import { ViewBatteryDataComponent } from './core/battery/view-battery-data.component';
import { MaintainBusinessAreaComponent } from './core/businessarea/maintain-business-area.component';
import { SearchBusinessAreasComponent } from './core/businessarea/search-business-areas.component';
import { MaintainCustomerComponent } from './core/customer/maintain-customer.component';
import { SearchCustomersComponent } from './core/customer/search-customers.component';
import { SearchDevicesComponent } from './core/device/search-devices.component';
import { HomeComponent } from './core/home/home.component';
import { SearchLogEntriesComponent } from './core/log/search-log-entries.component';
import { LoginComponent } from './core/login/login.component';
import { MaintainModelComponent } from './core/model/maintain-model.component';
import { SearchModelsComponent } from './core/model/search-models.component';
import { SearchScheduledTasksComponent } from './core/scheduledtask/search-scheduled-tasks.component';
import { MaintainServiceOrderComponent } from './core/serviceorder/maintain-service-order.component';
import { SearchServiceOrdersComponent } from './core/serviceorder/search-service-orders.component';
import { UninstallServiceOrdersComponent } from './core/serviceorder/uninstall-service-orders.component';
import { MaintainUserComponent } from './core/user/maintain-user.component';
import { SearchUsersComponent } from './core/user/search-users.component';
import { AuthenticationGuard } from './shared/mechanism/authentication.guard';
import { ProfileGuard } from './shared/mechanism/profile.guard';
import { UserProfile } from './shared/model/enum/user-profile.enum';
import { SearchDeviceSoftwaresComponent } from './core/devicesoftware/search-device-softwares.component';
import { MaintainDeviceSoftwareComponent } from './core/devicesoftware/maintain-device-software.component';
import { MaintainBatteryComponent } from './core/battery/maintain-battery.component';
import { SearchHeadOfficesComponent } from './core/headoffice/search-head-offices.component';
import { MaintainHeadOfficeComponent } from './core/headoffice/maintain-head-office.component';
import { SearchDeviceSoftwareUpdateRequisitionsComponent } from './core/devicesoftwareupdaterequisition/search-device-software-update-requisitions.component';
import { MaintainDeviceSoftwareUpdateRequisitionComponent } from './core/devicesoftwareupdaterequisition/maintain-device-software-update-requisition.component';

export const routes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthenticationGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'home', component: HomeComponent, canActivate: [AuthenticationGuard] },
    {
        path: '',
        canActivate: [AuthenticationGuard],
        children: [
            {
                path: 'businessareas',
                canActivate: [AuthenticationGuard, ProfileGuard],
                data: { requireProfiles: [UserProfile.ADMIN] },
                children: [
                    { path: '', component: SearchBusinessAreasComponent },
                    { path: 'new', component: MaintainBusinessAreaComponent },
                    { path: ':id/edit', component: MaintainBusinessAreaComponent }
                ]
            },
            {
                path: 'users',
                canActivate: [AuthenticationGuard, ProfileGuard],
                data: { requireProfiles: [UserProfile.ADMIN] },
                children: [
                    { path: '', component: SearchUsersComponent },
                    { path: 'new', component: MaintainUserComponent },
                    { path: ':id/edit', component: MaintainUserComponent }
                ]
            },
            {
                path: 'customers',
                canActivate: [AuthenticationGuard, ProfileGuard],
                data: { requireProfiles: [UserProfile.ADMIN, UserProfile.MANAGER] },
                children: [
                    { path: '', component: SearchCustomersComponent },
                    { path: 'new', component: MaintainCustomerComponent },
                    { path: ':id/edit', component: MaintainCustomerComponent },
                ]
            },
            {
                path: 'serviceorders',
                canActivate: [AuthenticationGuard, ProfileGuard],
                data: { requireProfiles: [UserProfile.ADMIN, UserProfile.EXECUTIVE, UserProfile.COORDINATOR, UserProfile.OPERATOR, UserProfile.MANAGER] },
                children: [
                    { path: '', component: SearchServiceOrdersComponent },
                    { path: 'new', component: MaintainServiceOrderComponent },
                    { path: ':id/edit', component: MaintainServiceOrderComponent },
                    { path: ':id/uninstall', component: UninstallServiceOrdersComponent }
                ]
            },
            {
                path: 'devices',
                canActivate: [AuthenticationGuard, ProfileGuard],
                data: { requireProfiles: [UserProfile.ADMIN] },
                children: [
                    { path: '', component: SearchDevicesComponent }
                ]
            },
            {
                path: 'logEntries',
                canActivate: [AuthenticationGuard, ProfileGuard],
                data: { requireProfiles: [UserProfile.ADMIN] },
                children: [
                    { path: '', component: SearchLogEntriesComponent }
                ]
            },
            {
                path: 'scheduledtasks',
                canActivate: [AuthenticationGuard, ProfileGuard],
                data: { requireProfiles: [UserProfile.ADMIN] },
                children: [
                    { path: '', component: SearchScheduledTasksComponent }
                ]
            },
            {
                path: 'models',
                canActivate: [AuthenticationGuard, ProfileGuard],
                data: { requireProfiles: [UserProfile.ADMIN] },
                children: [
                    { path: '', component: SearchModelsComponent },
                    { path: 'new', component: MaintainModelComponent },
                    { path: ':id/edit', component: MaintainModelComponent },
                ]
            },
            {
                path: 'devicesoftwares',
                canActivate: [AuthenticationGuard, ProfileGuard],
                data: { requireProfiles: [UserProfile.ADMIN] },
                children: [
                    { path: '', component: SearchDeviceSoftwaresComponent },
                    { path: 'new', component: MaintainDeviceSoftwareComponent },
                    { path: ':id/edit', component: MaintainDeviceSoftwareComponent },
                ]
            },
            {
                path: 'devicesoftwareupdaterequisitions',
                canActivate: [AuthenticationGuard, ProfileGuard],
                data: { requireProfiles: [UserProfile.ADMIN] },
                children: [
                    { path: '', component: SearchDeviceSoftwareUpdateRequisitionsComponent },
                    { path: 'new', component: MaintainDeviceSoftwareUpdateRequisitionComponent },
                    { path: ':id/edit', component: MaintainDeviceSoftwareUpdateRequisitionComponent },
                ]
            },
            {
                path: 'headoffices',
                canActivate: [AuthenticationGuard, ProfileGuard],
                data: { requireProfiles: [UserProfile.ADMIN, UserProfile.EXECUTIVE] },
                children: [
                    { path: '', component: SearchHeadOfficesComponent },
                    { path: 'new', component: MaintainHeadOfficeComponent },
                    { path: ':id/edit', component: MaintainHeadOfficeComponent },
                ]
            },
        ]
    },
    {
        path: '',
        canActivate: [AuthenticationGuard],
        children: [
            {
                path: 'batteries',
                canActivate: [AuthenticationGuard],
                children: [
                    { path: '', component: SearchBatteriesComponent },
                    { path: 'list', component: ListBatteriesComponent },
                    {
                        path: ':id/details',
                        component: ViewBatteryDataComponent,
                        canActivate: [ProfileGuard],
                        data: { onlyInternalUser: true },
                    },
                    { path: ':id/edit', component: MaintainBatteryComponent }
                ]
            },
            {
                path: 'alerts',
                canActivate: [AuthenticationGuard],
                component: SearchAlertsComponent,
            },
        ]
    }
];
