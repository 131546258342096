import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { GenericPageableSearchComponent } from '../../shared/generic-pageable-search-component.component';
import { HeadOffice } from '../../shared/model/head-office.model';
import { HeadOfficeService } from './head-office.service';
import { HeadOfficeSearchParameters } from '../../shared/model/search/head-office-search-parameters.model';

/**
 * Search head offices component controller class
 */
@Component({ selector: 'app-search-head-offices', templateUrl: './search-head-offices.component.html', })
export class SearchHeadOfficesComponent extends GenericPageableSearchComponent<HeadOffice> implements OnInit {
    /**
     * Filters object. Overwrites the inherited generic filters object.
     */
    filters: HeadOfficeSearchParameters;

    constructor(protected toastrService: ToastrService, protected headOfficeService: HeadOfficeService, protected router: Router) {
        // call super
        super(toastrService, headOfficeService, router, 'headoffices');
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // call super
        super.ngOnInit();

        // instantiate filters
        this.filters = new HeadOfficeSearchParameters();

        // perfom initial search
        this.search();
    }

    /**
    * Method responsible for cleaning the search parameters
    */
    cleanFilters() {
        // clear the filter with the default value
        this.filters.name = '';
        this.filters.socialName = '';
        this.filters.cnpj = '';
    }
}
