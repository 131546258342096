import { User } from './user.model';

/**
 * Model class representing a device software
 */
export class DeviceSoftware {
    id: number;
    version: string;
    releaseNotes: string;
    packageUrl: string;
    active: boolean;
    createdDate: Date;
    creatorUser: User;
    creatorUserId: number;
    modifiedDate: Date;
}
