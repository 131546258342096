import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApplicationService } from '../../../core/application.service';
import { GenericComponent } from '../../generic-component.component';
import { SecurityManager } from '../../mechanism/security-manager.service';

/**
 * Footer component controller class
 */
@Component({ selector: 'app-footer', templateUrl: './footer.component.html', styleUrls: ['./footer.component.scss']})
export class FooterComponent extends GenericComponent implements OnInit  {
    /**
     * Application information that must be shown
     */
    private applicationVersion: string;

    /**
     * Default constructor for footer component
     *
     * @param toastService toast service
     * @param securityManager mechanism to deal with security matters
     * @param versionManager machanism responsible for providing application version information
     */
    constructor(protected toastService: ToastrService, private securityManager: SecurityManager, private applicationServices: ApplicationService) {
        // call super
        super(toastService);
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // set application version information
        this.applicationServices.getApplicationVersion().subscribe(
            (applicationVersion) => {
                // format and return the received information
                this.applicationVersion = 'v' + applicationVersion['version'] + '-' + applicationVersion['environment'];
            }
        );
    }

    /**
     * Method responsible for defining component's visibility
     * @returns flag indicating component visibility
     */
    isComponentVisible(): boolean {
        return this.securityManager.isSessionValid();
    }

    /**
     * Method responsible for getting application information
     * @returns string contining the application version and environment
     */
    getApplicationVersion(): string {
       return this.applicationVersion;
    }
}
