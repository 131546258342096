import { Injectable } from '@angular/core';

import { GenericService } from '../../shared/generic-service.service';
import { MouraConnectServer } from '../../shared/mechanism/moura-connect-server.service';
import { SecurityManager } from '../../shared/mechanism/security-manager.service';
import { DeviceSoftwareUpdateRequisition } from '../../shared/model/device-software-update-requisition.model';

/**
 * Class responsible for dealing with device software update requisition matters
 */
@Injectable()
export class DeviceSoftwareUpdateRequisitionService extends GenericService<DeviceSoftwareUpdateRequisition> {

  /**
     * Default constructor
     *
     * @param mouraConnectServer moura connect server
     * @param securityManager security manager
     */
    constructor(protected mouraConnectServer: MouraConnectServer, protected securityManager: SecurityManager) {
        // call super
        super('/devicesoftwareupdaterequisitions', mouraConnectServer);
    }
}
