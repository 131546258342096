import { HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { GenericSearchParameters } from './generic-search-parameters.model';

/**
 * Model class representing device software update requisition search parameters
 */
export class DeviceSoftwareUpdateRequisitionSearchParameters implements GenericSearchParameters {
    id: Number;
    status: string;
    type: string;
    businessAreaId: number;
    startDate: Date;
    endDate: Date;
    customers: number[];

    /**
     * Default contructor
     */
    constructor() {
        this.id = null;
        this.status = '';
        this.type = '';
        this.businessAreaId = 0;
        this.startDate = null;
        this.endDate = null;
        this.customers = [];
    }

    /**
     * @see GenericSearchParameters/Parameters/generateHttpParams()
     */
    generateHttpParams(): HttpParams {
        // initialize parameter lsit
        let httpParams: HttpParams = new HttpParams();

        // assign id parameter
        if (this.id > 0) {
            httpParams = httpParams.append('id', this.id.toString());
        }

        // assign status parameter
        if (this.status && this.status.length > 0) {
            httpParams = httpParams.append('status', this.status);
        }

        // assign type parameter
        if (this.type && this.type.length > 0) {
            httpParams = httpParams.append('type', this.type);
        }

        // assign business area id parameter
        if (this.businessAreaId > 0) {
            httpParams = httpParams.append('businessAreaId', this.businessAreaId.toString());
        }

        // assign start date parameter
        if (this.startDate) {
            httpParams = httpParams.append('startDate', moment(this.startDate).format('YYYYMMDD'));
        }

        // assign end date parameter
        if (this.endDate) {
            httpParams = httpParams.append('endDate', moment(this.endDate).format('YYYYMMDD'));
        }

        // assign customers parameter
        if (this.customers.length > 0) {
            for (const id of this.customers) {
                if (Number(id) !== 0) {
                    httpParams = httpParams.append('customers', id.toString());
                }
            }
        }

        return httpParams;
    }
}
