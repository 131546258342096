import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Pageable } from '../model/pageable.model';
import { GenericSearchParameters } from '../model/search/generic-search-parameters.model';

/**
 * Class which holds methods to access the erouting server
 */
@Injectable()
export class MouraConnectServer {

    /**
     * Default constructor to receive dependencies
     *
     * @param httpClient http client to perform calls to the server
     */
    constructor(private httpClient: HttpClient) {
    }

    /**
     * Perform a post to the server
     *
     * @param url url to be added to base server url (Think Rest)
     * @param payload payload to be sent by this request
     * @param observeResponse optional parameter which determines the answer type
     * @returns observable object containing T object (Observable<T>), or Http response (Observable<HttpResponse<T>>) or an error
     */
    post<T>(url: string, payload?: any, observeResponse: boolean = false): Observable<any> {
        // perform request
        return observeResponse ? this.httpClient.post<T>(this.getServerURL(url), payload, { observe: 'response' }) : this.httpClient.post<T>(this.getServerURL(url), payload);
    }

    /**
     * Perform a put to the server
     *
     * @param url url to be added to base server url (Think Rest)
     * @param payload payload to be sent by this request
     * @returns observable object containing T object (Observable<T>) or an error
     */
    put<T>(url: string, payload: any): Observable<T> {
        // perform request
        return this.httpClient.put<T>(this.getServerURL(url), payload);
    }

    /**
     * Perform a pacth to the server
     *
     * @param url url to be added to base server url (Think Rest)
     * @param payload payload to be sent by this request
     * @param observeResponse optional parameter which determines the answer type
     * @returns observable object containing T object (Observable<T>), or Http response (Observable<HttpResponse<T>>) or an error
     */
    patch<T>(url: string, payload?: any, observeResponse: boolean = false): Observable<any> {
        // perform request
        return observeResponse ?
            this.httpClient.patch<T>(this.getServerURL(url), payload, { observe: 'response' }) : this.httpClient.patch<T>(this.getServerURL(url), payload);
    }

    /**
     * Perform a get into the server
     *
     * @param url url to be added to base server url (Think Rest)
     * @param pageable optional pageable object to paginate the return
     * @param parameters optional parameters to add to the request
     * @returns observable object containing a page of T (if pageable present), object T or error
     */
    get<T>(url: string, pageable?: Pageable, parameters?: GenericSearchParameters): Observable<any> {
        return this.httpClient.get<any>(this.getServerURL(url), { params: this.generateRequestParameters(pageable, parameters) });
    }

    /**
     * Perform a delete to the server
     *
     * @param url url to be added to base server url (Think Rest)
     * @returns observable object containing object or an error
     */
    delete<T>(url: string): Observable<T> {
        return this.httpClient.delete<T>(this.getServerURL(url));
    }

    /**
     * Method responsible for generating final URL to access the server
     *
     * @param url url to be added to base server url
     * @returns url changed with base
     */
    private getServerURL(url: string): string {
        return environment.mouraConnectServerURL + url;
    }

    /**
     * Method responsible for generating a parameter list to be added to a request
     *
     * @param pageable pageable object to be converted as request parameters
     * @param parameters list of elements to be converted as request parameters
     * @returns http parameters
     */
    private generateRequestParameters(pageable: Pageable | null, parameters: GenericSearchParameters | null): HttpParams {
        // structure to be returned
        let requestParameters: HttpParams = parameters ? parameters.generateHttpParams() : new HttpParams();

        // add pagination elements
        if (pageable != null) {
            requestParameters = requestParameters.set('page', pageable.page.toString());
            requestParameters = requestParameters.set('size', pageable.size.toString());
            requestParameters = requestParameters.set('sort', `${pageable.sortField},${pageable.sortOrder}`);
        }

        return requestParameters;
    }
}
