import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

/**
 * Service class responsible for providing methods to export structured data
 */
@Injectable()
export class ExportDataManager {

    /**
     * Excel type definition
     */
    private static readonly EXCEL_TYPE: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    /**
     * Excel extension definition
     */
    private static readonly EXCEL_EXTENSION: string = '.xlsx';

    /**
     * Exports the given json to a file with the given filename.
     *
     * @param json The json to be exported
     * @param excelFileName The output filename
     */
    exportAsExcelFile(json: any[], excelFileName: string) {
        // construct spreedsheet
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        // save as excel file
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    /**
     * Saves the buffer as an excel file.
     *
     * @param buffer the buffer to be saved
     * @param fileName the file name
     */
    private saveAsExcelFile(buffer: any, fileName: string) {
        const data: Blob = new Blob([buffer], {
            type: ExportDataManager.EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + ExportDataManager.EXCEL_EXTENSION);
    }
}
