import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MouraConnectServer } from '../../shared/mechanism/moura-connect-server.service';
import { SecurityManager } from '../../shared/mechanism/security-manager.service';
import { StationaryHomeReport } from '../../shared/model/report/stationary-home-report.model';
import { TractionaryCoordinatorHomeReport } from '../../shared/model/report/tractionary-coordinator-home-report.model';
import { TractionaryOperatorHomeReport } from '../../shared/model/report/tractionary-operator-home-report.model';

/**
 * Class responsible for dealing with tracitonary report matters
 */
@Injectable()
export class ReportService {

    /**
     * Default Constructor
     *
     * @param mouraConnectServer moura connect server
     * @param securityManager  security manager
     */
    constructor(protected mouraConnectServer: MouraConnectServer, protected securityManager: SecurityManager) {
    }

    /**
     * Method responsible to get the operator's tractionary report by customer id.
     *
     * @param customerId the customer id.
     * @returns structure containing requested report
     */
    getOperatorTractionaryHomeReportByCustomer(customerId: number): Observable<TractionaryOperatorHomeReport> {
        return this.mouraConnectServer.get(`/reports/home/tractionary/operator/customer/${customerId}`, null, null);
    }

    /**
     * Method responsible to get the operator's tractionary report by unit id.
     *
     * @param unitId the unit id.
     * @returns structure containing requested report
     */
    getOperatorTractionaryHomeReportByUnit(unitId: number): Observable<TractionaryOperatorHomeReport> {
        return this.mouraConnectServer.get(`/reports/home/tractionary/operator/unit/${unitId}`, null, null);
    }

    /**
     * Method responsible to get the operator's tractionary report by battery room id.
     *
     * @param batteryRoomId the battery room id.
     * @returns structure containing requested report
     */
    getOperatorTractionaryHomeReportByBatteryRoom(batteryRoomId: number): Observable<TractionaryOperatorHomeReport> {
        return this.mouraConnectServer.get(`/reports/home/tractionary/operator/batteryroom/${batteryRoomId}`, null, null);
    }

    /**
     * Method responsible to get the coordinator's tractionary report by customer id.
     *
     * @param customerId the customer id.
     * @returns structure containing requested report
     */
    getCoordinatorTractionaryHomeReportByCustomer(customerId: number): Observable<TractionaryCoordinatorHomeReport> {
        return this.mouraConnectServer.get(`/reports/home/tractionary/coordinator/customer/${customerId}`, null, null);
    }

    /**
     * Method responsible to get the coordinator's tractionary report by unit id.
     *
     * @param unitId the unit id.
     * @returns structure containing requested report
     */
    getCoordinatorTractionaryHomeReportByUnit(unitId: number): Observable<TractionaryCoordinatorHomeReport> {
        return this.mouraConnectServer.get(`/reports/home/tractionary/coordinator/unit/${unitId}`, null, null);
    }

    /**
     * Method responsible to get the coordinator's tractionary report by battery room id.
     *
     * @param batteryRoomId the battery room id.
     * @returns structure containing requested report
     */
    getCoordinatorTractionaryHomeReportByBatteryRoom(batteryRoomId: number): Observable<TractionaryCoordinatorHomeReport> {
        return this.mouraConnectServer.get(`/reports/home/tractionary/coordinator/batteryroom/${batteryRoomId}`, null, null);
    }

    /**
     * Method responsible to get the stationary report by customer id.
     *
     * @param customerId the customer id.
     * @returns structure containing requested report
     */
    getStationaryHomeReportByCustomer(customerId: number): Observable<StationaryHomeReport> {
        return this.mouraConnectServer.get(`/reports/home/stationary/customer/${customerId}`, null, null);
    }

    /**
     * Method responsible to get the stationary report by unit id.
     *
     * @param unitId the unit id.
     * @returns structure containing requested report
     */
    getStationaryHomeReportByUnit(unitId: number): Observable<StationaryHomeReport> {
        return this.mouraConnectServer.get(`/reports/home/stationary/unit/${unitId}`, null, null);
    }
}
