import { HttpParams } from '@angular/common/http';
import { GenericSearchParameters } from './generic-search-parameters.model';

/**
 * Model class representing user search parameters
 */
export class UserSearchParameters implements GenericSearchParameters {
    name: string;
    email: string;
    jobTitle: string;
    profile: string;
    internalUser: string;
    businessAreaId: number;

    /**
     * Default Constructor
     */
    constructor() {
        this.name = '';
        this.email = '';
        this.jobTitle = '';
        this.internalUser = '';
        this.businessAreaId = 0;
        this.profile = '';
    }

    /**
     * @see GenericSearchParameters/generateHttpParams()
     */
    generateHttpParams(): HttpParams {
        // initialize parameter list
        let httpParams: HttpParams = new HttpParams();

        // assign name parameter
        if (this.name && this.name.trim().length > 0) {
            httpParams = httpParams.append('name', this.name);
        }

        // assign email parameter
        if (this.email && this.email.trim().length > 0) {
            httpParams = httpParams.append('email', this.email);
        }

        // assign profile parameter
        if (this.profile && this.profile.trim().length > 0) {
            httpParams = httpParams.append('profile', this.profile);
        }

        // assign job title parameter
        if (this.jobTitle && this.jobTitle.trim().length > 0) {
            httpParams = httpParams.append('jobTitle', this.jobTitle);
        }

        // assign internal user filter parameter
        if (this.internalUser && this.internalUser.trim().length > 0) {
            httpParams = httpParams.append('internalUser', this.internalUser);
        }

        // assign customer id parameter
        if (this.businessAreaId && this.businessAreaId > 0) {
            httpParams = httpParams.append('businessAreaId', this.businessAreaId.toString());
        }

        return httpParams;
    }
}
