import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from '../../shared/generic-service.service';
import { MouraConnectServer } from '../../shared/mechanism/moura-connect-server.service';
import { Battery } from '../../shared/model/battery.model';

/**
 * Class responsible for dealing with batteries
 */
@Injectable()
export class BatteryService extends GenericService<Battery> {

    /**
     * Default Constuctor
     * @param mouraConnectServer moura connect server instance
     */
    constructor(protected mouraConnectServer: MouraConnectServer) {
        // call super
        super('/batteries', mouraConnectServer);
    }

    /**
     * Method responsible for updating battery model
     *
     * @param batteryId the battery id
     * @param modelId the model id
     */
    public updateBatteryModel(batteryId: number, modelId: number): Observable<string> {
        return this.mouraConnectServer.patch<string>(`${this.restAPIElement}/${batteryId}/model/${modelId}`);
    }
}
