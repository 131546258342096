import { BatteryRoom } from './battery-room.model';

/**
 * Model class representing an unit
 */
export class Unit {
    id: number;
    description: string;
    cnpj: string;
    address: string;
    uf: string;
    city: string;
    latitude: number;
    longitude: number;
    modifiedDate: Date;
    batteryRooms: BatteryRoom[];
}
