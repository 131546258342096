import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';
import { GenericMaintainEntityComponent } from '../../shared/generic-maintain-entity-component';
import { SecurityManager } from '../../shared/mechanism/security-manager.service';
import { Customer } from '../../shared/model/customer.model';
import { UserProfile } from '../../shared/model/enum/user-profile.enum';
import { ServiceOrder } from '../../shared/model/service-order.model';
import { Unit } from '../../shared/model/unit.model';
import { User } from '../../shared/model/user.model';
import { CustomerService } from '../customer/customer.service';
import { ServiceOrderService } from './service-order.service';

/**
 * Maintain service order component controller class
 */
@Component({ selector: 'app-maintain-service-order', templateUrl: './maintain-service-order.component.html' })
export class MaintainServiceOrderComponent extends GenericMaintainEntityComponent<ServiceOrder> implements OnInit {
    /**
     * Customers to be loaded on options component
     */
    customers: Customer[];

    /**
     * Adresses to be loaded on options component
     */
    units: Unit[];

    /**
     * Property responsible for definiing the minimum date for the service order
     */
    minServiceOrderDate: Date;

    /**
     * The default constructor
     *
     * @param toastService toast service
     * @param serviceOrderService service for service order matters
     * @param customerService service for customer matters
     * @param router router service
     * @param activatedRoute activated router
     * @param securityManager mechanism to deal with security matters
     */
    constructor(protected toastService: ToastrService, protected serviceOrderService: ServiceOrderService, private customerService: CustomerService, protected router: Router, protected activatedRoute: ActivatedRoute, private securityManager: SecurityManager) {
        // call super
        super(toastService, serviceOrderService, router, activatedRoute, 'serviceorders');

        // initialize model
        this.model = new ServiceOrder();
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // call super
        super.ngOnInit();

        // set minimum service order date
        this.minServiceOrderDate = new Date();
        this.minServiceOrderDate = moment(this.minServiceOrderDate).set({day: this.minServiceOrderDate.getDay() + 1}).startOf('d').toDate();

        // load customers
        this.loadCustomers();

        // as soon as we have the model loaded (edition mode) cut off itself as parent
        this.modelLoaded.subscribe(() => {
            // We need to perform this instruction due to a conjunction of 2 issues:
            // - bsdatepicker only accepts pure Date objects, otherwise a 'Invalid Date' is displayed
            // - typescript generates a Date from JSON conversion from Rest API but internally it is a number, which cause the problem described above
            this.model.executionDate = new Date(this.model.executionDate);
            this.model.executionDate = new Date(this.model.executionDate.valueOf() + this.model.executionDate.getTimezoneOffset() * 60000);

            // load unit
            this.loadCustomerAdresses();
        });

        // define page title
        this.pageTitle = this.isEdition ? 'Editar Ordem de Serviço' : 'Nova Ordem de Serviço';
    }

    /**
     * Method responsible to assign business area to the user's model as soon as customer is selected
     */
    setBusinessAreaToModel() {
        // select customer object associated to user's selection
        const selectedCustomer: Customer = this.customers.find((customer: Customer) => customer.id === Number(this.model.customerId));
        if (isNullOrUndefined(selectedCustomer) ) {
            this.model.businessAreaId = null;
        }
        else {
            this.model.businessAreaId = selectedCustomer.businessAreaId;
        }
    }

    /**
     * Method responsible for loading all unit after customer selection by user
     */
    loadCustomerAdresses() {
        if (this.isEdition) {
            this.units = this.model.customer.units;
        }
        else if (this.model.customerId) {
            this.units = this.customers.find(c => c.id === Number(this.model.customerId)).units;
        }
        else {
            this.units = null;
        }
    }

    /**
     * Method responsible for loading all customers
     */
    private loadCustomers() {
        // business area associated to logged user
        const loggedUser: User = this.securityManager.getLoggedUser();

        // in according to the user profile, check what customers should be seen by logged user
        if (loggedUser.profile === UserProfile.EXECUTIVE || loggedUser.profile === UserProfile.ADMIN) {
            this.customerService.getAllEntities().subscribe(
                (data) => this.assignAndSortCustomerData(data),
                (responseError) => this.handleErrors(responseError)
            );
        }
        else {
            this.customerService.getCustomersByBusinessArea(loggedUser.businessAreaId).subscribe(
                (data) => this.assignAndSortCustomerData(loggedUser.profile !== UserProfile.COORDINATOR ? data : data.filter((customer: Customer) => loggedUser.customers.findIndex((loggedUserCustomer: Customer) => customer.id === loggedUserCustomer.id) !== -1)),
                (responseError) => this.handleErrors(responseError)
            );
        }
    }

    /**
     * Method responsible for assigning and sorting customer data (callback for loading customers)\
     *
     * @param customers customers to be assigned and ordered
     */
    private assignAndSortCustomerData(customers: Customer[]) {
        // get all customers
        this.customers = customers;

        // sort customers
        this.customers.sort((a: Customer, b: Customer) => a.name.localeCompare(b.name));

    }
}
