import { Injectable } from '@angular/core';
import { GenericService } from '../../shared/generic-service.service';
import { MouraConnectServer } from '../../shared/mechanism/moura-connect-server.service';
import { AlertType } from '../../shared/model/alert-type.model';

/**
 * Class responsible for dealing with alert types
 */
@Injectable()
export class AlertTypeService extends GenericService<AlertType> {

    /**
     * Default Constuctor
     *
     * @param mouraConnectServer moura connect server instance
     */
    constructor(protected mouraConnectServer: MouraConnectServer) {
        // call super
        super('/alerttypes', mouraConnectServer);
    }
}
