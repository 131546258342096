import { BatteryRoom } from './battery-room.model';
import { Customer } from './customer.model';
import { Device } from './device.model';
import { AlertTypeCode } from './enum/alert-type-code.enum';
import { BatteryType } from './enum/battery-type.enum';
import { Model } from './model.model';
import { Unit } from './unit.model';

/**
 * Model class representing an battery
 */
export class Battery {
    id: number;
    usage: string;
    type: BatteryType;
    operationsIdentification: string;
    serialNumber: string;
    deviceId: number;
    device: Device;
    modelId: number;
    model: Model;
    batteryRoomId: number;
    batteryRoom: BatteryRoom;
    modifiedDate: Date;
    customer: Customer;
    customerId: number;
    unit: Unit;
    unitId: number;
    temperature: number;
    tension: number;
    electricCurrent: number;
    electricCurrentPeak: number;
    forkliftIdentification: number;
    dataTrackingSequenceNumber: number;
    stateOfCharge: number;
    currentAlerts: AlertTypeCode[];
    status: string;
    autonomy: number;
    nominalTension: number;
    manufacturingDate: Date;
    lastDataPackageProcessedDate: Date;
    lastDataPackageReceivedDate: Date;
    nominalCapacityEightHours: number;
    nominalCapacitySixHours: number;
    nominalCapacityFiveHours: number;
    uniqueIdentifier: string;
}
