import { Component, Input } from '@angular/core';

/**
 * Home data card component controller class
 */
@Component({ selector: 'app-home-data-card', templateUrl: './home-data-card.component.html', styleUrls: ['./home-data-card.component.scss']})
export class HomeDataCardComponent {
    /**
     * Icon to be displayed by this card
     */
    @Input() iconUrl: string;

    /**
     * Value to displayed by this card
     */
    @Input() value: string;

    /**
     * Description to displayed by this card
     */
    @Input() description: string;
}
