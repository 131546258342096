import { HttpParams } from '@angular/common/http';

import { GenericSearchParameters } from './generic-search-parameters.model';

/**
 * Model class representing device software search parameters
 */
export class DeviceSoftwareSearchParameters implements GenericSearchParameters {
    userId: number;
    version: string;
    releaseNotes: string;
    active: string;

    /**
     * Default constructor
     */
    constructor() {
        this.userId = 0;
        this.version = '';
        this.releaseNotes = '';
        this.active = '';
    }

    /**
     * @see GenericSearchParameters/Parameters/generateHttpParams()
     */
    generateHttpParams(): HttpParams {
        // initialize parameter lsit
        let httpParams: HttpParams = new HttpParams();

        // assign user id parameter
        if (this.userId > 0) {
            httpParams = httpParams.append('userId', this.userId.toString());
        }

        // assign version parameter
        if (this.version && this.version.trim().length > 0) {
            httpParams = httpParams.append('version', this.version);
        }

        // assign release notes parameter
        if (this.releaseNotes && this.releaseNotes.trim().length > 0) {
            httpParams = httpParams.append('releaseNotes', this.releaseNotes);
        }

        // assign active parameter
        if (this.active && this.active.trim().length > 0) {
            httpParams = httpParams.append('active', this.active.toString());
        }

        return httpParams;
    }
}
