import { LogEntrySource } from './enum/log-entry-source.enum';

/**
 * Model class representing a log entry
 */
export class LogEntry {
    id: number;
    user: string;
    location: string;
    source: LogEntrySource;
    message: string;
    exception: string;
    createdDate: Date;
}
