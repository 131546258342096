import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MouraConnectServer } from '../shared/mechanism/moura-connect-server.service';

/**
 * Class responsible for dealing with application services
 */
@Injectable()
export class ApplicationService {

    /**
     * Default Constuctor
     *
     * @param mouraConnectServer moura connect server instance
     */
    constructor(protected mouraConnectServer: MouraConnectServer) {
        // Default constructor
    }

    /**
     * Method responsible for returning the application version information
     *
     * @returns observable to an application version instance
     */
    getApplicationVersion(): Observable<any> {
        return this.mouraConnectServer.get('/application/version');
    }
}
