import { BusinessArea } from './business-area.model';
import { Customer } from './customer.model';
import { DeviceSoftwareUpdateRequisitionItem } from './device-software-update-requisition-item.model';
import { DeviceSoftwareUpdateRequisitionStatus } from './enum/device-software-update-requisition-status.enum';
import { DeviceSoftwareUpdateRequisitionType } from './enum/device-software-update-requisition-type.enum';
import { Unit } from './unit.model';

/**
 * Model class representing a device software update requisition
 */
export class DeviceSoftwareUpdateRequisition {
    id: number;
    customer: Customer;
    customerId: number;
    businessArea: BusinessArea;
    businessAreaId: number;
    unit: Unit;
    unitId: number;
    status: DeviceSoftwareUpdateRequisitionStatus;
    type: DeviceSoftwareUpdateRequisitionType;
    executionDate: Date;
    modifiedDate: Date;
    items: DeviceSoftwareUpdateRequisitionItem[];
}
