import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { ToastrService } from 'ngx-toastr';
import { GenericPageableSearchComponent } from '../../shared/generic-pageable-search-component.component';
import { ExportDataManager } from '../../shared/mechanism/export-data-manager.service';
import { SecurityManager } from '../../shared/mechanism/security-manager.service';
import { Device } from '../../shared/model/device.model';
import { DeviceSearchParameters } from '../../shared/model/search/device-search-parameters.model';
import { DeviceService } from './device.service';

/**
 * Search device component controller class
 */
@Component({ selector: 'app-search-devices', templateUrl: './search-devices.component.html' })
export class SearchDevicesComponent extends GenericPageableSearchComponent<Device> implements OnInit {
    /**
     * Filters object. Overwrites the inherited generic filters object.
     */
    filters: DeviceSearchParameters;

    /**
     * Business areas to be used as filter's content
     */
    devices: Device[];

    /**
     * Default constructor for generic application component
     * @param toastService toast service
     * @param deviceService service for device matters
     * @param router router service
     * @param exportDataManager export the ExportDataManager
     * @param SpinnerVisibilityService spinner service (will be triggered manually)
     */
    constructor(protected toastService: ToastrService, protected deviceService: DeviceService, protected router: Router, protected securityManager: SecurityManager, private exportDataManager: ExportDataManager, private spinner: SpinnerVisibilityService) {
        // call super
        super(toastService, deviceService, router, 'devices');
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // call super
        super.ngOnInit();

        // instantiate filters
        this.filters = new DeviceSearchParameters();

        // setup pageable object
        this.pageable.sortField = 'chipIdentification';

        // perform initial search
        this.search();
    }

    /**
     * Method responsible for uploading a spreadsheet to the server
     *
     * @param fileListForUploading list of files to be uploaded
     */
    uploadDeviceSpreadsheet(fileListForUploading: FileList) {
        // trigger spinner
        this.spinner.show();

        // try processing devices from spreadsheet
        this.deviceService.processDevicesFromSpreadsheet(fileListForUploading.item(0)).subscribe(
            (devices) => {
                // hide spinner
                this.spinner.hide();

                // try creating device list
                this.deviceService.createDevices(devices).subscribe(
                    () => {
                        // show success message
                        this.showSuccessMessage('Dispositivos criados com sucesso');

                        // redo search
                        this.search();
                    },
                    (responseError) => this.handleErrors(responseError)
                );
            },
            (error) => {
                // hide spinner
                this.spinner.hide();

                // show error message
                this.showErrorMessage(error);
            }
        );
    }

    /**
     * Create a empty Device for template and export as a spreedsheat
     */
    exportDeviceSpreadSheetTemplate() {
        // generate a element on the fly
        const downloadLink: HTMLAnchorElement = document.createElement('a');
        downloadLink.download = 'ModeloUploadDispositivos.xlsx';
        downloadLink.href = 'assets/downloads/ModeloUploadDispositivos.xlsx';
        downloadLink.click();

        // remove download link
        downloadLink.remove();
    }

    /**
     * Method responsible for cleaning the search parameters
     */
    cleanFilters() {
        // return the others to default values
        this.filters.chipIdentification = '';
        this.filters.serialNumber = '';
        this.filters.type = '';
    }
}
