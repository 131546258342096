import { Customer } from './customer.model';

/**
 * Model class representing a head office
 */
export class HeadOffice {
    id: number;
    name: string;
    socialName: string;
    cnpj: string;
    customers: Array<Customer>;
    modifiedDate: Date;
}
