import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { GenericPageableSearchComponent } from '../../shared/generic-pageable-search-component.component';
import { SecurityManager } from '../../shared/mechanism/security-manager.service';
import { Battery } from '../../shared/model/battery.model';
import { Customer } from '../../shared/model/customer.model';
import { UserProfile } from '../../shared/model/enum/user-profile.enum';
import { BatterySearchParameters } from '../../shared/model/search/battery-search-parameters.model';
import { User } from '../../shared/model/user.model';
import { CustomerService } from '../customer/customer.service';
import { BatteryService } from './battery.service';
import { MaintainBatteryComponent } from './maintain-battery.component';
import { HeadOffice } from '../../shared/model/head-office.model';
import { HeadOfficeService } from '../headoffice/head-office.service';

/**
 * Search batteries component controller class
 */
@Component({ selector: 'app-search-batteries', templateUrl: './search-batteries.component.html' })
export class SearchBatteriesComponent extends GenericPageableSearchComponent<Battery> implements OnInit {
    /**
     * Filters object. Overwrites the inherited generic filters object.
     */
    filters: BatterySearchParameters;

    /**
     * Head office to be used as filter's content
     */
    headOffices: HeadOffice[];

    /**
     * Variable to hold loaded customers.
     */
    loadedCustomers: Customer[];

    /**
     * Customer to be used as filter's content
     */
    customers: Customer[];

    /**
     * Modal Reference for manipulating new unit
     */
    modalReference: BsModalRef;

    /**
     * Default constructor for generic application component
     *
     * @param toastService toast service
     * @param batteryService service for battery area matters
     * @param router router service
     * @param customerService service for customer matters
     * @param securityManager security manager service
     * @param modalService modal service
     * @param headOfficeService head office service
     */
    constructor(protected toastService: ToastrService, protected batteryService: BatteryService, protected router: Router, private customerService: CustomerService, private securityManager: SecurityManager, private modalService: BsModalService, protected headOfficeService: HeadOfficeService) {
        // call super
        super(toastService, batteryService, router, 'batteries');
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // call super
        super.ngOnInit();

        // get logged user
        const loggedUser = this.securityManager.getLoggedUser();

        // load customers
        this.loadCustomers();

        // instantiate filters
        this.filters = new BatterySearchParameters();
        if (loggedUser.profile === UserProfile.ADMIN || loggedUser.profile === UserProfile.EXECUTIVE) {
            // load head offices
            this.loadHeadOffices();
        }

        // set the user customer id and business area in order to show information in the correct way
        this.filters.businessAreaId = loggedUser.businessAreaId;
        this.filters.customersId = loggedUser.internal && loggedUser.profile === UserProfile.COORDINATOR ? loggedUser.customers.map((customer: Customer) => customer.id) : !loggedUser.internal || (loggedUser.internal && loggedUser.profile === UserProfile.OPERATOR) ? loggedUser.customers.map((customer: Customer) => customer.id) : [0];

        // setup pageable object
        this.pageable.sortField = 'operationsIdentification';

        // perform initial search
        this.search();
    }

    /**
     * Method responsible for loading customers
     */
    loadCustomers() {
        // get logged user
        const loggedUser: User = this.securityManager.getLoggedUser();

        // check if user can get all customers or must load filtering by business area
        if (loggedUser.profile === UserProfile.ADMIN || loggedUser.profile === UserProfile.EXECUTIVE) {
            this.customerService.getAllEntities().subscribe(
                (data) => {
                    // get all customers
                    this.customers = loggedUser.profile !== UserProfile.COORDINATOR ? data : data.filter((customer: Customer) => loggedUser.customers.findIndex((loggedUserCustomer: Customer) => customer.id === loggedUserCustomer.id) !== -1);

                    // set the loaded customers
                    this.loadedCustomers = data;

                    // sort customers
                    this.customers.sort((a: Customer, b: Customer) => a.name.localeCompare(b.name));
                },
                (responseError) => this.handleErrors(responseError)
            );
        }
        else {
            this.customerService.getCustomersByBusinessArea(loggedUser.businessAreaId).subscribe(
                (data) => {
                    // get all customers
                    this.customers = loggedUser.profile !== UserProfile.COORDINATOR ? data : data.filter((customer: Customer) => loggedUser.customers.findIndex((loggedUserCustomer: Customer) => customer.id === loggedUserCustomer.id) !== -1);

                    // sort customers
                    this.customers.sort((a: Customer, b: Customer) => a.name.localeCompare(b.name));
                },
                (responseError) => this.handleErrors(responseError)
            );
        }
    }

    /**
     * Method responsible for responding if customer's filter must be shown
     *
     * @returns flag indicating if user can change customer
     */
    canUserChangeCustomer(): boolean {
        return this.securityManager.getLoggedUser().profile !== UserProfile.OPERATOR;
    }

    /**
     * Method responsible for cleaning the search parameters
     */
    cleanFilters() {
        // clear the filter with the default value
        this.filters.operationsIdentification = '';
        this.filters.unitId = 0;
        this.filters.usage = '';
        this.filters.type = '';
        this.filters.status = '';
        this.filters.headOfficeId = 0;
        if (this.canUserChangeCustomer()) {
            this.filters.customersId = [0];

            // reset the customers list
            this.customers = this.loadedCustomers;
        }
    }

    /**
     * Method responsible to load the edit battery model madal
     *
     * @param batteryId the selected battery id
     */
    goToEditModel(batteryId: number) {
        // initial state will contain a callback function
        const initialState = {
            model: this.results.content.find((battery: Battery) => battery.id === batteryId),
        };

        // call modal
        this.modalReference = this.modalService.show(MaintainBatteryComponent, { initialState, class: 'modal-lg' });
    }

    /**
     * Method responsible to verify if user can edit battery model
     *
     * @returns flag indicating if user can edit battery model
     */
    canUserEditBatteryModel(): boolean {
        const user = this.securityManager.getLoggedUser();
        return user.profile !== UserProfile.EXECUTIVE && user.profile !== UserProfile.MANAGER;
    }

    /**
     * Method responsible to verify if should display head office filter
     *
     * @returns flag indicating if should display head office filter
     */
    shouldDisplayHeadOfficeFilter(): boolean {
        // get logged user
        const loggedUser = this.securityManager.getLoggedUser();

        return loggedUser.profile === UserProfile.ADMIN || loggedUser.profile === UserProfile.EXECUTIVE;
    }

    /**
     * Method responsible to filter customers list by head office if necessary
     */
    filterCustomersByHeadOffice() {
        // filter the list of customer if necessary
        this.customers = Number(this.filters.headOfficeId) !== 0 ? this.loadedCustomers.filter((customer: Customer) => customer.headOfficeId === Number(this.filters.headOfficeId)) : this.loadedCustomers;
    }

    /**
     * Method responsible for loading all head offices
     */
    private loadHeadOffices(): void {
        this.headOfficeService.getAllEntities().subscribe(
            (data) => {
                // get all head offices
                this.headOffices = data;

                // sort head offices
                this.headOffices.sort((a: HeadOffice, b: HeadOffice) => a.name.localeCompare(b.name));
            },
            (responseError) => this.handleErrors(responseError)
        );
    }
}
