import { HttpParams } from '@angular/common/http';
import { GenericSearchParameters } from './generic-search-parameters.model';

/**
 * Model class representing device search parameters
 */
export class DeviceSearchParameters implements GenericSearchParameters {
    chipIdentification: string;
    serialNumber: string;
    type: string;

    /**
     * Default Constructor
     */
    constructor() {
        this.chipIdentification = '';
        this.serialNumber = '';
        this.type = '';
    }

    /**
     * @see GenericSearchParameters/generateHttpParams()
     */
    generateHttpParams(): HttpParams {
        // initialize parameter list
        let httpParams: HttpParams = new HttpParams();

        // assign chip identification parameter
        if (this.chipIdentification && this.chipIdentification.trim().length > 0) {
            httpParams = httpParams.append('chipIdentification', this.chipIdentification);
        }

        // assign serialNumber parameter
        if (this.serialNumber && this.serialNumber.trim().length > 0) {
            httpParams = httpParams.append('serialNumber', this.serialNumber);
        }

        // assign type filter parameter
        if (this.type && this.type.trim().length > 0) {
            httpParams = httpParams.append('type', this.type);
        }

        return httpParams;
    }
}
