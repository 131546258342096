import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

/**
 * Pipe responsible to format the month number to month abbreviation
 */
@Pipe({ name: 'month' })
export class MonthPipe implements PipeTransform {

    transform(monthNumber: number): string {
        // Return the abbreviated month
        return moment(monthNumber, 'M').locale('pt-br').format('MMM');
    }

}
