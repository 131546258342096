import { DeviceSoftware } from './device-software.model';
import { Device } from './device.model';
import { DeviceSoftwareUpdateRequisitionItemStatus } from './enum/device-software-update-requisition-item-status.enum';
import { User } from './user.model';

/**
 * Model class representing a device sosftware update requisition item
 */
export class DeviceSoftwareUpdateRequisitionItem {
    id: number;
    executedDate: Date;
    device: Device;
    deviceId: number;
    deviceSoftware: DeviceSoftware;
    deviceSoftwareId: number;
    user: User;
    userId: number;
    deviceSoftwareUpdateRequisitionId: number;
    status: DeviceSoftwareUpdateRequisitionItemStatus;
    description: String;
}
