import { Injectable } from '@angular/core';
import { GenericService } from '../../shared/generic-service.service';
import { MouraConnectServer } from '../../shared/mechanism/moura-connect-server.service';
import { BusinessArea } from '../../shared/model/business-area.model';

/**
 * Class responsible for dealing with business area matters
 */
@Injectable()
export class BusinessAreaService extends GenericService<BusinessArea> {

    /**
     * Default Constuctor
     *
     * @param mouraConnectServer moura connect server instance
     */
    constructor(protected mouraConnectServer: MouraConnectServer) {
        // call super
        super('/businessareas', mouraConnectServer);
    }
}
