import { BatteryManufacturer } from './enum/battery-manufacturer.enum';
import { BatteryType } from './enum/battery-type.enum';

/**
 * Model class representing a model
 */
export class Model {
    id: number;
    description: string;
    manufacturer: BatteryManufacturer;
    type: BatteryType;
    modifiedDate: Date;
    nominalCapacityEightHours: number;
    nominalCapacitySixHours: number;
    nominalCapacityFiveHours: number;
}
