import { Component, OnInit } from '@angular/core';
import { GenericComponent } from '../../shared/generic-component.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DeviceSoftwareUpdateRequisitionItem } from '../../shared/model/device-software-update-requisition-item.model';
import { DeviceSoftware } from '../../shared/model/device-software.model';
import { DeviceSoftwareService } from '../devicesoftware/device-software.service';
import { Device } from '../../shared/model/device.model';
import { DeviceService } from '../device/device.service';
import { isNullOrUndefined } from 'util';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

/**
 * Maintain device software update requisition item component controller class
 */
@Component({ selector: 'app-maintain-device-software-update-requisition-item', templateUrl: './maintain-device-software-update-requisition-item.component.html', })
export class MaintainDeviceSoftwareUpdateRequisitionItemComponent extends GenericComponent implements OnInit {
    /**
     * Device software update requisition item model
     */
    model: DeviceSoftwareUpdateRequisitionItem;

    /**
     * Updated device software update requisition item model
     */
    updatedModel: DeviceSoftwareUpdateRequisitionItem;

    /**
     * List of maintain device software update requisition items
     */
    itemList: DeviceSoftwareUpdateRequisitionItem[];

    /**
     * Variable responsible to hold the flag that indicates if the current operation is an edition
     */
    isEdition: boolean = false;

    /**
     * Variable responsible to hold the page title string
     */
    pageTitle: string;

    /**
     * Variable responsible to hold the device softwares
     */
    deviceSoftwares: DeviceSoftware[];

    /**
     * Variable responsible to hold the selected device software from dropdown
     */
    selectedDeviceSoftware: DeviceSoftware[];

    /**
     * Variable responsible to hold the devices
     */
    devices: Device[];

    /**
     * Variable responsible to hold the load devices
     */
    loadedDevices: Device[];

    /**
     * Variable responsible to hold the selected device from dropdown
     */
    selectedDevice: Device[];

    /**
     * The device dropdown settings.
     */
    deviceDropdownSettings: IDropdownSettings;

    /**
     * The device software dropdown settings.
     */
    deviceSoftwareDropdownSettings: IDropdownSettings;

    /**
     * Flag responsible to check if is in field requisition
     */
    isInFieldRequisition: boolean;

    /**
     * Default constructor
     *
     * @param modalReference reference to modal opener
     * @param toastService toast service
     * @param deviceSoftwareService device software service
     * @param deviceService device service
     */
    constructor(public modalReference: BsModalRef, protected toastService: ToastrService, private deviceSoftwareService: DeviceSoftwareService, private deviceService: DeviceService) {
        // call super
        super(toastService);

        // initialize model
        this.model = new DeviceSoftwareUpdateRequisitionItem();

        // load device softwares
        this.loadDeviceSoftwares();

        // set the device dropdown settings
        this.deviceDropdownSettings = {
            singleSelection: true,
            idField: 'id',
            textField: 'serialNumber',
            itemsShowLimit: 1,
            allowSearchFilter: true,
            enableCheckAll: false,
            searchPlaceholderText: 'Buscar',
            noDataAvailablePlaceholderText: 'Sem dispositivos disponíveis',
            closeDropDownOnSelection: true,
        };

        // set the device software dropdown settings
        this.deviceSoftwareDropdownSettings = {
            singleSelection: true,
            idField: 'id',
            textField: 'version',
            itemsShowLimit: 1,
            allowSearchFilter: true,
            enableCheckAll: false,
            searchPlaceholderText: 'Buscar',
            noDataAvailablePlaceholderText: 'Sem softwares do dispositivo disponíveis',
            closeDropDownOnSelection: true,
        };
    }

    /**
    * @see @angular/core/OnInit/ngOnInit()
    */
    ngOnInit() {
        // define page title
        this.pageTitle = this.isEdition ? 'Editar Item da Requisição de Atualização de Software de Dispositivo' : 'Nova Item da Requisição de Atualização de Software de Dispositivo';

        // init model that will be updated
        this.updatedModel = Object.create(this.model);

        // initialize selected item from dropdowns
        this.selectedDevice = [this.updatedModel.device];
        this.selectedDeviceSoftware = [this.updatedModel.deviceSoftware];

        // load devices
        this.loadDevices();
    }

    /**
     * Method responsible for cancelling device software update requisition item maintainability
     */
    cancel() {
        // clear model in order to avoid the opener getting trash
        this.model = new DeviceSoftwareUpdateRequisitionItem();

        // close it
        this.modalReference.hide();
    }

    /**
     * Method responsible for adding a new item or editing an existent one by calling a callback
     */
    recordItem() {
        this.updatedModel.deviceSoftware = this.deviceSoftwares.find((deviceSoftware: DeviceSoftware) => deviceSoftware.id === Number(this.updatedModel.deviceSoftwareId));
        this.updatedModel.device = this.devices.find((device: Device) => device.id === Number(this.updatedModel.deviceId));

        if (!isNullOrUndefined(this.updatedModel.deviceSoftwareId) && !isNullOrUndefined(this.updatedModel.deviceId)) {
            // check if its a edition
            if (this.isEdition) {
                // copy update model's properties to model
                Object.keys(this.updatedModel).forEach(key => this.model[key] = this.updatedModel[key]);
            }
            // if it's a creation
            else {
                // check if list must be initialized
                if (this.modalReference.content.itemList === undefined) {
                    this.modalReference.content.itemList = [];
                }

                // callback to push the updated reference
                this.modalReference.content.itemList.push(this.updatedModel);
            }

            // hide modal
            this.modalReference.hide();
        }
    }

    /**
     * Method load devices filted.
     */
    loadFilteredDevices() {
        this.devices = this.loadedDevices.filter((device: Device) => isNullOrUndefined(this.itemList.find((item: DeviceSoftwareUpdateRequisitionItem) => Number(item.deviceId) === device.id)));
    }

    /**
     * Method resonsible to load the device softwares.
     */
    private loadDeviceSoftwares() {
        this.deviceSoftwareService.getAllActiveDeviceSoftwares().subscribe((deviceSoftwares: DeviceSoftware[]) => this.deviceSoftwares = deviceSoftwares);
    }
    /**
     * Method resonsible to load the devices.
     */
    private loadDevices() {
        this.deviceService.findByActive(this.isInFieldRequisition).subscribe((devices: Device[]) => {
            // set loaded devices
            this.loadedDevices = devices;

            // filter devices options
            this.loadFilteredDevices();
        });
    }
}
