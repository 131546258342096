import { HttpParams } from '@angular/common/http';
import { GenericSearchParameters } from './generic-search-parameters.model';

/**
 * Model class representing customer search parameters
 */
export class CustomerSearchParameters implements GenericSearchParameters {
    name: string;
    socialName: string;
    cnpj: string;
    businessAreaId: number;
    customerId: number;

    /**
     * Default Constructor
     */
    constructor() {
        this.name = '';
        this.socialName = '';
        this.cnpj = '';
        this.businessAreaId = 0;
        this.customerId = 0;
    }

    /**
     * @see GenericSearchParameters/generateHttpParams()
     */
    generateHttpParams(): HttpParams {
        // initialize parameter list
        let httpParams: HttpParams = new HttpParams();

        // assign name parameter
        if (this.name && this.name.trim().length > 0) {
            httpParams = httpParams.append('name', this.name);
        }

        // assign social name parameter
        if (this.socialName && this.socialName.trim().length > 0) {
            httpParams = httpParams.append('socialName', this.socialName);
        }

        // assign cnpj parameter
        if (this.cnpj && this.cnpj.trim().length > 0) {
            httpParams = httpParams.append('cnpj', this.cnpj);
        }

        // assign business area parameter
        if (this.businessAreaId > 0) {
            httpParams = httpParams.append('businessAreaId', this.businessAreaId.toString());
        }

        // assign customer id
        if (this.customerId > 0) {
            httpParams = httpParams.append('customerId', this.customerId.toString());
        }

        return httpParams;
    }
}
