import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe resonsible to translate the device software update requisition status
 */
@Pipe({ name: 'deviceSoftwareUpdateRequisitionStatus' })
export class DeviceSoftwareUpdateRequisitionStatusPipe implements PipeTransform {

    /**
     * @see @angular/core/PipeTransform/transform(value: any, ...args: any[])
     */
    transform(deviceSoftwareUpdateRequisitionStatus: string): string {
        let deviceSoftwareUpdateRequisitionStatusName: string;

        switch (deviceSoftwareUpdateRequisitionStatus) {
            case 'OPEN':
                deviceSoftwareUpdateRequisitionStatusName = 'Em aberto';
                break;
            case 'ONGOING':
                deviceSoftwareUpdateRequisitionStatusName = 'Em andamento';
                break;
            case 'CLOSED':
                deviceSoftwareUpdateRequisitionStatusName = 'Concluída';
                break;
            default:
                deviceSoftwareUpdateRequisitionStatusName = 'Nenhuma descrição para ' + deviceSoftwareUpdateRequisitionStatus;
                break;
        }

        return deviceSoftwareUpdateRequisitionStatusName;
    }

}
