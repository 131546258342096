import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from 'util';

/**
 * Pipe responsible to translate the IoT Network issue that occured while executing a service order item
 */
@Pipe({ name: 'serviceOrderItemIotNetworkIssue' })
export class ServiceOrderItemIotNetworkIssuePipe implements PipeTransform {

    /**
   * @see @angular/core/PipeTransform/transform(value: any, ...args: any[])
   */
    transform(serviceOrderItemIotNetworkIssue: string): string {
        let serviceOrderItemIotNetworkIssueName: string;

        // treat null or undefined scenario
        if (isNullOrUndefined(serviceOrderItemIotNetworkIssue)) {
            return '-';
        }

        switch (serviceOrderItemIotNetworkIssue) {
            case 'REGISTER_IGNORED':
                serviceOrderItemIotNetworkIssueName = 'Registro de dispositivo ignorado pelo usuário';
                break;
            case 'ALREADY_REGISTERED':
                serviceOrderItemIotNetworkIssueName = 'Dispositivo já estava registrado';
                break;
            default:
                serviceOrderItemIotNetworkIssueName = 'Nenhuma descrição para ' + serviceOrderItemIotNetworkIssue;
                break;
        }

        return serviceOrderItemIotNetworkIssueName;
    }
}
