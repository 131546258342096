import { Location } from '@angular/common';
import { ErrorHandler, Injectable } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { LogEntryService } from '../../core/log/log-entry.service';
import { LogEntrySource } from '../model/enum/log-entry-source.enum';
import { LogEntry } from '../model/log-entry.model';
import { SecurityManager } from './security-manager.service';

/**
 * Class responsible for being the application's default error handler
 * IMPORTANT: the default handleError method will not treat HttpErrorResponse, just Error
 */
@Injectable()
export class DefaultErrorHandler implements ErrorHandler {

    /**
     * Default constructor
     * @param logEntryService log entry service
     * @param securityManager security manager
     * @param location the location service
     */
    constructor(private logEntryService: LogEntryService, private securityManager: SecurityManager, private location: Location) { }

    /**
     * @see @angular/core/ErrorHandler#handleError(Error)
     *
     * This handle method will not treat HttpErrorResponse
     */
    handleError(error: Error) {
        // use console to log the error, if the environment is not prod
        console.error(error);

        // create an error log
        this.createErrorLog(error);
    }

    /**
     * Method responsible for creating an error log based on the caught error
     *
     * @param error caught error
     */
    createErrorLog(error: Error) {
        // call service to performe the creation if message and exception are not null or undefined
        if (!isNullOrUndefined(error) && !isNullOrUndefined(error.message) && !isNullOrUndefined(error.stack)) {
            // create object
            const logEntry: LogEntry = new LogEntry();
            logEntry.source = LogEntrySource.WEBSITE;
            logEntry.user = !isNullOrUndefined(this.securityManager.getLoggedUser()) ? this.securityManager.getLoggedUser().email : 'Usuário Anônimo';
            logEntry.location = this.location.path() ? this.location.path() : 'Não é possível determinar a localidade do erro';
            logEntry.message = error.message;
            logEntry.exception = error.stack;

            // create log entry
            this.logEntryService.createEntity(logEntry).subscribe();
        }
    }
}
