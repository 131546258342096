import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';

/**
 * Generic component class
 */
export class GenericComponent {

    /**
     * Default constructor for generic component
     *
     * @param toastService toast service
     */
    constructor(protected toastService: ToastrService) { }

    /**
     * Shows an error message in a toast. The message stays until closed manually.
     *
     * @param message the message to be shown
     */
    showErrorMessage(message: string) {
        // show toast
        this.toastService.error(message);

    }
    /**
     * Shows a success message in a toast.
     *
     * @param message the message to be shown
     */
    showSuccessMessage(message: string) {
        // show toast
        this.toastService.success(message);
    }

    /**
     * Shows a warning message in a toast.
     *
     * @param message the message to be shown
     */
    showWarningMessage(message: string) {
        // show toast
        this.toastService.warning(message);
    }

    /**
     * Shows an info message in a toast.
     *
     * @param message the message to be shown
     */
    showInfoMessage(message: string) {
        // show toast
        this.toastService.info(message);
    }

    /**
     * Method responsible for dealing with errors coming from server
     *
     * @param responseError responseError object returned from server
     */
    handleErrors(responseError: any) {
        // set error message to be shown
        if (isNullOrUndefined(responseError)) {
            // error without message and status code
            this.showErrorMessage('Ocorreu um erro ao tentar conectar-se ao servidor. Aguarde um momento e tente novamente');
        } else if (isNullOrUndefined(responseError.error) || isNullOrUndefined(responseError.error['message'])) {
            // error with defined status code, but without message
            this.showErrorMessage('Ocorreu um erro, aguarde um momento e tente novamente');
        } else {
            // error with defined message
            this.showErrorMessage(responseError.error['message']);
        }
    }
}
