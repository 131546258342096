import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';

import { MonthPipe } from '../../../shared/pipe/month.pipe';

/**
 * Controller class for the Battery Replacement Card Component.
 */
@Component({ selector: 'app-battery-replacement-card', templateUrl: './battery-replacement-card.component.html', styleUrls: ['./battery-replacement-card.component.scss'] })
export class BatteryReplacementCardComponent implements OnChanges {

    /**
     * Variable to handle the chart.
     */
    @ViewChild(BaseChartDirective) chart: BaseChartDirective;

    /**
     * Flag indicating whether the component has finished loading its content.
     */
    contentLoaded: boolean;

    /**
     * Variable to hold labels array.
     */
    labels: Array<string>;

    /**
     * Variable to hold data array.
     */
    data: Array<number>;

    /**
     * Variable to hold datasets input
     */
    datasets: any[];

    /**
     * Variable to store chart options
     */
    options: any;

    /**
     * The battery replacement summary grouped by model chart data.
     */
    @Input() batteryReplacementSummaryChartData: any;

    /**
     * Input variable that holds number of battery replacements
     */
    @Input() numberOfBatteryReplacements: number;

    /**
     * Default constructor
     *
     * @param monthPipe month pipe
     */
    constructor(private monthPipe: MonthPipe) {
        // initialize variables
        this.contentLoaded = false;

        // set chart options
        this.options = {
            maintainAspectRatio: true,
            scaleShowVerticalLines: false,
            responsive: true,
            tooltips: {
                enabled: true,
                mode: 'point'
            },
            legend: {
                display: false,
            },
            scales: {
                xAxes: [{
                    barPercentage: 1,
                    categoryPercentage: 0.5,
                    ticks: {
                        display: true,
                        padding: 10,
                    },
                    gridLines: {
                        drawTicks: false,
                        drawOnChartArea: false
                    }
                }],
                yAxes: [{
                    ticks: {
                        min: 0,
                        autoSkip: false,
                        padding: 5,
                        maxTicksLimit: 9,
                        display: true,
                    },
                    gridLines: {
                        drawBorder: false,
                        drawTicks: false
                    }
                }]
            }
        };
    }

    /**
     * @see @angular/core/OnChange/ngOnChanges()
     */
    ngOnChanges() {
        // build the chart
        this.buildChart();
    }

    /**
     * Method responsible to build the chart, setting the chart label, data and background color.
     */
    private buildChart() {
        // create the battery replacement summary chart data
        this.buildBatteryReplacementSummaryChartData();

        // Recreate the chart. Angular isn't able to update the chart correctly.
        if (this.chart && this.contentLoaded) {
            // destroy the current chart.
            this.chart.chart.destroy();
            this.chart.chart = 0;

            // set the values, labels, colors and options to the new chart.
            this.chart.datasets = this.datasets;
            this.chart.options = this.options;
            this.chart.labels = this.labels;
            this.chart.colors = this.datasets;

            // build the chart with the new values.
            this.chart.chart = this.chart.getChartBuilder(this.chart.ctx);
        }
    }

    /**
     * Method responsible to create the chart object for the battery replacement summary grouped by model.
     */
    private buildBatteryReplacementSummaryChartData() {
        // initialize the variable
        const chartData: Array<{ 'year': number, 'month': number, 'value': number }> = [];

        this.contentLoaded = false;
        this.labels = [];

        // build the chart data array
        for (const month in this.batteryReplacementSummaryChartData) {
            if (this.batteryReplacementSummaryChartData.hasOwnProperty(month)) {
                chartData.push({ 'year': Number(month.split('|')[0]), 'month': Number(month.split('|')[1]), 'value': this.batteryReplacementSummaryChartData[month] });
            }
        }

        // sort the chart data by month
        chartData.sort((a, b) => a.year - b.year || a.month - b.month);

        // set the chart variables
        this.labels = chartData.map(d => this.monthPipe.transform(d.month));
        this.data = chartData.map(d => d.value);

        // check if has value
        if (Math.max(...this.data) > 0) {
            // set the datasets
            this.datasets = [
                {
                    data: this.data,
                    backgroundColor: ['rgb(65,145,153,0.8)', 'rgb(90,160,153,0.8)', 'rgb(124,191,169,0.8)'],
                    label: 'Trocas'
                }
            ];

            // set content loaded
            this.contentLoaded = true;
        }
    }
}
