import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';
import { GenericComponent } from '../../shared/generic-component.component';
import { ExportDataManager } from '../../shared/mechanism/export-data-manager.service';
import { Battery } from '../../shared/model/battery.model';
import { AlertTypeCode } from '../../shared/model/enum/alert-type-code.enum';
import { BatteryType } from '../../shared/model/enum/battery-type.enum';
import { Measurement } from '../../shared/model/measurement.model';
import { Page } from '../../shared/model/page.model';
import { Pageable } from '../../shared/model/pageable.model';
import { Unit } from '../../shared/model/unit.model';
import { MeasurePipe } from '../../shared/pipe/measure.pipe';
import { BatteryService } from './battery.service';
import { MeasurementService } from './measurement.service';

/**
 * View battery data component controller class
 */
@Component({ selector: 'app-view-battery-data', templateUrl: './view-battery-data.component.html', styleUrls: ['./view-battery-data.component.scss'] })
export class ViewBatteryDataComponent extends GenericComponent implements OnInit {
    /**
     * Battery information
     */
    battery: Battery;

    /**
     * Measurement information
     */
    measurements: Measurement[];

    /**
     * Measurement structure to be displayed
     */
    currentMeasurements: any[] = [];

    /**
     * Data visualization mode (GRAPHIC OR TABLE)
     */
    dataVisualizationMode: string = 'GRAPHIC';

    /**
     * Filter for searching purposes
     */
    filter = { period: 'DAY', startDate: moment().toDate(), endDate: moment().toDate(), minCustomDate: moment().subtract(1, 'years').toDate(), maxCustomDate: moment().toDate() };

    /**
     * Pageable object to control pagination
     */
    pageable: Pageable = new Pageable();

    /**
     * Results to be used as dataset in table mode
     */
    results: Page<Measurement>;

    /**
     * Variable to indicate if menu is expanded or not
     */
    expandedMenu: boolean = true;

    /**
     * Flag to indicate if graphic mode is ready
     */
    preparedForGraphicMode: boolean = false;

    /**
     * The minimum width for the menu to be naturally expanded.
     * It collapses when the window becomes smaller than this value, in pixels.
     */
    private minWidthForExpandedMenu = 767.98;

    /**
     * Variable to hold map's marker array
     */
    mapMarkerArray: any[];

    /**
     * Variable to hold map's center latitude
     */
    mapCenterLatitude: number;

    /**
     * Variable to hold map's marker longitude
     */
    mapCenterLongitude: number;

    /**
     * Default constructor
     *
     * @param toastService toast service
     * @param batteryService service for battery matters
     * @param measurementService service for measurement matters
     * @param exportDataManager export data manager
     * @param activatedRoute ativated router service
     */
    constructor(protected toastService: ToastrService, private batteryService: BatteryService, private measurementService: MeasurementService, private exportDataManager: ExportDataManager, private activatedRoute: ActivatedRoute) {
        // call super
        super(toastService);
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // initilize batter before loading it in order to prevent errors at UI
        this.battery = new Battery();
        this.battery.unit = new Unit();
        this.battery.id = this.activatedRoute.snapshot.params['id'];

        // setup pageable object
        this.pageable.sortField = 'date';
        this.pageable.sortOrder = 'DESC';
        this.pageable.page = 1;
        this.pageable.size = 10;

        // load battery information
        this.loadBattery();
    }

    /**
     * Method reponsible for handling with window resize
     *
     * @param event resize event
     */
    onResize(event: any) {
        if (event.target.innerWidth < this.minWidthForExpandedMenu) {
            this.expandedMenu = false;
        }
    }

    /**
     * Method responsible for changing table page
     */
    changeTablePage() {
        // cut off data due to pagination
        this.results.content = this.measurements.slice((this.pageable.page - 1) * this.pageable.size, this.pageable.page * this.pageable.size);

        // recalculate total pages
        this.results.totalPages = Math.round(this.measurements.length / this.pageable.size);
    }

    /**
     * Method responsible for indicating if the data visualization mode is graphic
     *
     * @returns flag indicating if the data visualization mode is graphic
     */
    isGraphicDataVisualizationMode(): boolean {
        return this.dataVisualizationMode === 'GRAPHIC';
    }

    /**
     * Method responsible for indicating if the graphic mode is ready to be shown
     *
     * @returns flag indicating if the graphic mode is ready
     */
    isGraphicDataVisualizationModePrepared(): boolean {
        return this.preparedForGraphicMode;
    }

    /**
     * Method responsible for searching new information based on start and end date of the filter when period selection changes
     *
     * @param customDateSelectionEvent provided only when a custom date is selected by the component
     */
    search(customDateSelectionEvent: any) {
        // set end date as today
        this.filter.endDate = moment().toDate();
        // set to the first page
        this.pageable.page = 1;

        // set initial date value
        switch (this.filter.period) {
            case 'WEEK':
                this.filter.startDate = moment().subtract(1, 'weeks').toDate();
                break;
            case 'MONTH':
                this.filter.startDate = moment().subtract(1, 'months').toDate();
                break;
            case 'YEAR':
                this.filter.startDate = moment().subtract(1, 'years').toDate();
                break;
            case 'CUSTOM':
                if (!isNullOrUndefined(customDateSelectionEvent)) {
                    this.filter.startDate = moment(customDateSelectionEvent[0]).set({ hour: 0, minute: 0, second: 0 }).toDate();
                    this.filter.endDate = moment(customDateSelectionEvent[1]).set({ hour: 23, minute: 59, second: 59 }).toDate();
                }
                break;
            default:
                // this filter applies to DAILY mode too
                this.filter.startDate = moment().subtract(1, 'days').toDate();
        }

        // redo the search except when user press CUSTOM information without informing a new period
        if (this.filter.period !== 'CUSTOM' || !isNullOrUndefined(customDateSelectionEvent)) {
            // load measurements
            this.loadMeasurements();
        }
    }

    /**
     * Method responsible for defining if the map link will be displayed to user
     *
     * @returns flag indicating if the map link will be displayed to user
     */
    shouldDisplayMapLink(): boolean {
        return !isNullOrUndefined(this.mapMarkerArray) && !isNullOrUndefined(this.mapCenterLatitude) && !isNullOrUndefined(this.mapCenterLongitude);
    }

    /**
     * Method responsible for checking if the custom period filter should be displayed
     *
     * @returns flag indicating if the custom period filter should be displayed
     */
    shouldDisplayCustomPeriodFilter(): boolean {
        return this.filter.period === 'CUSTOM';
    }

    /**
     * Method responsible for activating (or desactiving) a measurement item that was clicked
     *
     * @param measurementId measurement identifier to be changed
     */
    changeMeasurementActiveState(measurementId: string) {
        // get measurement structure
        const measurementStructure = this.currentMeasurements.filter((measurement) => measurement.id === measurementId)[0];

        // change its state
        measurementStructure.isActive = !measurementStructure.isActive;

        // if the measurement structure was activated in graphic mode, scroll to it
        if (this.isGraphicDataVisualizationMode() && measurementStructure.isActive) {
            this.scrollToElement(measurementId);
        }
    }

    /**
     * Method responsible for changing the menu state (expanded and contracted)
     */
    toggleMenu() {
        this.expandedMenu = !this.expandedMenu;
    }

    /**
     * Method responsible for respond if side menu is expanded or not
     */
    isMenuExpanded() {
        return this.expandedMenu;
    }

    /**
     * Method responsible for responding if a mesasurement is active
     *
     * @param id measurement's id
     */
    isMeasurementActive(id: string) {
        // filter measurement by id
        const measurement = this.currentMeasurements.filter((item) => item.id === id)[0];

        // check if its defined and active
        return isNullOrUndefined(measurement) ? false : measurement.isActive;
    }

    /**
     * Method reponsible for responding if electrolyte data must be shown
     */
    shouldShowElectrolyteInformation() {
        return this.battery.type === BatteryType.TRACTIONARY;
    }

    /**
     * Method responsible for scrolling the view until center a desired element
     *
     * @param id element's id
     */
    private scrollToElement(id: string) {
        // set a 100ms timer, that tries to perform scroll until find success
        const timer = setInterval(() => {
            // get element by its id
            const el: HTMLElement = document.getElementById(id);

            // if it's not null or undefined, the element was found
            if (!isNullOrUndefined(el)) {
                // stop timer
                clearInterval(timer);

                // perform the scroll
                el.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }, 100);

        // set a timeout of 1s, to stop the time interval if it have not success
        setTimeout(() => {
            // stop timer
            clearInterval(timer);
        }, 1000);
    }

    /*
     * Exports the current data content to an Excel file.
     */
    exportToExcel() {
        this.exportDataManager.exportAsExcelFile(this.results.content.map(this.buildExportableMeasurement.bind(this)), `${this.battery.operationsIdentification} - Detalhes`);
    }

    /**
     * Exports the current data content to a PDF file.
     */
    exportToPDF() {
        // TODO
    }

    /**
     * Takes a measurement object and returns its representation in an Excel row, which has more presentable column names and formatted values.
     *
     * @param measurement the measurement to be converted.
     * @returns an object that represents an exportable measurement
     */
    private buildExportableMeasurement(measurement: Measurement): { [key: string]: any } {
        // instantiate pipes to transform information
        const measurePipe: MeasurePipe = new MeasurePipe();

        let exportableMeasurement: any = {
            'Data': moment(measurement.measurementDate).format('DD/MM/YYYY HH:mm:ss')
        };

        if (this.isMeasurementActive('charge-state')) {
            exportableMeasurement = { ...exportableMeasurement, 'Estado de carga': measurePipe.transform(measurement.stateOfCharge, '%') };
        }

        if (this.isMeasurementActive('electrolyte-level')) {
            exportableMeasurement = { ...exportableMeasurement, 'Nível de eletrólito': measurement.electrolyteLevel };
        }

        if (this.isMeasurementActive('tension')) {
            exportableMeasurement = { ...exportableMeasurement, 'Tensão': measurement.tension };
        }

        if (this.isMeasurementActive('temperature')) {
            exportableMeasurement = { ...exportableMeasurement, 'Temperatura': measurement.temperature };
        }

        if (this.isMeasurementActive('current')) {
            exportableMeasurement = { ...exportableMeasurement, 'Corrente': measurement.electricCurrent };
        }

        return exportableMeasurement;
    }

    /**
     * Method responsible for getting battery details
     */
    private loadBattery() {
        // get all batteries
        this.batteryService.getEntityById(this.battery.id).subscribe(
            (battery) => {
                // associated battery information
                this.battery = battery;

                // set map's data
                if (!isNullOrUndefined(battery.device)) {
                    this.mapCenterLatitude = battery.device.latitude;
                    this.mapCenterLongitude = battery.device.longitude;
                    this.mapMarkerArray = [{ latitude: battery.device.latitude, longitude: battery.device.longitude }];
                }

                // generate measurement information
                this.generateMeasurementStructure();

                // perform first search
                this.search(null);
            },
            responseError => this.handleErrors(responseError)
        );
    }

    /**
     * Method responsible for loading measurements for this UI
     */
    private loadMeasurements() {
        // get all measurements
        this.measurementService.getMeasurementsByBatteryInAPeriod(this.battery.id, this.filter.startDate, this.filter.endDate).subscribe(
            (measurements) => {
                // assign measurements
                this.measurements = measurements;

                // prepare measurements for graphic mode
                this.prepareMeasurementsForGraphicMode();

                // prepare measurements for text mode
                this.prepareMeasurementsForTableMode();
            },
            responseError => this.handleErrors(responseError)
        );
    }

    /**
     * Method responsible for generating measurement information to be displayed
     */
    private generateMeasurementStructure() {
        // instantiate pipes to transform information
        const measurePipe: MeasurePipe = new MeasurePipe();

        // clear measurement information
        this.currentMeasurements = [];

        // add charge state item
        this.currentMeasurements.push({
            id: 'charge-state',
            text: 'Estado de carga',
            isActive: true,
            selectedIcon: 'icon-charge-state-selected',
            notSelectedIcon: 'icon-charge-state',
            leftValue: measurePipe.transform(this.battery.stateOfCharge, '%'),
            chartData: []
        });

        if (this.shouldShowElectrolyteInformation()) {
            // add electrolyte level item
            this.currentMeasurements.push({
                id: 'electrolyte-level',
                text: 'Nível do eletrólito',
                isActive: true,
                selectedIcon: 'icon-electrolyte-selected',
                notSelectedIcon: 'icon-electrolyte',
                leftValue: this.battery.currentAlerts && this.battery.currentAlerts.indexOf(AlertTypeCode.LOW_ELECTROLYTE_LEVEL) !== -1 ? 'Baixo' : 'Normal',
                chartData: []
            });
        }

        // add voltage item
        this.currentMeasurements.push({
            id: 'tension',
            text: 'Tensão',
            isActive: true,
            selectedIcon: 'icon-voltage-selected',
            notSelectedIcon: 'icon-voltage',
            leftValue: measurePipe.transform(this.battery.tension, 'V'),
            chartData: []
        });

        // add temperature item
        this.currentMeasurements.push({
            id: 'temperature',
            text: 'Temperatura',
            isActive: false,
            selectedIcon: 'icon-temperature-selected',
            notSelectedIcon: 'icon-temperature',
            leftValue: measurePipe.transform(this.battery.temperature, 'ºC'),
            chartData: []
        });

        // add eletric current item
        this.currentMeasurements.push({
            id: 'current',
            text: 'Corrente',
            isActive: true,
            selectedIcon: 'icon-current-selected',
            notSelectedIcon: 'icon-current',
            leftValue: measurePipe.transform(this.battery.electricCurrent, 'A'),
            rightValue: 'Máx: ' + measurePipe.transform(this.battery.electricCurrentPeak, 'A'),
            chartData: []
        });
    }

    /**
    * Method responsible for preparing measurements data for table mode
    */
    private prepareMeasurementsForTableMode() {
        // instantiate results
        this.results = new Page<Measurement>();
        this.results.content = [];

        // fullfill totals
        this.results.totalPages = Math.round(this.measurements.length / this.pageable.size);
        this.results.totalElements = this.measurements.length;

        // cut off data due to pagination
        this.results.content = this.measurements.reverse().slice((this.pageable.page - 1) * this.pageable.size, this.pageable.page * this.pageable.size);
    }

    /**
    * Method responsible for preparing measurements data for graphic mode mode
    */
    private prepareMeasurementsForGraphicMode() {
        // information to be added to graphics
        const dateLabels: Date[] = [];
        const electrolyteData: any[] = [];
        const stateOfChargeData: any[] = [];
        const tensionData: any[] = [];
        const temperatureData: any[] = [];
        const eletricCurrentData: any[] = [];

        // iterate over all measurements to extract information for graphics
        this.measurements.forEach((measurement, index) => {
            // add date to label
            dateLabels.push(measurement.measurementDate);

            // push all information needed
            electrolyteData.push(isNullOrUndefined(measurement.electrolyteLevel) ? electrolyteData[index - 1] : measurement.electrolyteLevel);
            stateOfChargeData.push(isNullOrUndefined(measurement.stateOfCharge) ? stateOfChargeData[index - 1] : measurement.stateOfCharge);
            tensionData.push(isNullOrUndefined(measurement.tension) ? tensionData[index - 1] : measurement.tension);
            temperatureData.push(isNullOrUndefined(measurement.temperature) ? temperatureData[index - 1] : measurement.temperature);
            eletricCurrentData.push(isNullOrUndefined(measurement.electricCurrent) ? electrolyteData[index - 1] : measurement.electricCurrent);
        });

        // each measurement type should procuce a different type of structure
        this.currentMeasurements.forEach(currementMeasurement => {
            switch (currementMeasurement.id) {
                // state of charge chart
                case ('charge-state'):
                    currementMeasurement.chartData = {
                        yScaleLabel: 'Porcentagem (%)',
                        labels: dateLabels,
                        datasets: [
                            {
                                label: currementMeasurement.text + ' (%)',
                                data: stateOfChargeData,
                            }
                        ]
                    };
                    break;
                // electrolyte chart
                case 'electrolyte-level':
                    currementMeasurement.chartData = {
                        yScaleLabel: '0 = Baixo e 1 = Normal',
                        labels: dateLabels,
                        datasets: [
                            { label: 'Nível', data: electrolyteData, steppedLine: true }
                        ]
                    };
                    break;
                // tension chart
                case 'tension':
                    currementMeasurement.chartData = {
                        yScaleLabel: 'Tensão (V)',
                        beginAtZero: false,
                        labels: dateLabels,
                        datasets: [
                            {
                                label: currementMeasurement.text + ' (V)',
                                data: tensionData
                            }
                        ]
                    };
                    break;
                // temperature chart
                case 'temperature':
                    currementMeasurement.chartData = {
                        yScaleLabel: 'Temperatura (ºC)',
                        beginAtZero: false,
                        labels: dateLabels,
                        datasets: [
                            {
                                label: currementMeasurement.text + ' (ºC)',
                                data: temperatureData
                            }
                        ]
                    };
                    break;
                // current chart
                case 'current':
                    currementMeasurement.chartData = {
                        yScaleLabel: 'Corrente(A)',
                        labels: dateLabels,
                        datasets: [
                            {
                                label: currementMeasurement.text + ' (A)',
                                data: eletricCurrentData
                            }
                        ]
                    };
                    break;
                default:
                    this.showErrorMessage('Alguns dados não puderam ser carregados');
            }
        });

        // set flag
        this.preparedForGraphicMode = true;
    }

    /**
     * Method responsible to find the average time modes from measruments
     *
     * @param measurements the array of measurements
     * @returns the average time modes as an array
     */
    private findAverageTimeModesFromMeasurements(measurements: Array<Measurement>): Array<number> {
        // initialize the variables
        const numMapping: any = {};
        let greatestFreq = 0;
        const mode: Array<number> = [];

        // iterate over all measurements to calculate the mode
        measurements.forEach((measurement, index) => {
            // verify if has a next measurement
            if (measurements[index + 1]) {
                // get the duration time in minutes
                const intervalDuration = Math.round(moment.duration(moment(this.measurements[index + 1].measurementDate).diff(moment(measurement.measurementDate))).asMinutes());

                // map the values and their frequency
                numMapping[intervalDuration] = (numMapping[intervalDuration] || 0) + 1;
                // set the current mode
                if (greatestFreq < numMapping[intervalDuration]) {
                    greatestFreq = numMapping[intervalDuration];
                }
            }
        });

        // get only the modes and return it
        for (const intervalDuration in numMapping) {
            if (numMapping.hasOwnProperty(intervalDuration) && numMapping[intervalDuration] > 1) {
                mode.push(Number(intervalDuration));
            }
        }

        return mode;
    }

    /**
     * Method responsible to verify if there is an active measurement. If true, display the table.
     */
    shouldDisplayTableInformation() {
        return this.currentMeasurements.map(measurement => measurement.isActive).indexOf(true) !== -1;
    }
}
