import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';
import { GenericComponent } from '../../shared/generic-component.component';
import { SecurityManager } from '../../shared/mechanism/security-manager.service';
import { AlertType } from '../../shared/model/alert-type.model';
import { Alert } from '../../shared/model/alert.model';
import { BatteryType } from '../../shared/model/enum/battery-type.enum';
import { UserProfile } from '../../shared/model/enum/user-profile.enum';
import { DoughnutData } from '../../shared/model/interface/doughnut-data';
import { IdLabeledRow } from '../../shared/model/interface/id-labeled-row.interface';
import { TractionaryOperatorHomeReport } from '../../shared/model/report/tractionary-operator-home-report.model';
import { BatteryStatusPipe } from '../../shared/pipe/battery-status.pipe';
import { AlertTypeService } from '../alert/alert-type.service';
import { ReportService } from '../report/report.service';

/**
 * Home component controller class for tractionary batteries
 */
@Component({ selector: 'app-tractionary-operator-home', templateUrl: './tractionary-operator-home.component.html', styleUrls: ['./tractionary-operator-home.component.scss'] })
export class TractionaryOperatorHomeComponent extends GenericComponent implements OnInit, OnChanges {
    /**
     * Color palette for battery status
     */
    batteryStatusColorPalette: any;

    /**
     * Data content of the Battery chart.
     */
    batteryChartData: IdLabeledRow[];

    /**
     * Number of batteries using.
     */
    numberOfBatteriesUsing: number;

    /**
     * Number of batteries stopped.
     */
    numberOfBatteriesStopped: number;

    /**
     * Number of batteries charging.
     */
    numberOfBatteriesCharging: number;

    /**
     * Number of batteries resting.
     */
    numberOfBatteriesResting: number;

    /**
     * Number of batteries ready for use.
     */
    numberOfBatteriesReady: number;

    /**
     * Number of batteries discharged.
     */
    numberOfBatteriesDischarged: number;

    /**
     * Number of batteries charged.
     */
    numberOfBatteriesCharged: number;

    /**
     * Number of unknown batteries.
     */
    numberOfBatteriesUnknown: number;

    /**
     * Data content the report of the tractionary home
     */
    tractionaryOperatorHomeReport: TractionaryOperatorHomeReport;

    /**
     * The doughtnut battery average time by status data.
     */
    batteryAverageTimeByStatusChartData: DoughnutData;

    /**
     * Hold the report open alerts.
     */
    openAlerts: Alert[];

    /**
     * Variable to hold if is small device screen
     */
    isSmallDeviceScreen: boolean;

    /**
     * hold the current customer id and customer unit id.
     */
    @Input() homeFilter: any;

    /**
     * Hold the alert types
     */
    alertTypes: AlertType[];

    /**
     * Flag if the user is internal
    */
    isInternalUser: boolean;

    /**
     * hold the indicators information.
     */
    indicators: Array<any>;

    /**
     * Variable to hold reference to battery chard modal close button
     */
    @ViewChild('batteryChartModalCloseButton') batteryChartModalCloseButtonElement: ElementRef;

    /**
     * Default constructor
     *
     * @param toastService toast service
     * @param router router service
     * @param reportService tractionary report service
     * @param securityManager security Manager service
     * @param deviceService device detector service
     */
    constructor(protected toastService: ToastrService, private batteryStatusPipe: BatteryStatusPipe, private router: Router, private reportService: ReportService, private alertTypeService: AlertTypeService, private securityManager: SecurityManager, deviceService: DeviceDetectorService) {
        // call super
        super(toastService);

        // set if is small device screen
        this.isSmallDeviceScreen = deviceService.isMobile() || deviceService.isTablet();
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // setup static chart data
        this.batteryStatusColorPalette = {
            'USING': 'rgb(55,188,155,0.8)',
            'STOPPED': 'rgb(192,84,204,0.8)',
            'CHARGING': 'rgb(228,197,46,0.8)',
            'RESTING': 'rgb(240,80,80,0.8)',
            'READY': 'rgb(93,156,236,0.8)',
            'DISCHARGED': 'rgb(255,118,57,0.8)',
            'CHARGED': 'rgb(91,170,61,0.8)',
            'UNKNOW': 'rgb(127,132,132,0.8)',
        };

        // set the internal user flag
        this.isInternalUser = this.securityManager.getLoggedUser().internal;

        // load the apert types
        this.loadAlertTypes();

        // initialize the report
        this.clearReport();
    }

    /**
     * @see @angular/core/OnChanges/ngOnChanges()
     */
    ngOnChanges() {
        // load the tractionary report
        this.getTractionaryReport();
    }

    /**
     * Event handler for the click action on a row in the Battery chart. Redirects to the battery's detail page.
     *
     * @param row the row that has been clicked
     */
    batteryChartRowClicked(row: IdLabeledRow) {
        this.router.navigate(['/batteries', row.id, 'details']);
    }

    /**
     * Event handler for the click action on a row in the Battery chart and close the modal element. Redirects to the battery's detail page.
     *
     * @param row the row that has been clicked
     */
    modalBatteryChartRowClicked(row: IdLabeledRow) {
        // close modal
        this.batteryChartModalCloseButtonElement.nativeElement.click();
        // navigate
        this.router.navigate(['/batteries', row.id, 'details']);
    }

    /**
     * Method responsible to verify if user can access battery details.
     *
     * @returns flag indicating if ca access battery details
     */
    canUserGoToBatteryDetails(): boolean {
        return this.securityManager.getLoggedUser().internal && !this.isSmallDeviceScreen;
    }

    /**
     * Method responsible for verifying if should display the report data.
     */
    shouldDisplayReportInformation() {
        return this.tractionaryOperatorHomeReport;
    }

    /**
     * Method responsible for building the battery list query params object and return it
     *
     * @param status the battery status
     * @returns query params object
     */
    buildBatteriesListQueryParams(status: string): { [key: string]: string } {
        // The query param object with the selected status, battery type and selected unit id
        const queryParams: any = {
            status: status,
            type: 'TRACTIONARY'
        };

        // if is internal and head office id is set, get the selected head office id
        if (this.isInternalUser && Number(this.homeFilter.headOfficeId) !== 0) {
            queryParams.headOfficeId = this.homeFilter.headOfficeId;
        }

        // if is internal and customer id is set, get the the selected customer id
        if (this.isInternalUser && Number(this.homeFilter.customerId) !== 0) {
            queryParams.customersId = [this.homeFilter.customerId];
        }

        // if is not external or is not a OPERATOR and customer unit id is set, get the selected unit id
        if ((this.isInternalUser || this.securityManager.getLoggedUser().profile !== UserProfile.OPERATOR) && Number(this.homeFilter.customerUnitId)) {
            queryParams.unitId = this.homeFilter.customerUnitId;
        }

        return queryParams;
    }

    /**
     * Method responsible for defining if the last data update info must be displayed
     *
     * @returns flag indicating if the last data update info must be displayed
     */
    shouldShowLastUpdateInformation(): boolean {
        return !isNullOrUndefined(this.tractionaryOperatorHomeReport.updatedDate);
    }

    /**
     * build the indicators array.
     */
    private buildIndicators() {
        // initialize indicators
        this.indicators = [];

        // number Of Alerts indicator
        this.indicators.push({ icon: 'alerts', title: 'Alertas', value: this.tractionaryOperatorHomeReport.numberOfAlerts });

        // number Of Battery Replacements indicator
        this.indicators.push({ icon: 'batteries-changed', title: 'Trocas', value: this.tractionaryOperatorHomeReport.numberOfBatteryReplacements });

        // battery idleness index indicator
        this.indicators.push({ icon: 'idleness-index', title: 'Índice de ociosidade', value: `${this.tractionaryOperatorHomeReport.batteryIdlenessIndex}%` });

        // number Of Deep Discharge Alerts indicator
        this.indicators.push({ icon: 'deep-discharge', title: 'Descarga profunda', value: this.tractionaryOperatorHomeReport.numberOfDeepDischargeAlerts });
    }

    private loadAlertTypes() {
        this.alertTypeService.getAllEntities().subscribe(
            (alertTypes) => {
                // set alert types holder
                this.alertTypes = alertTypes;
            }
        );
    }

    /**
     * Loads the Batteries Status indicator card content.
     */
    private loadBatteryStatusCount() {
        // assign information
        this.numberOfBatteriesUsing = this.tractionaryOperatorHomeReport.batteryCountGroupedByStatus['USING'];
        this.numberOfBatteriesStopped = this.tractionaryOperatorHomeReport.batteryCountGroupedByStatus['STOPPED'];
        this.numberOfBatteriesCharging = this.tractionaryOperatorHomeReport.batteryCountGroupedByStatus['CHARGING'];
        this.numberOfBatteriesResting = this.tractionaryOperatorHomeReport.batteryCountGroupedByStatus['RESTING'];
        this.numberOfBatteriesDischarged = this.tractionaryOperatorHomeReport.batteryCountGroupedByStatus['DISCHARGED'];
        this.numberOfBatteriesCharged = this.tractionaryOperatorHomeReport.batteryCountGroupedByStatus['CHARGED'];
        this.numberOfBatteriesReady = this.tractionaryOperatorHomeReport.batteryCountGroupedByStatus['READY'];
        this.numberOfBatteriesUnknown = this.tractionaryOperatorHomeReport.batteryCountGroupedByStatus['UNKNOW'];
    }

    /**
     * Method responsible for loading the Battery chart content
     */
    private loadBatteryStatusTimeSummaryForTheLast24Hours(): void {
        // reset chart data
        this.batteryChartData = [];

        // set the batteryStatusSummaryForTheLastDay
        const batteryStatusSummaryForTheLastDay = this.tractionaryOperatorHomeReport.batteryStatusSummaryForTheLastDay;

        // iterate in summary items to create chart data structure
        for (const batteryStatusSummary in batteryStatusSummaryForTheLastDay) {
            if (batteryStatusSummaryForTheLastDay.hasOwnProperty(batteryStatusSummary)) {
                this.batteryChartData.push(this.tranformStatusSummaryToChartData(batteryStatusSummary, this.groupAndFilterBatteryStatus(batteryStatusSummaryForTheLastDay[batteryStatusSummary])));
            }
        }
    }

    /**
     * Method responsible to group the status and filter status greater than a half hour
     *
     * @param batteryStatusSummary battery's status summary key (composed by battery id | battery unique key)
     * @returns the new battery status summary
     */
    private groupAndFilterBatteryStatus(batteryStatusSummary: any) {
        // the new battery status summary
        const groupedBatteryStatusSummary: Array<any> = [];
        for (const summaryItem in batteryStatusSummary) {
            // check if key is present in summary
            if (batteryStatusSummary.hasOwnProperty(summaryItem)) {
                // if the current status is different from the last status, push to the array
                if (!groupedBatteryStatusSummary.length || groupedBatteryStatusSummary[groupedBatteryStatusSummary.length - 1]['status'] !== batteryStatusSummary[summaryItem]['status']) {
                    groupedBatteryStatusSummary.push(batteryStatusSummary[summaryItem]);
                }
                // if the current status is equal from the last status, sum the time
                else {
                    groupedBatteryStatusSummary[groupedBatteryStatusSummary.length - 1]['time'] += batteryStatusSummary[summaryItem]['time'];
                }
            }
        }

        return groupedBatteryStatusSummary;
    }

    /**
     * Method responsible for converting the battery status summary to structured data to feed the Battery Chart
     *
     * @param batteryStatusSummaryKey battery's status summary key (composed by battery id | battery unique key)
     * @param batteryStatusSummary summary about status changes from the desired battery
     */
    private tranformStatusSummaryToChartData(batteryStatusSummaryKey: string, batteryStatusSummary: any): IdLabeledRow {
        // init column array
        const columns = [];

        // extract data from battery status summary key
        const batteryId: number = parseInt(batteryStatusSummaryKey.split('|')[0], 10);
        const batteryUniqueIdentifier: string = batteryStatusSummaryKey.split('|')[1];
        for (const summaryItem in batteryStatusSummary) {
            // check if key is present in summary
            if (batteryStatusSummary.hasOwnProperty(summaryItem)) {
                // add column to array, with time in rounded hours (2 decimal places)
                columns.push(
                    {
                        label: this.batteryStatusPipe.transform(batteryStatusSummary[summaryItem]['status'], BatteryType.TRACTIONARY),
                        value: Math.round((batteryStatusSummary[summaryItem]['time'] * 1000) / (1000 * 60 * 60)) / 1000,
                        backgroundColor: this.batteryStatusColorPalette[batteryStatusSummary[summaryItem]['status']]
                    }
                );
            }
        }

        // return a new chart row
        return {
            id: batteryId,
            label: batteryUniqueIdentifier,
            columns: columns.length !== 0 ? columns : [{ label: '', value: 0 }]
        };
    }

    /**
     * Method responsible to load the report of the tractionary home.
     */
    private getTractionaryReport() {
        let tractionaryHomeReportObservable = null;

        if (this.homeFilter.unitBatteryRoomId > 0) {
            // set the request to get the tractionary manager report by unit battery room id
            tractionaryHomeReportObservable = this.reportService.getOperatorTractionaryHomeReportByBatteryRoom(this.homeFilter.unitBatteryRoomId);
        }
        else if (this.homeFilter.customerUnitId > 0) {
            // set the resquest to get the tractionary report by customer unit id
            tractionaryHomeReportObservable = this.reportService.getOperatorTractionaryHomeReportByUnit(this.homeFilter.customerUnitId);
        } else if (this.homeFilter.customerId > 0) {
            // set the resquest to get the tractionary report by customer id
            tractionaryHomeReportObservable = this.reportService.getOperatorTractionaryHomeReportByCustomer(this.homeFilter.customerId);
        } else {
            this.clearReport();
        }

        if (tractionaryHomeReportObservable) {
            // perform request and treat response
            tractionaryHomeReportObservable.subscribe(
                (response) => {
                    // get the response data
                    this.tractionaryOperatorHomeReport = response;

                    this.openAlerts = this.tractionaryOperatorHomeReport.openAlerts;

                    // load battery status count
                    this.loadBatteryStatusCount();

                    // load battery status time summary for last 24 hours
                    this.loadBatteryStatusTimeSummaryForTheLast24Hours();

                    // create the array of indicators
                    this.buildIndicators();

                    // create the battery average time by status chart data
                    this.buildBatteryAverageTimeByStatusChartData();
                },
                (responseError) => {
                    // show error messages
                    this.handleErrors(responseError);
                }
            );
        }
    }

    /**
     * Method responsible to clear the variables.
     */
    private clearReport() {
        this.openAlerts = [];
        this.batteryChartData = [];
        this.batteryAverageTimeByStatusChartData = null;
        this.tractionaryOperatorHomeReport = new TractionaryOperatorHomeReport();
        this.numberOfBatteriesUsing = 0;
        this.numberOfBatteriesStopped = 0;
        this.numberOfBatteriesCharging = 0;
        this.numberOfBatteriesResting = 0;
        this.numberOfBatteriesDischarged = 0;
        this.numberOfBatteriesCharged = 0;
        this.numberOfBatteriesReady = 0;
        this.numberOfBatteriesUnknown = 0;
        this.indicators = [];
    }

    /**
     * Method responsible to create the chart object for the average time by status.
     */
    private buildBatteryAverageTimeByStatusChartData() {
        const labels = [];
        const data = [];
        const backgroundColor = [];
        const batteryAverageTimeGroupedByStatus = this.tractionaryOperatorHomeReport.batteryAverageTimeGroupedByStatus;

        for (const status in batteryAverageTimeGroupedByStatus) {
            if (batteryAverageTimeGroupedByStatus.hasOwnProperty(status)) {
                labels.push(this.batteryStatusPipe.transform(status, BatteryType.TRACTIONARY));
                data.push(Math.round(batteryAverageTimeGroupedByStatus[status] / (1000 * 60 * 60)));
                backgroundColor.push(this.batteryStatusColorPalette[status]);
            }
        }

        this.batteryAverageTimeByStatusChartData = {
            labels: labels,
            datasets: [
                {
                    data: data,
                    backgroundColor: backgroundColor
                }
            ],
            showMiddleText: false
        };
    }
}
