import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';
import { GenericComponent } from './generic-component.component';
import { GenericService } from './generic-service.service';
import { Page } from './model/page.model';
import { Pageable } from './model/pageable.model';
import { GenericSearchParameters } from './model/search/generic-search-parameters.model';

/**
 * Generic component which provides pageable search capabilities
 */
export class GenericPageableSearchComponent<T> extends GenericComponent implements OnInit {
    /**
     * variable at model holding search results
     */
    results: Page<T>;

    /**
     * variable at model holding pageable options
     */
    pageable: Pageable;

    /**
     * variable at model holding all filters
     */
    filters: GenericSearchParameters;

    /**
     * Default constructor for generic search application component
     *
     * @param toastService toast service
     * @param service service associated to this component
     * @param router router service
     * @param routeElement route element used to redirect user when necessary (Ex: users, businessunits, etc)
     */
    constructor(protected toastService: ToastrService, protected service: GenericService<T>, protected router: Router, protected routeElement: string) {
        // call super
        super(toastService);
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // initialize results with an empty set
        this.results = new Page<T>();

        // initiliaze pageable with default values
        this.pageable = new Pageable();
        this.pageable.size = 10;
        this.pageable.page = 1;
        this.pageable.sortField = 'id';
        this.pageable.sortOrder = 'ASC';
    }

    /**
     * Method responsible for searching entities
     */
    search() {
        // call search
        this.service.searchForEntities(this.pageable, this.filters).subscribe(
            (data) => {
                // assign information
                this.results = data;

                // check if there's anything to be shown
                if (isNullOrUndefined(this.results) || isNullOrUndefined(this.results.content) || this.results.content.length <= 0) {
                    // show warning message saying no results were found
                    this.showWarningMessage('Nenhum resultado encontrado');
                }
            },
            (responseError) => {
                // Clean last successful search
                this.results = new Page<T>();

                // Show error messages
                this.handleErrors(responseError);
            }
        );
    }

    /**
     * Method responsible for sorting entities
     *
     * @param sortField sort field to be used to sort entities
     */
    sort(sortField: string) {
        // perform sort logic before searching information
        if (sortField === this.pageable.sortField) {
            this.pageable.sortOrder = this.pageable.sortOrder === 'ASC' ? 'DESC' : 'ASC';
        } else {
            this.pageable.sortOrder = 'ASC';
        }

        // assign new sort field
        this.pageable.sortField = sortField;

        // perform search again
        this.search();
    }

    /**
     * Method responsible for deleting an entity
     *
     * @param entityId entity identifier
     */
    delete(entityId: number) {
        this.service.deleteEntity(entityId).subscribe(
            (responseSuccess) => {
                // show a success message
                this.showSuccessMessage(responseSuccess['message']);

                // redo the search
                this.search();
            },
            (responseError) => {
                this.handleErrors(responseError);
            }
        );
    }

    /**
     * Method responsible for redireting user to the creation page relative to the chosen entity
     */
    goToCreation() {
        this.router.navigate([`/${this.routeElement}/new`]);
    }

    /**
     * Method responsible for redireting user to the edition page relative to the chosen entity
     *
     * @param entityId entity's identifier
     */
    goToEdition(entityId: number) {
        this.router.navigate([`/${this.routeElement}/${entityId}/edit`]);
    }
}
