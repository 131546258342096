import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { GenericMaintainEntityComponent } from '../../shared/generic-maintain-entity-component';
import { Battery } from '../../shared/model/battery.model';
import { Customer } from '../../shared/model/customer.model';
import { Model } from '../../shared/model/model.model';
import { Unit } from '../../shared/model/unit.model';
import { ModelService } from '../model/model.service';
import { BatteryService } from './battery.service';

/**
 * Maintain battery component controller class
 */
@Component({ selector: 'app-maintain-battery', templateUrl: './maintain-battery.component.html', })
export class MaintainBatteryComponent extends GenericMaintainEntityComponent<Battery> implements OnInit {

    /**
     * the list of models
     */
    models: Array<Model>;

    /**
     * default constructor
     *
     * @param toastService toast service
     * @param batteryService service for battery matters
     * @param router router service
     * @param activatedRoute activated route service
     * @param modelService service for model matters
     */
    constructor(protected toastService: ToastrService, protected batteryService: BatteryService, protected router: Router, protected activatedRoute: ActivatedRoute, private modelService: ModelService) {
        // call super
        super(toastService, batteryService, router, activatedRoute, 'batteries');

        // initialize model
        this.model = new Battery();
        this.model.customer = new Customer();
        this.model.unit = new Unit();
        this.model.model = new Model();
    }

    /**
     * @see @angular/core/OnInit/ngOninit()
     */
    ngOnInit() {
        // call super
        super.ngOnInit();

        // as soon as we have the model loaded (edition mode), load all models
        this.modelLoaded.subscribe(() => {
            this.modelService.getAllEntities().subscribe((models: Array<Model>) => this.models = models.filter((model: Model) => model.type === this.model.type));
        });

        // define page title
        this.pageTitle = 'Editar Bateria';
    }

    /**
     * Method responsible to update the model manufacturer input value
     */
    public updateModelManufacturer() {
        this.model.model.manufacturer = this.models.find((model: Model) => model.id === Number(this.model.modelId)).manufacturer;
    }

    /**
     * Method responsible for updating battery model
     */
    public updateBatteryModel(): void {
        // disable the submit button
        this.isSubmitDisabled = true;

        // update entity
        this.batteryService.updateBatteryModel(this.model.id, this.model.modelId).subscribe(
            (responseSuccess) => {
                // show a success message
                this.showSuccessMessage(responseSuccess['message']);

                // back to search page
                this.goToSearch();
            },
            (responseError) => {
                this.handleErrors(responseError);

                // enable the submit button
                this.isSubmitDisabled = false;
            }
        );
    }
}
