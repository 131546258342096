import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';
import { GenericComponent } from '../../shared/generic-component.component';
import { SecurityManager } from '../../shared/mechanism/security-manager.service';

/**
 * Login component controller class
 */
@Component({ selector: 'app-login', templateUrl: './login.component.html', styleUrls: ['./login.component.scss'] })
export class LoginComponent extends GenericComponent implements OnInit {
    /**
     * variable at model holding email
     */
    email: string;

    /**
     * variable at model holding password
     */
    password: string;

    /**
     * variable at model holding password confirmation
     */
    passwordConfirmation: string;

    /**
     * User session ID
     */
    sessionId: string;

    /**
     * User confirmation code
     */
    confirmationCode: number;

    /**
     * Variable responsible for indicating if a password change is happening
     */
    userIsChangingPassword: boolean;

    /**
     * Variable responsible for indicating if user selected Forgot Password option or has status of Reset Password Required
     */
    userNeedsConfirmationCode: boolean;

    /**
     * Variable responsible for indicating if should make external authentication
     */
    shouldMakeExternalAuthentication: boolean;

    /**
     * Default constructor for login component
     * @param toastService toast service
     * @param securityManager mechanism to deal with security matters
     * @param router router service
     */
    constructor(protected toastService: ToastrService, private securityManager: SecurityManager, private router: Router) {
        // call super
        super(toastService);
    }

    /**
     * @see @angular/core/OnInit#OnInit()
     */
    ngOnInit() {
        // check if some user is logged in
        if (this.securityManager.isSessionValid()) {
            // navigate to home if user is already logged in
            this.router.navigate(['/home']);
        }

        // set password variables
        this.userIsChangingPassword = false;
        this.userNeedsConfirmationCode = false;

        // set login with moura AD variable
        this.shouldMakeExternalAuthentication = false;
    }

    /**
     * Method responsible for perfoming login
     */
    login() {
        // call security manager
        this.securityManager.login(this.email, this.password, this.shouldMakeExternalAuthentication).subscribe(
            () => {
                // navigate to home if user is already logged in
                this.router.navigate(['/home']);
            },
            responseError => {
                // clean password
                this.password = undefined;

                // check if session ID is valid
                if (!isNullOrUndefined(responseError) && !isNullOrUndefined(responseError.error.sessionId)) {
                    // indicate that password reset is required
                    this.userIsChangingPassword = true;

                    // set session ID
                    this.sessionId = responseError.error.sessionId;
                }
                else if (!isNullOrUndefined(responseError) && responseError.error.reason === 'PASSWORD_RESET_REQUIRED') {
                    // show message
                    this.showInfoMessage(responseError.error.message);

                    // set password variables
                    this.userIsChangingPassword = false;
                    this.userNeedsConfirmationCode = true;
                }
                else {
                    // show error message
                    this.handleErrors(responseError);
                }
            }
        );
    }

    /**
     * Method responsible for perfoming password change, using Session ID as validation
     */
    changePasswordBySessionId() {
        // call security manager
        this.securityManager.changePassword(this.email, this.password, this.passwordConfirmation, this.sessionId).subscribe(
            () => {
                // perform after change call
                this.showPasswordChangeSuccessBehavior();
            },
            responseError => {
                // show error message
                this.handleErrors(responseError);
            }
        );
    }

    /**
     * Method responsible for perfoming password change, using Confirmation Code as validation
     */
    changePasswordByConfirmationCode() {
        // call security manager
        this.securityManager.changePassword(this.email, this.password, this.passwordConfirmation, this.confirmationCode, false).subscribe(
            () => {
                // perform after change call
                this.showPasswordChangeSuccessBehavior();
            },
            responseError => {
                // show error message
                this.handleErrors(responseError);
            }
        );
    }

    /**
     * Method responsible for resetting user's password
     */
    resetPassword() {
        // call security manager
        this.securityManager.resetPassword(this.email).subscribe(
            () => {
                // show success message
                this.showSuccessMessage('Código enviado');

                // clean password
                this.password = undefined;

                // set password variables
                this.userIsChangingPassword = true;
                this.userNeedsConfirmationCode = true;
            },
            responseError => {
                // show error message
                this.handleErrors(responseError);
            }
        );
    }

    /**
     * Indicates when menu should be shown
     */
    shouldShowLogin() {
        return !this.userIsChangingPassword && !this.userNeedsConfirmationCode;
    }

    /**
     * Indicates when must ask by user's e-mail to send a code
     * */
    shouldAskForUserEmail() {
        return this.userIsChangingPassword && !this.userNeedsConfirmationCode;
    }

    /**
     * Indicates when menu should show form to change password by code
     */
    isUserChangingPasswordByCode() {
        return !this.userIsChangingPassword && this.userNeedsConfirmationCode;
    }

    /**
     * Indicates when menu should show form to change password by Session ID
     */
    isUserChangingPasswordBySession() {
        return this.userIsChangingPassword && this.userNeedsConfirmationCode;
    }

    /**
     * Method responsible for returning to login initial state
     */
    cleanStateVariables() {
        // clean input variables
        this.email = undefined;
        this.password = undefined;
        this.confirmationCode = undefined;

        // set password variables
        this.userIsChangingPassword = false;
        this.userNeedsConfirmationCode = false;
    }

    /**
     * Method responsible for showing success message and returning to login after password change operations
     */
    private showPasswordChangeSuccessBehavior() {
        // show success message
        this.showSuccessMessage('Senha definada com sucesso');

        // call login method
        this.login();
    }
}
