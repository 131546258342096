import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { UserProfile } from '../model/enum/user-profile.enum';
import { User } from '../model/user.model';
import { SecurityManager } from './security-manager.service';

/**
 * Guard class responsible for redirecting user when its profile does not match with the requirements
 */
@Injectable()
export class ProfileGuard implements CanActivate {

    /**
     * Default constructor
     * @param router router service
     * @param securitymanager security manager
     */
    constructor(private router: Router, private securityManager: SecurityManager) { }

    /**
     *  @see @angular/router#CanActivate(ActivatedRouteSnapshot)
     */
    canActivate(activatedRoute: ActivatedRouteSnapshot): boolean {
        // get logged user in order to check restrictions
        const loggedUser: User = this.securityManager.getLoggedUser();

        // flag that indicates activation or not of the resource
        let isActivated = this.securityManager.isSessionValid();

        // check if route requires internal user
        const requiredProfiles: UserProfile[] = activatedRoute.data.requireProfiles;
        if (!isNullOrUndefined(requiredProfiles)) {
            isActivated = requiredProfiles.findIndex(profile => profile === loggedUser.profile) >= 0;
        }

        // check if route can be acessed only by internal user
        const onlyInternalUser: boolean = activatedRoute.data.onlyInternalUser;
        if (!isNullOrUndefined(onlyInternalUser)) {
            isActivated = loggedUser.internal === onlyInternalUser;
        }

        // perform necessary actions in according to the flag
        if (!isActivated) {
            // Removes any information about the user from the session
            this.securityManager.logout();

            // not logged in so redirect to login page
            this.router.navigate(['login']);
        }

        return isActivated;
    }
}
