import { Component, Input } from '@angular/core';

/**
 * Error messages component class
 */
@Component({selector: 'app-error-messages', templateUrl: './error-message.component.html'})
export class ErrorMessageComponent {
    /**
     * Variable holding #variable (ngModel) inputed to show the correct error message
     */
    @Input() entityForValidation: any;

    /**
     * Variable holding #variable (ngForm) to validate when is submitted
     */
    @Input() entityForm: any;

    /**
     * Default constructor
     */
    constructor() {}
}
