import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GenericPageableSearchComponent } from '../../shared/generic-pageable-search-component.component';
import { Model } from '../../shared/model/model.model';
import { ModelSearchParameters } from '../../shared/model/search/model-search-parameter.model';
import { ModelService } from './model.service';

/**
 * Search models component controller class
 */
@Component({ selector: 'app-search-models', templateUrl: './search-models.component.html', })
export class SearchModelsComponent extends GenericPageableSearchComponent<Model> implements OnInit {
    /**
     * Filters object. Overwrites the inherited generic filter object.
     */
    filters: ModelSearchParameters;

    /**
     * Default constructor for generic application component
     *
     * @param toastService toast service
     * @param modelService service for model matters
     * @param router router service
     */
    constructor(protected toastService: ToastrService, protected modelService: ModelService, protected router: Router) {
        // call super
        super(toastService, modelService, router, 'models');
    }

    /**
    * @see @angular/core/OnInit/ngOnInit()
    */
    ngOnInit() {
        // call super
        super.ngOnInit();

        // instantiate filters
        this.filters = new ModelSearchParameters();

        // setup pageable object
        this.pageable.sortField = 'description';

        // perform initial search
        this.search();
    }

    /**
     * Method responsible for cleaning the search parameters
     */
    cleanFilters() {
        // clear the filter with the default value
        this.filters.description = '';
        this.filters.manufacturer = '';
        this.filters.type = '';
    }
}
