import { EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GenericComponent } from './generic-component.component';
import { GenericService } from './generic-service.service';

/**
 * Generic component class
 */
export class GenericMaintainEntityComponent<T> extends GenericComponent implements OnInit {
    /**
     * Model loaded event
     */
    @Output() modelLoaded = new EventEmitter();

    /**
     * variable holding data about model
     */
    model: T;

    /**
     * variable holding entity's identifier
     */
    entityId: number;

    /**
     * variable holding information about what kind of operation is being performed
     */
    isEdition: boolean;

    /**
     * variable at model holding page title (Maintain or New)
     */
    pageTitle: string;

    /**
     * variable responsible to disable the submit button to avoid multiple requests
     */
    isSubmitDisabled: boolean = false;

    /**
     * Default constructor for generic maintain application component
     *
     * @param toastService toast service
     * @param service service associated to this component
     * @param router router service
     * @param activatedRoute activated route reference
     * @param routeElement route element used to redirect user when necessary (Ex: users, businessunits, etc)
     */
    constructor(protected toastService: ToastrService, protected service: GenericService<T>, protected router: Router, protected activatedRoute: ActivatedRoute, protected routeElement: string) {
        // call super
        super(toastService);
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // check if this is an create or edit operation
        this.activatedRoute.params.subscribe((params) => {
            if (params['id']) {
                // it's and edition operation
                this.isEdition = true;

                // store entity id
                this.entityId = params['id'];

                // load model
                this.service.getEntityById(params['id']).subscribe(
                    (data) => {
                        this.model = data;

                        // broadcast model loaded event
                        this.modelLoaded.emit();
                    },
                    (responseError) => this.handleErrors(responseError)
                );
            }
        });
    }

    /**
     * Method responsible by saving an entity
     */
    save() {
        // disable the submit button
        this.isSubmitDisabled = true;

        // check which operation is begin performed
        if (this.isEdition) {
            // update entity
            this.service.updateEntity(this.entityId, this.model).subscribe(
                (responseSuccess) => {
                    // show a success message
                    this.showSuccessMessage(responseSuccess['message']);

                    // back to search page
                    this.goToSearch();
                },
                (responseError) => {
                    this.handleErrors(responseError);

                    // enable the submit button
                    this.isSubmitDisabled = false;
                }
            );
        } else {
            // new entity
            this.service.createEntity(this.model).subscribe(
                (responseSuccess) => {
                    // show a success message
                    this.showSuccessMessage(responseSuccess['message']);

                    // back to search page
                    this.goToSearch();
                },
                (responseError) => {
                    this.handleErrors(responseError);

                    // enable the submit button
                    this.isSubmitDisabled = false;
                }
            );
        }
    }

    /**
     * Method responsible for redireting user to the search page relative to the chosen entity
     */
    goToSearch() {
        this.router.navigate([`/${this.routeElement}`]);
    }
}
