export enum AlertTypeCode {
    HIGH_TEMPERATURE = 'HIGH_TEMPERATURE',
    LOW_STATE_OF_CHARGE = 'LOW_STATE_OF_CHARGE',
    LOW_ELECTROLYTE_LEVEL = 'LOW_ELECTROLYTE_LEVEL',
    OVERVOLTAGE = 'OVERVOLTAGE',
    IMPROPER_MOVEMENT = 'IMPROPER_MOVEMENT',
    DEEP_DISCHARGE = 'DEEP_DISCHARGE',
    DISCONNECT_FROM_CHARGER = 'DISCONNECT_FROM_CHARGER',
    VIOLATED_REST = 'VIOLATED_REST',
    INCOMPLETED_RECHARGE = 'INCOMPLETED_RECHARGE',
    ANY = 'ANY'
}
