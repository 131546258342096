import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe resonsible to translate the device software update requisition type
 */
@Pipe({ name: 'deviceSoftwareUpdateRequisitionType' })
export class DeviceSoftwareUpdateRequisitionTypePipe implements PipeTransform {

    /**
     * @see @angular/core/PipeTransform/transform(value: any, ...args: any[])
     */
    transform(deviceSoftwareUpdateRequisitionType: string): string {
        let deviceSoftwareUpdateRequisitionTypeName: string;

        switch (deviceSoftwareUpdateRequisitionType) {
            case 'IN_THE_FIELD':
                deviceSoftwareUpdateRequisitionTypeName = 'Em campo';
                break;
            case 'OFF_THE_FIELD':
                deviceSoftwareUpdateRequisitionTypeName = 'Fora de campo';
                break;
            default:
                deviceSoftwareUpdateRequisitionTypeName = 'Nenhuma descrição para ' + deviceSoftwareUpdateRequisitionType;
                break;
        }

        return deviceSoftwareUpdateRequisitionTypeName;
    }

}
