import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from '../../shared/generic-service.service';
import { MouraConnectServer } from '../../shared/mechanism/moura-connect-server.service';
import { ServiceOrder } from '../../shared/model/service-order.model';

@Injectable()
export class ServiceOrderService extends GenericService<ServiceOrder> {

    /**
     * Default Constructor
     * @param mouraConnectServer moura connect server instance
     */
    constructor(protected mouraConnectServer: MouraConnectServer) {
        // call super
        super('/serviceorders', mouraConnectServer);
    }

    /**
     * Method responsible for performing the uninstall operation
     * @param serviceOrderId identification of an Uninstall Service Order
     * @param batteryId battery that will have its device uninstalled
     * @param deviceId device to be uninstalled
     * @param description item description
     */
    uninstall(serviceOrderId: number, batteryId: number, deviceId: number, description: string): Observable<any> {
        // call server to perform the call
        return this.mouraConnectServer.post(`/serviceorders/${serviceOrderId}/uninstall`, {batteryId, deviceId, description}, true);
    }
}
