import { HttpParams } from '@angular/common/http';
import { GenericSearchParameters } from './generic-search-parameters.model';

/**
 * Model class representing model search parameters
 */
export class ModelSearchParameters implements GenericSearchParameters {
    manufacturer: string;
    description: string;
    type: string;

    /**
     * Default Constructor
     */
    constructor() {
        this.manufacturer = '';
        this.description = '';
        this.type = '';
    }

    /**
     * @see GenericSearchParameters/generateHttpParams()
     */
    generateHttpParams(): HttpParams {
        // initialize parameter list
        let httpParams: HttpParams = new HttpParams();

        // assign parameter list
        if (this.manufacturer && this.manufacturer.trim().length > 0) {
            httpParams = httpParams.append('manufacturer', this.manufacturer);
        }

        if (this.description && this.description.trim().length > 0) {
            httpParams = httpParams.append('description', this.description);
        }

        if (this.type && this.type.trim().length > 0) {
            httpParams = httpParams.append('type', this.type);
        }

        return httpParams;
    }
}
