import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe responsible to translate the service order type
 */
@Pipe({ name: 'serviceOrderType' })
export class ServiceOrderTypePipe implements PipeTransform {

    /**
    * @see @angular/core/PipeTransform/transform(value: any, ...args: any[])
    */
    transform(serviceOrderType: string): string {
        let serviceOrderTypeName: string;

        switch (serviceOrderType) {
            case 'INSTALLATION':
                serviceOrderTypeName = 'Instalação';
                break;
            case 'UNINSTALL':
                serviceOrderTypeName = 'Desinstalação';
                break;
            case 'EXCHANGE':
                serviceOrderTypeName = 'Troca';
                break;
            default:
                serviceOrderTypeName = 'Nenhuma descrição para ' + serviceOrderType;
                break;
        }

        return serviceOrderTypeName;
    }
}
