import { Component, OnInit } from '@angular/core';
import { GenericMaintainEntityComponent } from '../../shared/generic-maintain-entity-component';
import { HeadOffice } from '../../shared/model/head-office.model';
import { ToastrService } from 'ngx-toastr';
import { HeadOfficeService } from './head-office.service';
import { Router, ActivatedRoute } from '@angular/router';

/**
 * Maintain head office component controller class
 */
@Component({ selector: 'app-maintain-head-office', templateUrl: './maintain-head-office.component.html', })
export class MaintainHeadOfficeComponent extends GenericMaintainEntityComponent<HeadOffice> implements OnInit {

    constructor(protected toastrService: ToastrService, protected headOfficeService: HeadOfficeService, protected router: Router, protected activatedRoute: ActivatedRoute) {
        // call super
        super(toastrService, headOfficeService, router, activatedRoute, 'headoffices');

        // initialize model
        this.model = new HeadOffice();
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // call super
        super.ngOnInit();

        // define page title
        this.pageTitle = this.isEdition ? 'Editar Matriz' : 'Nova Matriz';
    }

}
