import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GenericMaintainEntityComponent } from '../../shared/generic-maintain-entity-component';
import { Model } from '../../shared/model/model.model';
import { ModelService } from './model.service';

/**
 * Maintain model component controller class
 */
@Component({ selector: 'app-maintain-model', templateUrl: './maintain-model.component.html', })
export class MaintainModelComponent extends GenericMaintainEntityComponent<Model> implements OnInit {

    /**
     * Default constructor for generic application component
     *
     * @param toastService toast service
     * @param modelService service for model matters
     * @param router router service
     */
    constructor (protected toastService: ToastrService, protected modelService: ModelService, protected router: Router, protected activatedRoute: ActivatedRoute) {
        // call super
        super(toastService, modelService, router, activatedRoute, 'models');

        // initialize model
        this.model = new Model();
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // call super
        super.ngOnInit();

        // define page title
        this.pageTitle = this.isEdition ? 'Editar Modelo' : 'Novo Modelo';
    }
}
