import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { GenericPageableSearchComponent } from '../../shared/generic-pageable-search-component.component';
import { SecurityManager } from '../../shared/mechanism/security-manager.service';
import { BusinessArea } from '../../shared/model/business-area.model';
import { Customer } from '../../shared/model/customer.model';
import { DeviceSoftwareUpdateRequisition } from '../../shared/model/device-software-update-requisition.model';
import { DeviceSoftwareUpdateRequisitionStatus } from '../../shared/model/enum/device-software-update-requisition-status.enum';
import { UserProfile } from '../../shared/model/enum/user-profile.enum';
import {
    DeviceSoftwareUpdateRequisitionSearchParameters,
} from '../../shared/model/search/device-software-update-requisition-search-parameters';
import { User } from '../../shared/model/user.model';
import { CustomerService } from '../customer/customer.service';
import { DeviceSoftwareUpdateRequisitionService } from './device-software-update-requisition.service';
import { DeviceSoftwareUpdateRequisitionItem } from '../../shared/model/device-software-update-requisition-item.model';

/**
 * Searcg device software update requisitions controller class
 */
@Component({ selector: 'app-search-device-software-update-requisitions', templateUrl: './search-device-software-update-requisitions.component.html', })
export class SearchDeviceSoftwareUpdateRequisitionsComponent extends GenericPageableSearchComponent<DeviceSoftwareUpdateRequisition> implements OnInit {

    /**
     * Filters object. Overwrites the the inherited generic filters object.
     */
    filters: DeviceSoftwareUpdateRequisitionSearchParameters;

    /**
     * Variable responsible to hold business areas
     */
    businessAreas: BusinessArea[];

    /**
     * Variable to hold loaded customers.
     */
    loadedCustomers: Customer[];

    /**
     * Customer to be used as filter's content
     */
    customers: Customer[];

    /**
     * Device software update requisition that will be show in details by modal
     */
    selectedDeviceSoftwareUpdateRequisition: DeviceSoftwareUpdateRequisition;

    /**
     * Device software update requisition items that will be show in details by modal
     */
    selectedDeviceSoftwareUpdateRequisitionItems: DeviceSoftwareUpdateRequisitionItem[];

    /**
     * Default constructor for generic application component
     *
     * @param toastrService toast service
     * @param deviceSoftwareUpdateRequisitionService service from device software update requisition service
     * @param router router service
     * @param customerService service for customer matters
     * @param securityManager mechanism to deal with security matters
     */
    constructor(protected toastrService: ToastrService, protected deviceSoftwareUpdateRequisitionService: DeviceSoftwareUpdateRequisitionService, protected router: Router, private customerService: CustomerService, private securityManager: SecurityManager) {
        // call super
        super(toastrService, deviceSoftwareUpdateRequisitionService, router, 'devicesoftwareupdaterequisitions');
    }

    /**
    * @see @angular/core/OnInit/ngOnInit()
    */
    ngOnInit() {
        // call super
        super.ngOnInit();

        // load customers
        this.loadCustomers();

        // initialize varible
        this.selectedDeviceSoftwareUpdateRequisitionItems = [];

        // get logged user
        const loggedUser = this.securityManager.getLoggedUser();

        // instantiate filters
        this.filters = new DeviceSoftwareUpdateRequisitionSearchParameters();

        // set business area and customer id
        this.filters.businessAreaId = loggedUser.businessAreaId;
        this.filters.customers = loggedUser.internal && loggedUser.profile === UserProfile.COORDINATOR ? loggedUser.customers.map((customer: Customer) => customer.id) : !loggedUser.internal || (loggedUser.internal && loggedUser.profile === UserProfile.OPERATOR) ? loggedUser.customers.map((customer: Customer) => customer.id) : [0];

        // perform initial search
        this.search();
    }

    /**
     * Method responsible for loading all customers
     */
    loadCustomers() {
        // business area associated to logged user
        const loggedUser: User = this.securityManager.getLoggedUser();

        // in according to the user profile, check what customers should be seen by logged user
        if (loggedUser.profile === UserProfile.EXECUTIVE || loggedUser.profile === UserProfile.ADMIN) {
            this.customerService.getAllEntities().subscribe(
                (data) => this.assignAndSortCustomerData(data),
                (responseError) => this.handleErrors(responseError)
            );
        }
        else {
            this.customerService.getCustomersByBusinessArea(loggedUser.businessAreaId).subscribe(
                (data) => this.assignAndSortCustomerData(loggedUser.profile !== UserProfile.COORDINATOR ? data : data.filter((customer: Customer) => loggedUser.customers.findIndex((loggedUserCustomer: Customer) => customer.id === loggedUserCustomer.id) !== -1)),
                (responseError) => this.handleErrors(responseError)
            );
        }
    }

    /**
     * Method responsible for cleaning the search parameters
     */
    cleanFilters() {
        // get logged user
        const loggedUser = this.securityManager.getLoggedUser();

        this.filters.id = null;
        this.filters.status = '';
        this.filters.type = '';
        this.filters.startDate = null;
        this.filters.endDate = null;
        this.filters.customers = loggedUser.internal && loggedUser.profile === UserProfile.COORDINATOR ? loggedUser.customers.map((customer: Customer) => customer.id) : !loggedUser.internal || (loggedUser.internal && loggedUser.profile === UserProfile.OPERATOR) ? loggedUser.customers.map((customer: Customer) => customer.id) : [0];
    }

    /**
    * Method responsible for setting period date filter after user's selection
    *
    * @param customDateSelectionEvent provided only when a custom date is selected by the component
    */
    setPeriodDateFilter(customDateSelectionEvent: any) {
        this.filters.startDate = customDateSelectionEvent[0];
        this.filters.endDate = customDateSelectionEvent[1];
    }

    /**
     * Method responsible for getting period date filter
     *
     * @returns formatted period date filter
     */
    getFormattedPeriodDateFilter(): string {
        // formatted period date filter to be returned
        let formattedPeriodDateFilter: string = null;

        // if start and end date are present, show them
        if (this.filters.startDate && this.filters.endDate) {
            formattedPeriodDateFilter = moment(this.filters.startDate).format('DD/MM/YYYY');
            formattedPeriodDateFilter += '-';
            formattedPeriodDateFilter += moment(this.filters.endDate).format('DD/MM/YYYY');
        }

        return formattedPeriodDateFilter;
    }

    /**
     * Method responsible for indicating if a given device software update requisition is editable
     *
     * @param deviceSoftwareUpdateRequisition device software update requisition to be evaluated
     * @returns flag indicating if the given device software update requisition is editable
     */
    shouldEditDeviceSoftwareUpdateRequisition(deviceSoftwareUpdateRequisition: DeviceSoftwareUpdateRequisition): boolean {
        return deviceSoftwareUpdateRequisition.status === DeviceSoftwareUpdateRequisitionStatus.OPEN;
    }

    /**
     * Method responsible for indicating if a given device software update requisition has any details to be shown
     *
     * @param deviceSoftwareUpdateRequisition device software update requisition to be evaluated
     * @returns flag indicating if the given device software update requisition has any details to be shown
     */
    deviceSoftwareUpdateRequisitionHasAnyItems(deviceSoftwareUpdateRequisition: DeviceSoftwareUpdateRequisition): boolean {
        return deviceSoftwareUpdateRequisition.items && deviceSoftwareUpdateRequisition.items.length > 0;
    }

    /**
     * Method responsible for viewing device software update requisition items by setting choosen device software update requisition
     *
     * @param deviceSoftwareUpdateRequisition device software update requisition to be viewed
     */
    viewDeviceSoftwareUpdateRequisitionItems(deviceSoftwareUpdateRequisition: DeviceSoftwareUpdateRequisition) {
        this.selectedDeviceSoftwareUpdateRequisition = deviceSoftwareUpdateRequisition;
        this.selectedDeviceSoftwareUpdateRequisitionItems = deviceSoftwareUpdateRequisition.items;
    }

    /**
     * Method responsible for assigning and sorting customer data (callback for loading customers)
     *
     * @param customers customers to be assigned and ordered
     */
    private assignAndSortCustomerData(customers: Customer[]) {
        // get all customers
        this.customers = customers;

        // set the loaded customers
        this.loadedCustomers = customers;

        // sort customers
        this.customers.sort((a: Customer, b: Customer) => a.name.localeCompare(b.name));
    }
}
