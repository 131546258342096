import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SecurityManager } from './security-manager.service';

/**
 * Guard class responsible for redirecting user when there is no user or its token is invalid or when he got his session expired
 */
@Injectable()
export class AuthenticationGuard implements CanActivate {

    /**
     * Default constructor
     * @param router router service
     * @param securitymanager security manager
     */
    constructor(private router: Router, private securityManager: SecurityManager) { }

    /**
     *  @see @angular/router#CanActivate()
     */
    canActivate(): boolean {
        // flag that indicates activation or not of the resource
        const isActivated = this.securityManager.isSessionValid();

        // perform necessary actions in according to the flag
        if (!isActivated) {
            // Removes any information about the user from the session
            this.securityManager.logout();

            // not logged in so redirect to login page
            this.router.navigate(['login']);
        }

        return isActivated;
    }
}
