/**
 * Model class representing a Device
 */
export class Device {
    id: number;
    chipIdentification: string;
    serialNumber: string;
    boardIdentification: string;
    lotIdentification: string;
    group: string;
    type: string;
    hardwareVersion: string;
    softwareVersion: string;
    latitude: number;
    longitude: number;
    active: boolean;
    modifiedDate: Date;
    deviceType: string;
    certificate: string;
    contract: string;
    numberOfDailyUplinks: number;
}
