import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GenericPageableSearchComponent } from '../../shared/generic-pageable-search-component.component';
import { SecurityManager } from '../../shared/mechanism/security-manager.service';
import { Customer } from '../../shared/model/customer.model';
import { CustomerSearchParameters } from '../../shared/model/search/customer-search-parameters.model';
import { CustomerService } from './customer.service';

/**
 * Search customers component controller class
 */
@Component({ selector: 'app-search-customers', templateUrl: './search-customers.component.html' })
export class SearchCustomersComponent extends GenericPageableSearchComponent<Customer> implements OnInit {
    /**
     * Filters object. Overwrites the inherited generic filters object.
     */
    filters: CustomerSearchParameters;

    /**
     * Default constructor for generic application component
     * @param toastService toast service
     * @param customerService service for customer matters
     * @param router router service
     * @param securityManager mechanism to deal with security matters
     */
    constructor(protected toastService: ToastrService, protected customerService: CustomerService, protected router: Router, private securityManager: SecurityManager) {
        // call super
        super(toastService, customerService, router, 'customers');
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // call super
        super.ngOnInit();

        // instantiate filters
        this.filters = new CustomerSearchParameters();
        this.filters.businessAreaId = this.securityManager.getLoggedUser().businessAreaId;

        // setup pageable object
        this.pageable.sortField = 'name';

        // perform initial search
        this.search();
    }

    /**
     * Method responsible for cleaning the search parameters
     */
    cleanFilters() {
        // clear the filter with the default value
        this.filters.name = '';
        this.filters.socialName = '';
        this.filters.cnpj = '';
    }

    /**
    * Method responsible for opening upload input file dialog
    *
    * @param customerPictureInputField html reference for input file in order to open it
    */
    openCustomerPictureDialog(customerPictureInputField: HTMLInputElement) {
        customerPictureInputField.click();
    }

    /**
     * Method responsible for uploading a customer picture to the server
     *
     * @param customerId customer identification of the object which picture will be uploaded
     * @param fileListForUploading list of files to be uploaded
     */
    uploadCustomerPicture(customerId: number, fileListForUploading: FileList) {
        // get first file
        const fileToBeUploaded: File = fileListForUploading.item(0);

        // call picture upload service
        return this.customerService.uploadCustomerPicture(customerId, fileToBeUploaded).subscribe(
            () => {
                const customer: Customer = this.results.content.find((c: Customer) => c.id === customerId);
                customer.logoUrl = `customer-${customer.cnpj}`;

                // show sucess message
                this.showSuccessMessage('Logotipo atualizado com sucesso.');
            },
            () => {
                // provide generic error message
                this.showErrorMessage('Não foi possível atualizar o logotipo do cliente. Verifique se a foto fornecidade é realmente um formato de arquivo de foto e/ou se o tamanho da foto é apropriado');
            }
        );
    }
}
