import { Alert } from '../alert.model';
import { Battery } from '../battery.model';

/**
 * Model class representing operator report of tractionary batteries.
 */
export class TractionaryOperatorHomeReport {
    numberOfAlerts: number;
    numberOfBatteryReplacements: number;
    numberOfDeepDischargeAlerts: number;
    batteryIdlenessIndex: number;
    numberOfBatteries: number;
    numberOfBatteriesWithLowAutonomy: number;
    batteryStatusSummaryForTheLastDay: any;
    batteryCountGroupedByStatus: any;
    batteryAverageTimeGroupedByStatus: any;
    batteriesWithLowAutonomy: Battery[];
    openAlerts: Alert[];
    updatedDate: Date;

    constructor() {
        this.numberOfAlerts = 0;
        this.numberOfBatteryReplacements = 0;
        this.numberOfDeepDischargeAlerts = 0;
        this.batteryIdlenessIndex = 0;
        this.numberOfBatteries = 0;
        this.numberOfBatteriesWithLowAutonomy = 0;
        this.batteryStatusSummaryForTheLastDay = null;
        this.batteryCountGroupedByStatus = null;
        this.batteryAverageTimeGroupedByStatus = null;
        this.batteriesWithLowAutonomy = [];
        this.openAlerts = [];
        this.updatedDate = null;
    }
}

