import { HttpParams } from '@angular/common/http';
import * as moment from 'moment';

import { Customer } from '../customer.model';
import { GenericSearchParameters } from './generic-search-parameters.model';

/**
 * Model class representing alert search parameters
 */
export class AlertSearchParameters implements GenericSearchParameters {
    customerId: number;
    unitId: number;
    businessAreaId: number;
    batteryType: string;
    batteryOperationsIdentification: string;
    deviceChipIdentification: string;
    batteryUniqueIdentifier: string;
    typeCode: string;
    startDate: Date;
    endDate: Date;
    customers: Array<Customer>;

    /**
     * Default Constructor
     */
    constructor() {
        this.customerId = 0;
        this.unitId = 0;
        this.businessAreaId = 0;
        this.batteryType = '';
        this.batteryOperationsIdentification = '';
        this.deviceChipIdentification = '';
        this.batteryUniqueIdentifier = '';
        this.typeCode = '';
        this.startDate = null;
        this.endDate = null;
        this.customers = [];
    }

    /**
     * @see GenericSearchParameters/generateHttpParams()
     */
    generateHttpParams(): HttpParams {
        // initialize parameter list
        let httpParams: HttpParams = new HttpParams();

        // assign parameter list
        if (this.batteryType && this.batteryType.trim().length > 0) {
            httpParams = httpParams.append('batteryType', this.batteryType);
        }

        if (this.customerId > 0) {
            httpParams = httpParams.append('customerId', this.customerId.toString());
        }

        if (this.unitId > 0) {
            httpParams = httpParams.append('unitId', this.unitId.toString());
        }

        if (this.businessAreaId > 0) {
            httpParams = httpParams.append('businessAreaId', this.businessAreaId.toString());
        }

        // assign the battery identification
        if (this.batteryOperationsIdentification && this.batteryOperationsIdentification.length > 0) {
            httpParams = httpParams.append('batteryOperationsIdentification', this.batteryOperationsIdentification);
        }

        // assign the device chip identification
        if (this.deviceChipIdentification && this.deviceChipIdentification.length > 0) {
            httpParams = httpParams.append('deviceChipIdentification', this.deviceChipIdentification);
        }

        // assign the battery unique identifier
        if (this.batteryUniqueIdentifier && this.batteryUniqueIdentifier.length > 0) {
            httpParams = httpParams.append('batteryUniqueIdentifier', this.batteryUniqueIdentifier);
        }

        // assign the alert type
        if (this.typeCode && this.typeCode.length > 0) {
            httpParams = httpParams.append('typeCode', this.typeCode);
        }

        // assign start date parameter
        if (this.startDate) {
            httpParams = httpParams.append('startDate', moment(this.startDate).format('YYYYMMDDHHmmss'));
        }

        // assign end date parameter
        if (this.endDate) {
            httpParams = httpParams.append('endDate', moment(this.endDate).format('YYYYMMDDHHmmss'));
        }

        if (this.customers.length > 0) {
            for (const customer of this.customers) {
                httpParams = httpParams.append('customers', customer.id.toString());
            }
        }

        return httpParams;
    }
}
