import { Observable } from 'rxjs';
import { MouraConnectServer } from './mechanism/moura-connect-server.service';
import { Page } from './model/page.model';
import { Pageable } from './model/pageable.model';
import { GenericSearchParameters } from './model/search/generic-search-parameters.model';

/**
 * Generic service class
 */
export class GenericService<T> {

    /**
     * Default constructor
     * 
     * @param restAPIElement rest api element to compose url (ex: /users, /routes)
     * @param mouraConnectServer moura connect server
     */
    constructor(protected restAPIElement: string, protected mouraConnectServer: MouraConnectServer ) {}

    /**
     * Method responsible for performing a paginated search in an entity T
     *
     * @param pageable object which controls pagination and sorting
     * @param parameters list of parameters to be added into this search
     * @returns observable object containing a page of T
     */
    searchForEntities(pageable: Pageable, parameters: GenericSearchParameters): Observable<Page<T>> {
        return this.mouraConnectServer.get(`${this.restAPIElement}/filter`, pageable, parameters);
    }

    /**
     * Method responsible for getting all T entities
     *
     * @returns observable object containing a list of T
     */
    getAllEntities(): Observable<T[]> {
        return this.mouraConnectServer.get(this.restAPIElement);
    }

    /**
     * Method responsible for getting an entity T by its identifier
     *
     * @param entityId entity identifier to be found
     * @returns observable object containing T
     */
    getEntityById(entityId: number): Observable<T> {
        return this.mouraConnectServer.get<T>(`${this.restAPIElement}/${entityId}`);
    }

    /**
     * Method responsible for creating an entity T
     *
     * @param entity entity to be created
     * @returns observable object containing object or an error
     */
    createEntity(entity: T): Observable<string> {
        return this.mouraConnectServer.post<string>(this.restAPIElement, entity, false);
    }

    /**
     * Method responsible for updating an entity T
     *
     * @param entityId entity identifier to be updated
     * @param entity entity to be updated
     * @returns observable object containing object or an error
     */
    updateEntity(entityId: number,  entity: T): Observable<string> {
        return this.mouraConnectServer.put<string>(`${this.restAPIElement}/${entityId}`, entity);
    }

    /**
     * Method responsible for deleting an entity T
     *
     * @param entityId entity identifier used to
     * @returns observable object containing object or an error
     */
    deleteEntity(entityId: number): Observable<string> {
        return this.mouraConnectServer.delete<string>(`${this.restAPIElement}/${entityId}`);
    }
}
