import { BusinessArea } from './business-area.model';
import { Customer } from './customer.model';
import { Preferences } from './enum/preferences.enum';
import { UserProfile } from './enum/user-profile.enum';
import { Unit } from './unit.model';

/**
 * Model class representing an user
 */
export class User {
    id: number;
    name: string;
    photoUrl: string;
    jobTitle: string;
    profile: UserProfile;
    customers: Customer[];
    unitId: number;
    unit: Unit;
    businessAreaId: number;
    businessArea: BusinessArea;
    email: string;
    modifiedDate: Date;
    internal: boolean;
    preferences: Map<Preferences, any>;

    constructor() {
        // initialize the preferences
        this.preferences = new Map<Preferences, any>();
    }
}
