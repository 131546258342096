import { Component, Input, OnChanges, OnInit } from '@angular/core';
import * as moment from 'moment';
import { isNullOrUndefined } from 'util';

/**
 * Generic Chart component
 */
@Component({selector: 'app-chart', templateUrl: './chart.component.html'})
export class ChartComponent implements OnInit, OnChanges {
    /**
     * Input varible to define if chart must show a legend
     */
    visibleLegend: boolean;

    /**
     * Input varible to receive the base chart data (datasets, labels and type)
    */
    @Input() data: any;

    /**
     * Variable to hold labels array
     */
    labels: any[];

    /**
     * Input type varible
     */
    type: string;

    /**
     * Variable to hold datasets input
     */
    datasets: any[];

    /**
     * Variable to hold chart colors settings
     */
    chartColors: any[];

    /**
     * Variable to store chart options
     */
    options: any;

    /**
     * Variable to indicate if chart must be visible
     */
    visible: boolean;

    /**
     * Default constructor method
     */
    constructor() { }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // set visible
        this.visible = true;
        // set the base data
        this.datasets = this.data['datasets'];
        this.labels = this.data['labels'];
        this.type = this.data['type'] === undefined ? 'line' : this.data['type'];

        // set chart options
        this.options = {
            spanGaps: true,
            scaleShowVerticalLines: false,
            responsive: true,
            legend: {
                display: this.data['visibleLegend'] === undefined ? false : this.data['visibleLegend'],
                position: 'bottom'
            },
            tooltips: {
                enabled: true,
                callbacks: {
                    title: (tooltipItem) => {
                        return moment(this.labels[tooltipItem[0].index]).format('DD/MM/YY HH:mm');
                    },
                },
            },
            scales: {
                yAxes: [{
                    scaleLabel: {
                        display: this.data['yScaleLabel'] !== undefined,
                        labelString: this.data['yScaleLabel']
                    },
                    ticks: {
                        // y axes initial value
                        beginAtZero: this.data['beginAtZero'] === undefined ? true : this.data['beginAtZero']
                    }
                }],
                xAxes: [{
                    type: 'time',
                    time: {
                        displayFormats: {
                            millisecond: 'DD/MM/YY HH:mm:ss',
                            minute: 'DD/MM/YY HH:mm',
                            hour: 'DD/MM/YY HH:mm',
                            day: 'DD/MM/YY',
                         }
                    },
                    scaleLabel: {
                        display: this.data['xScaleLabel'] !== undefined,
                        labelString: this.data['xScaleLabel']
                    },
                    ticks: {
                        // labels rotation
                        maxRotation: 0,
                        minRotation: 0
                    },
                }]
            }
        };

        // set colors
        this.chartColors = [
            { // primary - blue
                backgroundColor: 'rgba(59,135,232,0.2)',
                borderColor: 'rgba(59,135,232,1)',
                pointBackgroundColor: 'rgba(255,255,255,1)',
                pointBorderColor: '#3B87E8',
                pointHoverBackgroundColor: '#3B87E8',
                pointHoverBorderColor: 'rgba(255,255,255,0.8)'
            },
            { // green
                backgroundColor: 'rgba(66, 160, 0, 0.2)',
                borderColor: 'rgba(66, 160, 0, 1)',
                pointBackgroundColor: 'rgba(66, 160, 0, 1)',
                pointBorderColor: '#6DB63B',
                pointHoverBackgroundColor: '#6DB63B',
                pointHoverBorderColor: 'rgba(255,255,255,0.8)'
            },
            { // red
                backgroundColor: 'rgba(237, 11, 19, 0.2)',
                borderColor: 'rgba(237, 11, 19, 1)',
                pointBackgroundColor: 'rgba(237, 11, 19, 1)',
                pointBorderColor: '#ED0B13',
                pointHoverBackgroundColor: '#ED0B13',
                pointHoverBorderColor: 'rgba(255,255,255,0.8)'
            },
        ];
    }

    /**
     * @see @angular/core/OnChanges/ngOnChanges()
     */
    ngOnChanges(changes: any) {
        // update chart datasets and labels
        if (!isNullOrUndefined(changes.data)) {
            this.datasets = changes.data.currentValue['datasets'];

            // check if labels must be updated
            if (this.labels !== changes.data.currentValue['labels']) {
                this.labels = changes.data.currentValue['labels'];

                // hide and unhide component to refresh labels axis
                // FIXME: just updating the variable does not makes ChartJS rebuild labels. The found solution corrects this, but should be a temporary method
                this.visible = false;
                setTimeout(() => { this.visible = true; }, 1);
            }
        }

    }

    /**
     * Method responsible for respond if chart must be visible
     */
    isVisible() {
        return this.visible;
    }
}
