import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GenericService } from '../../shared/generic-service.service';
import { MouraConnectServer } from '../../shared/mechanism/moura-connect-server.service';
import { SecurityManager } from '../../shared/mechanism/security-manager.service';
import { DeviceSoftware } from '../../shared/model/device-software.model';

/**
 * Class responsible for dealing with device software matters
 */
@Injectable()
export class DeviceSoftwareService extends GenericService<DeviceSoftware> {

    /**
     * Default constructor
     *
     * @param mouraConnectServer moura connect server
     * @param securityManager security manager
     */
    constructor(protected mouraConnectServer: MouraConnectServer, protected securityManager: SecurityManager) {
        // call super
        super('/devicesoftwares', mouraConnectServer);
    }

    /**
     * Method responsible for creating a device software
     *
     * @param deviceSoftware device software to be created
     * @param file software file
     * @returns observable object containing object or an error
     */
    createDeviceSoftware(deviceSoftware: DeviceSoftware, file: File): Observable<string> {
        // build specific structure to make the request
        const formData: FormData = new FormData();
        formData.append('deviceSoftware', new Blob([JSON.stringify(deviceSoftware)], {type: 'application/json'}));
        formData.append('file', file, file.name);

        return this.mouraConnectServer.post<string>(this.restAPIElement, formData, false);
    }

    /**
	 * Method responsible for getting all active device softwares
	 *
	 * @return observable object containing a list of active device softwares
	 */
    getAllActiveDeviceSoftwares() {
        return this.mouraConnectServer.get(`${this.restAPIElement}/active`);
    }
}
