import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from '../../shared/generic-service.service';
import { MouraConnectServer } from '../../shared/mechanism/moura-connect-server.service';
import { SecurityManager } from '../../shared/mechanism/security-manager.service';
import { User } from '../../shared/model/user.model';

/**
 * Class responsible for dealing with user matters
 */
@Injectable()
export class UserService extends GenericService<User> {
    /**
     * Default Constructor
     *
     * @param mouraConnectServer moura connect server
     * @param securityManager  security manager
     */
    constructor(protected mouraConnectServer: MouraConnectServer, protected securityManager: SecurityManager) {
        // call super
        super('/users', mouraConnectServer);
    }

    /**
     * Method responsible for calling API to upload the desired picture
     *
     * @param fileToBeUploaded file to be uploaded
     */
    uploadProfilePicture(fileToBeUploaded: File): Observable<any> {
        // build specific structure to send file
        const formData: FormData = new FormData();
        formData.append('picture', fileToBeUploaded, fileToBeUploaded.name);

        // call moura connect server to perform the call
        return this.mouraConnectServer.post(`/users/${this.securityManager.getLoggedUser().id}/picture`, formData, true);
    }

    /**
    * Method responsible for updating an user
    *
    * @param updatedUser user object already updated
    * @returns structure containing an error or success message
    */
    updateUser(updatedUser: User): Observable<any> {
        // send an update request to server
        return this.mouraConnectServer.put('/users/' + updatedUser.id, updatedUser);
    }
}
