import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';

import { MonthPipe } from '../../../shared/pipe/month.pipe';

declare var Chart: any;

/**
 * Controller class for the Battery Deep Discharge Card Component.
 */
@Component({ selector: 'app-battery-deep-discharge-card', templateUrl: './battery-deep-discharge-card.component.html', styleUrls: ['./battery-deep-discharge-card.component.scss'] })
export class BatteryDeepDischargeCardComponent implements OnInit, OnChanges {

    /**
     * Variable to handle the chart.
     */
    @ViewChild(BaseChartDirective) chart: BaseChartDirective;

    /**
     * Flag indicating whether the component has finished loading its content.
     */
    contentLoaded: boolean;

    /**
     * Variable to hold labels array.
     */
    labels: Array<string>;

    /**
     * Variable to hold data array.
     */
    data: Array<number>;

    /**
     * Variable to hold datasets input
     */
    datasets: any[];

    /**
     * Variable to store chart options
     */
    options: any;

    /**
     * The battery deep discharge summary chart data.
     */
    @Input() deepDischargeSummary: any;

    /**
     * Variable to hold the replacements with deep discharge percentage.
     */
    @Input() replacementsWithDeepDischargePercentage: number;

    /**
     * Default constructor
     *
     * @param monthPipe month pipe
     */
    constructor(private monthPipe: MonthPipe) {
        // initialize variables
        this.contentLoaded = false;

        // set chart options
        this.options = {
            showDatapoints: true,
            maintainAspectRatio: true,
            scaleShowVerticalLines: false,
            responsive: true,
            tooltips: {
                enabled: true,
                mode: 'point'
            },
            legend: {
                display: false,
            },
            layout: {
                padding: {
                    top: 10
                }
            },
            scales: {
                xAxes: [{
                    barPercentage: 1,
                    categoryPercentage: 0.5,
                    ticks: {
                        display: true,
                        padding: 10,
                    },
                    gridLines: {
                        display: false
                    }
                }],
                yAxes: [{
                    ticks: {
                        display: false
                    },
                    gridLines: {
                        drawTicks: false,
                        drawBorder: false,
                        drawOnChartArea: false
                    }
                }]
            },
            elements: {
                line: {
                    fill: false,
                    pointBorderWidth: 5,
                }
            }
        };
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // create a register to the value above the dot
        this.createChartRegisterTextCenter();
    }

    /**
     * @see @angular/core/OnChanges/ngOnChanges()
     */
    ngOnChanges() {
        // build the chart
        this.buildChart();
    }

    /**
     * Method responsible to build the chart, setting the chart label, data and background color.
     */
    private buildChart() {
        // create the battery deep discharge summary chart data
        this.buildBatteryDeepDischargeSummaryChartData();

        // Recreate the chart. Angular isn't able to update the chart correctly.
        if (this.chart && this.contentLoaded) {
            // destroy the current chart.
            this.chart.chart.destroy();
            this.chart.chart = 0;

            // set the values, labels, colors and options to the new chart.
            this.chart.datasets = this.datasets;
            this.chart.options = this.options;
            this.chart.labels = this.labels;
            this.chart.colors = this.datasets;

            // build the chart with the new values.
            this.chart.chart = this.chart.getChartBuilder(this.chart.ctx);
        }
    }

    /**
     * Method responsible to create the chart object for the battery deep discharge summary.
     */
    private buildBatteryDeepDischargeSummaryChartData() {
        // initialize the variable
        const chartData: Array<{ 'year': number, 'month': number, 'value': number }> = [];

        this.contentLoaded = false;
        this.labels = [];

        // build the chart data array
        for (const month in this.deepDischargeSummary) {
            if (this.deepDischargeSummary.hasOwnProperty(month)) {
                chartData.push({ 'year': Number(month.split('|')[0]), 'month': Number(month.split('|')[1]), 'value': this.deepDischargeSummary[month] });
            }
        }

        // sort the chart data by month
        chartData.sort((a, b) => a.year - b.year || a.month - b.month);

        // set the chart variables
        this.labels = chartData.map(d => this.monthPipe.transform(d.month));
        this.data = chartData.map(d => d.value);

        // check if has value
        if (Math.max(...this.data) > 0) {
            // set the datasets
            this.datasets = [
                {
                    data: this.data,
                    backgroundColor: 'rgb(160,234,204,0.8)',
                    borderColor: 'rgb(160,234,204,0.8)',
                    label: 'Total de descarga profunda'
                }
            ];

            // set content loaded
            this.contentLoaded = true;
        }
    }

    /**
     * Method responsible to create a register to draw the value above or bellow the point.
     */
    private createChartRegisterTextCenter() {
        // use the declared variable to register a plugin.
        Chart.plugins.register({
            // action that will be executed after draw the chart.
            beforeDraw: (chart: any) => {
                if (chart.config.options.showDatapoints) {
                    // get the chart context
                    const ctx = chart.chart.ctx;

                    // set the value style
                    ctx.font = Chart.helpers.fontString(16, 'normal', Chart.defaults.global.defaultFontFamily);
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';
                    ctx.fillStyle = Chart.helpers.getValueOrDefault(chart.config.options.showDatapoints.fontColor, chart.config.options.defaultFontColor);

                    // get the dataset
                    const dataset = chart.data.datasets[0];

                    for (let i = 0; i < dataset.data.length; i++) {
                        // calculate the value position
                        const model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;
                        const scaleMax = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._yScale.maxHeight;
                        const yPos = (scaleMax - model.y) / scaleMax >= 0.93 ? model.y + 25 : model.y - 5;
                        // render the value of the chart above the bar
                        ctx.fillText(dataset.data[i], model.x, yPos);
                    }
                }
            }
        });
    }
}
