import { Injectable } from '@angular/core';
import { GenericService } from '../../shared/generic-service.service';
import { MouraConnectServer } from '../../shared/mechanism/moura-connect-server.service';
import { Alert } from '../../shared/model/alert.model';

/**
 * Class responsible for dealing with alerts
 */
@Injectable()
export class AlertService extends GenericService<Alert> {

    /**
     * Default Constuctor
     *
     * @param mouraConnectServer moura connect server instance
     */
    constructor(protected mouraConnectServer: MouraConnectServer) {
        // call super
        super('/alerts', mouraConnectServer);
    }
}
