import { Pipe, PipeTransform } from '@angular/core';
import { UserProfile } from '../model/enum/user-profile.enum';

/**
 * Pipe responsible to translate the user profile information
 */
@Pipe({ name: 'userProfile' })
export class UserProfilePipe implements PipeTransform {

    /**
     * @see @angular/core/PipeTransform/transform(value: any, ...args: any[])
     */
    transform(userProfile: UserProfile): string {
        // user profile to be returned
        let userProfileName: string = '-';

        // decide what icon should be returned
        switch (userProfile) {

            case 'ADMIN':
                userProfileName = 'Administrador';
                break;

            case 'EXECUTIVE':
                userProfileName = 'Executivo';
                break;
            case 'MANAGER':
                userProfileName = 'Gerente';
                break;
            case 'COORDINATOR':
                userProfileName = 'Coordenador';
                break;
            case 'OPERATOR':
                userProfileName = 'Operador';
                break;
            case 'PENDING':
                userProfileName = 'Pendente';
                break;
            default:
                userProfileName = '-';
            break;
        }

        return userProfileName;
    }
}
