import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'util';
import * as XLSX from 'xlsx';
import { GenericService } from '../../shared/generic-service.service';
import { MouraConnectServer } from '../../shared/mechanism/moura-connect-server.service';
import { SecurityManager } from '../../shared/mechanism/security-manager.service';
import { Device } from '../../shared/model/device.model';
import { BatteryType } from '../../shared/model/enum/battery-type.enum';

/**
 * Class responsible for dealing with device matters
 */
@Injectable()
export class DeviceService extends GenericService<Device> {

    /**
    * Default Constructor
    * @param mouraConnectServer moura connect server
    * @param securityManager  security manager
    */
    constructor(protected mouraConnectServer: MouraConnectServer, protected securityManager: SecurityManager) {
        // call super
        super('/devices', mouraConnectServer);
    }

    /**
     * Method responsible for creating a list of devices
     *
     * @param devices devices to be created
     * @returns observable object containing object or an error
     */
    createDevices(devices: Device[]): Observable<any> {
        // call server to perform the call
        return this.mouraConnectServer.post('/devices/batch', devices);
    }

    /**
     * Method responsible for processing devices from a given spreadsheet by parsing all data
     *
     * @param spreadsheet spreadsheed file to be processed
     * @returns observable object containing a list of processed devices in case of success
     */
    processDevicesFromSpreadsheet(spreadsheet: File): Observable<Device[]> {
        // create observable operation
        const observableOperation: Observable<Device[]> = Observable.create(observer => {
            // validate file type
            if (spreadsheet.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                observer.error('Formato de arquivo inválido. Verifique se o arquivo é compatível com o modelo de planilha fornecido.');
            }
            else {
                // open spreadsheet and read file
                const fileReader: FileReader = new FileReader();
                fileReader.readAsBinaryString(spreadsheet);

                // in case of error when reading file
                fileReader.onerror = (error) => observer.error(error);

                // in case of success when reading file
                fileReader.onload = () => {
                    // create a binary workbook
                    const workbook: XLSX.WorkBook = XLSX.read(fileReader.result, {type: 'binary'});

                    // convert spreadsheet content into JSON
                    const jsonData = workbook.SheetNames.reduce((initial, name) => {
                            const sheet = workbook.Sheets[name];
                            initial[name] = XLSX.utils.sheet_to_json(sheet);
                            return initial;
                    }, {});

                    // since we processed contents, now we must validate the content and return a list of devices
                    const devices: Device[] = [];
                    let errorMessage: String = null;

                    // check if has data
                    if ( jsonData['Dispositivos'].length === 0 ) {
                        errorMessage = 'A planilha não deve ser vazia';
                    }

                    for (let i = 0; i < jsonData['Dispositivos'].length && isNullOrUndefined(errorMessage); i++) {
                        // check number of elements in the line
                        const lineToBeProcessed = jsonData['Dispositivos'][i];

                        // check if device type column has value
                        if (isNullOrUndefined(lineToBeProcessed['Tipo']) || lineToBeProcessed['Tipo'].length === 0 ) {
                            errorMessage = 'A coluna "Tipo", na linha ' + (i + 1) + ' da planilha importada é obrigatória';
                        }
                        // check if group column has value
                        else if (isNullOrUndefined(lineToBeProcessed['Grupo']) || lineToBeProcessed['Grupo'].length === 0) {
                            errorMessage = 'A coluna "Tipo", na Grupo ' + (i + 1) + ' da planilha importada é obrigatória';
                        }
                        // check if serial number column has value
                        else if ( isNullOrUndefined(lineToBeProcessed['Número de série']) || lineToBeProcessed['Número de série'].length === 0 ) {
                            errorMessage = 'A coluna "Número de série", na linha ' + (i + 1) + ' da planilha importada é obrigatória';
                        }
                        // check if type column has value and it's valid
                        else if (isNullOrUndefined(lineToBeProcessed['Tipo de bateria']) || (lineToBeProcessed['Tipo de bateria'] !== 'TRACIONÁRIA' && lineToBeProcessed['Tipo de bateria'] !== 'ESTACIONÁRIA')) {
                            errorMessage = 'A coluna "Tipo", na linha ' + (i + 1) + ' da planilha importada é obrigatória e os únicos valores permitidos são: TRACIONÁRIA e ESTACIONÁRIA';
                        }
                        // check if board identification column has value
                        else if (isNullOrUndefined(lineToBeProcessed['Identificação da placa']) || lineToBeProcessed['Identificação da placa'].length === 0) {
                            errorMessage = 'A coluna "Identificação da placa", na linha ' + (i + 1) + ' da planilha importada é obrigatória';
                        }
                        // check if lot identification column has value
                        else if (isNullOrUndefined(lineToBeProcessed['Lote']) || lineToBeProcessed['Lote'].length === 0) {
                            errorMessage = 'A coluna "Lote", na linha ' + (i + 1) + ' da planilha importada é obrigatória';
                        }
                        // check if certificate column has value
                        else if (isNullOrUndefined(lineToBeProcessed['Certificado']) || lineToBeProcessed['Certificado'].length === 0) {
                            errorMessage = 'A coluna "Certificado", na linha ' + (i + 1) + ' da planilha importada é obrigatória';
                        }
                        // check if contrct column has value
                        else if (isNullOrUndefined(lineToBeProcessed['Contrato']) || lineToBeProcessed['Contrato'].length === 0) {
                            errorMessage = 'A coluna "Contrato", na linha ' + (i + 1) + ' da planilha importada é obrigatória';
                        }
                        // check if number of daily uplinks column has value
                        else if (isNullOrUndefined(lineToBeProcessed['Número de mensagens diárias']) || isNaN(Number(lineToBeProcessed['Número de mensagens diárias']))) {
                            errorMessage = 'A coluna "Número de mensagens diárias", na linha ' + (i + 1) + ' da planilha importada é obrigatória e deve ser composta apenas por números';
                        }
                        // check if hardware version column has value
                        else if (isNullOrUndefined(lineToBeProcessed['Versão do hardware']) || lineToBeProcessed['Versão do hardware'].length === 0) {
                            errorMessage = 'A coluna "Versão do hardware", na linha ' + (i + 1) + ' da planilha importada é obrigatória';
                        }
                        // check if device software version column has value
                        else if (isNullOrUndefined(lineToBeProcessed['Versão do software do dispositivo']) || lineToBeProcessed['Versão do software do dispositivo'].length === 0) {
                            errorMessage = 'A coluna "Versão do software do dispositivo", na linha ' + (i + 1) + ' da planilha importada é obrigatória';
                        }
                        else {
                            // initialize device
                            const device: Device = new Device();

                            // assign properties to it
                            device.deviceType = lineToBeProcessed['Tipo'];
                            device.group = lineToBeProcessed['Grupo'];
                            device.serialNumber = lineToBeProcessed['Número de série'];
                            device.type = lineToBeProcessed['Tipo de bateria'] === 'TRACIONÁRIA' ? BatteryType.TRACTIONARY : BatteryType.STATIONARY;
                            device.boardIdentification = lineToBeProcessed['Identificação da placa'];
                            device.lotIdentification = lineToBeProcessed['Lote'];
                            device.certificate = lineToBeProcessed['Certificado'];
                            device.contract = lineToBeProcessed['Contrato'];
                            device.numberOfDailyUplinks = Number(lineToBeProcessed['Número de mensagens diárias']);
                            device.hardwareVersion = lineToBeProcessed['Versão do hardware'];
                            device.softwareVersion = lineToBeProcessed['Versão do software do dispositivo'];

                            // add it to the list
                            devices.push(device);
                        }
                    }

                    // check how this observable should be ended
                    if (isNullOrUndefined(errorMessage)) {
                        // return device list
                        observer.next(devices);

                        // end observer
                        observer.complete();
                    }
                    else {
                        observer.error(errorMessage);
                    }
                };
            }
        });

        return observableOperation;
    }

    /**
	 * Method responsible for getting devices by active flag
	 *
	 * @return observable object containing a list devices filtered by flag
	 */
    findByActive(isActive: boolean) {
        return this.mouraConnectServer.get(`${this.restAPIElement}/active/${isActive}`);
    }
}
