import { HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { GenericSearchParameters } from './generic-search-parameters.model';

/**
 * Model class representing log search parameters
 */
export class LogEntrySearchParameters implements GenericSearchParameters {
    user: string;
    location: string;
    source: string;
    startDate: Date;
    endDate: Date;

    /**
     * default constructor
     */
    constructor() {
        this.user = '';
        this.location = '';
        this.source = '';
        this.startDate = null;
        this.endDate = null;
    }

    /**
     * @see GenericSearchParameters/generateHttpParams()
     */
    generateHttpParams(): HttpParams {
        // initialize parameter list
        let httpParams: HttpParams = new HttpParams();

        // assign log entry user parameter
        if (this.user && this.user.length > 0) {
            httpParams = httpParams.append('user', this.user);
        }

        // assign log entry location parameter
        if (this.location && this.location.length > 0) {
            httpParams = httpParams.append('location', this.location);
        }

        // assign log entry source parameter
        if (this.source && this.source.length > 0) {
            httpParams = httpParams.append('source', this.source);
        }

        // assign log entry start date parameter
        if (this.startDate) {
            httpParams = httpParams.append('startDate', moment(this.startDate).format('YYYYMMDDHHmmss'));
        }

        // assign log entry end date parameter
        if (this.endDate) {
            httpParams = httpParams.append('endDate', moment(this.endDate).format('YYYYMMDDHHmmss'));
        }

        return httpParams;
    }
}
