import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GenericPageableSearchComponent } from '../../shared/generic-pageable-search-component.component';
import { SecurityManager } from '../../shared/mechanism/security-manager.service';
import { UserSearchParameters } from '../../shared/model/search/user-search-parameters.model';
import { User } from '../../shared/model/user.model';
import { UserService } from './user.service';

/**
 * Search user component controller class
 */
@Component({ selector: 'app-search-users', templateUrl: './search-users.component.html' })
export class SearchUsersComponent extends GenericPageableSearchComponent<User> implements OnInit {
    /**
     * Filters object. Overwrites the inherited generic filters object.
     */
    filters: UserSearchParameters;

    /**
     * Business areas to be used as filter's content
     */
    users: User[];

    /**
     * Default constructor
     *
     * @param toastService toast service
     * @param businessAreaService service for business area matters
     * @param router router service
     */
    constructor(protected toastService: ToastrService, protected userService: UserService, protected router: Router, protected securityManager: SecurityManager) {
        // call super
        super(toastService, userService, router, 'users');
    }

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // call super
        super.ngOnInit();

        // instantiate filters
        this.filters = new UserSearchParameters();
        this.filters.businessAreaId = this.securityManager.getLoggedUser().businessAreaId;

        // setup pageable object
        this.pageable.sortField = 'name';

        // perform initial search
        this.search();
    }

    /**
     * Method responsible for indicating if user type information can be shown (or selected)
     *
     * @returns flag indicating if user type information can be shown (or selected)
     */
    shouldDisplayUserType() {
        return this.securityManager.getLoggedUser().internal;
    }

    /**
     * Method responsible for indicating if user profile information can be shown (or selected)
     *
     * @returns flag indicating if user profile information can be shown (or selected)
     */
    shouldDisplayUserProfile() {
        return this.filters.internalUser === '' || this.filters.internalUser === 'true';
    }

    /**
     * Method responsible for indicating if a given user is editable
     *
     * @param user user to be evaluated
     * @returns flag indicating if the given user is editable
     */
    isUserEditable(user: User): boolean {
        return this.securityManager.getLoggedUser().id !== user.id;
    }

    /**
     * Method responsible for indicating if a given user is removable
     *
     * @param user user to be evaluated
     * @returns flag indicating if the given user is editable
     */
    isUserRemovable(user: User): boolean {
        return this.securityManager.getLoggedUser().id !== user.id;
    }

    /**
     * Method responsible for cleaning the search parameters
     */
    cleanFilters() {
        // return the others to default values
        this.filters.name = '';
        this.filters.email = '';
        this.filters.jobTitle = '';
        this.filters.internalUser = '';
        this.filters.profile = '';
    }

    /**
     * Method responsible to get the user customers as string
     *
     * @param user the user object
     * @returns customers as string
     */
    getUserCustomers(user: User): string {
        let customersAsString: string = '';

        if (user.customers !== null && user.customers.length > 1) {
            for (const customer of user.customers) {
                customersAsString += customer.name + '\n';
            }
        }

        return customersAsString;
    }
}
