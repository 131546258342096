import { Component, Input } from '@angular/core';
import { SiteStatus } from '../../../shared/model/enum/site-status.enum';
import { Site } from '../../../shared/model/report/stationary/site.model';

/**
 * Controller class for the Battery Sites Table Card Component.
 */
@Component({ selector: 'app-home-site-information', templateUrl: './home-site-information.component.html', styleUrls: ['home-site-information.component.scss']})
export class HomeSiteInformationComponent {
    /**
     * Expression representing the height of the component. For example: '700px'.
     * By setting this property, the component is able to create a scrollbar automatically, if necessary.
     */
    @Input() height: string;

    /**
     * The list of site information that will be displayed in the table.
     */
    @Input() siteInformationList: Site[];

    /**
     * The current customer id
     */
    @Input() customerId: number;

    /**
     * Variable to hold if is small device screen
     */
    @Input() isSmallDeviceScreen: boolean;

    /**
     * Method responsible for getting a css class if site has or has not alerts
     *
     * @param status the current status of the site
     * @returns site status css class
     */
    getSiteStatusCssClass(status: SiteStatus): string {
        return status === SiteStatus.CRITICAL ? 'status-critical' : (status === SiteStatus.ON_ALERT ? 'status-alert' : 'status-active');
    }

    /**
     * Method responsible for returning a message to the tooltip
     *
     * @param status the current status of the site
     * @returns the tooltip message
     */
    getSiteTooltipMessage(status: SiteStatus): string {
        return status === SiteStatus.CRITICAL ? 'Site com alerta de movimentação indevida' : 'Site com alerta';
    }
}

