import { BusinessArea } from './business-area.model';
import { Customer } from './customer.model';
import { ServiceOrderStatus } from './enum/service-order-status.enum';
import { ServiceOrderType } from './enum/service-order-type.enum';
import { ServiceOrderItem } from './service-order-item.model';
import { Unit } from './unit.model';

/**
 * Model representing a service order
 */
export class ServiceOrder {
    id: number;
    customer: Customer;
    customerId: number;
    businessArea: BusinessArea;
    businessAreaId: number;
    unit: Unit;
    unitId: number;
    numberOfDevices: number;
    modifiedDate: Date;
    executionDate: Date;
    status: ServiceOrderStatus;
    type: ServiceOrderType;
    items: ServiceOrderItem[];
}
