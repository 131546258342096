import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe responsible to format user type (internal or external)
 */
@Pipe({ name: 'userType' })
export class UserTypePipe implements PipeTransform {

    /**
     * @see @angular/core/PipeTransform/transform(value: any, ...args: any[])
     */
    transform(value: string): string {
        // list of recognized internal emails
        const internalEmails: string[] = ['moura.int', 'grupomoura.com', 'itemm.org.br', 'icmoura.org', 'moura.com.br'];

        // fullfill formatted type
        const formattedType: string = internalEmails.some(element => value.endsWith(element)) ? 'Interno' : 'Externo';

        return formattedType;
    }
}
