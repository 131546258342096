import { Injectable } from '@angular/core';
import { deserialize, serialize } from 'class-transformer';
import { AES, enc } from 'crypto-ts';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { environment } from '../../../environments/environment';
import { Preferences } from '../model/enum/preferences.enum';
import { User } from '../model/user.model';
import { MouraConnectServer } from './moura-connect-server.service';

/**
 * Class responsible for dealing with security matters
 */
@Injectable()
export class SecurityManager {
    /**
     * Key used to manipulate token at local storage
     */
    private static readonly localStorageKeyToken: string = 'moura-connect';

    /**
     * Key used to manipulate session expiration at local storage
     */
    private static readonly localStorageKeySessionExpiration: string = 'moura-connect.expiration';

    /**
     * Key used to manipulate user data at local storage
     */
    private static readonly localStorageKeyUserData: string = 'moura-connect.user';

    /**
     * Default Constuctor
     *
     * @param mouraConnectServer Moura Connect server instance
     */
    constructor(private mouraConnectServer: MouraConnectServer) { }

    /**
     * Method responsible for logging in the application an user
     *
     * @param email email to be used at login process
     * @param password password to be used at login process
     * @param shouldMakeExternalAuthentication flag indicating if should make external authentication
     * @return observable element where success result should be discarded and error should be treated
     */
    login(email: string, password: string, shouldMakeExternalAuthentication: boolean): Observable<User> {
        // call erouting server to perform the call
        return this.mouraConnectServer.post<User>('/users/login', {email, password, shouldMakeExternalAuthentication}, true).pipe(map(
            response => {
                // store token into local storage
                localStorage.setItem(SecurityManager.localStorageKeyToken, this.encryptInformation(response.headers.get('Authorization')));

                // put a expiration date for session
                localStorage.setItem(SecurityManager.localStorageKeySessionExpiration, moment().add(7, 'days').format());

                // store logged user information into local storage
                localStorage.setItem(SecurityManager.localStorageKeyUserData, this.encryptInformation(serialize<User>(response.body)));

                // return user found at the of the process
                return response.body;
            }
        ));
    }

    /**
     * Logs the user out. Clear the token from the server then erases it from localStorage.
     */
    logout() {
        // remove everything from localstorage
        localStorage.removeItem(SecurityManager.localStorageKeySessionExpiration);
        localStorage.removeItem(SecurityManager.localStorageKeyUserData);
        localStorage.removeItem(SecurityManager.localStorageKeyToken);
    }

    /**
     * Method responsible for returning logged user if there is one
     *
     * @returns logged user or null
     */
    getLoggedUser(): User | null {
        // get logged user from localstorage
        const loggedUserInformation: string = localStorage.getItem(SecurityManager.localStorageKeyUserData);
        if (loggedUserInformation !== null && loggedUserInformation.length > 0)  {
            // set the user object
            const user = deserialize(User, this.decryptInformation(loggedUserInformation));

            // A Map object is always serialized as a common object. Due to this problem we need to convert this object
            // coming from deserialization to a map every time. This is a issue that we need to revisit someday
            const preferences = new Map<Preferences, any>();
            Object.keys(user.preferences).forEach(key => { preferences.set(Preferences[key], user.preferences[key]); });
            user.preferences = preferences;

            return user;
        }
        else {
            return null;
        }
    }

    /**
     * Method responsible for getting a logged user preference
     *
     * @param preference preference to be retrieved
     * @returns logged user preference
     */
    getLoggedUserPreference(preference: Preferences): any | null {
        // preference value to be returned
        let preferenceValue: any = null;

        // check if exists a logged user in order to extract preference value
        const loggedUser: User = this.getLoggedUser();
        if (!isNullOrUndefined(loggedUser)) {
            preferenceValue = loggedUser.preferences.get(preference);
        }

        return preferenceValue;
    }

    /**
     * Method responsible for getting the authentication token
     *
     * @returns authentication token
     */
    getAuthenticationToken(): string | null {
        const tokenInformation: string = localStorage.getItem(SecurityManager.localStorageKeyToken);
        if (!isNullOrUndefined(tokenInformation) ) {
            return this.decryptInformation(tokenInformation);
        }
        else {
            return null;
        }
    }

    /**
     * Method responsible for checking if the session of the logged user is valid.
     * In order to do it, the following things will be checked:
     * - is there a logged user information?
     * - is there a valid token information?
     * - is the session time valid?
     * If all answers to these questions are yes, session is valid
     *
     * @returns flag indicating if the logged user session is valid
     */
    isSessionValid(): boolean {
        // flag to be returned
        let isSessionValid = true;

        // check if there is a logged user
        isSessionValid = isSessionValid && (localStorage.getItem(SecurityManager.localStorageKeyUserData) != null);

        // check if there is a token and if it's valid
        isSessionValid = isSessionValid && (localStorage.getItem(SecurityManager.localStorageKeyToken) != null);

        // check if the expiration time is still valid
        isSessionValid = isSessionValid && (moment().isBefore(localStorage.getItem(SecurityManager.localStorageKeySessionExpiration)));

        return isSessionValid;
    }

    /**
     * Method responsible for changing user password
     *
     * @param email user's e-mail
     * @param newPassword user's new passsword
     * @param sessionId session ID
     * @param confirmationCode confirmation code
     */
    changePassword(email: string, newPassword: string, newPasswordConfirmation: string, validation: string | number, useSessionId: boolean = true) {
        // set request payload
        const payload = useSessionId ? { email, newPassword, newPasswordConfirmation, sessionId: validation } : { email, newPassword, newPasswordConfirmation, confirmationCode: validation };

        // call erouting server to perform the call
        return this.mouraConnectServer.post<User>('/users/password', payload, true);
    }

    /**
     * Method responsible for ressetting user password
     *
     * @param email user's e-mail
     */
    resetPassword(email: string) {
        // call erouting server to perform the call
        return this.mouraConnectServer.post('/users/password/reset', {email} );
    }

    /**
     * Method responsible for decrypting information. Information will be decrypted only when environment is not local
     *
     * @param dataToBeDecrypted data to be decrypted
     * @returns decrypted data when environment is not local, otherwise returns data itself
     */
    private decryptInformation(dataToBeDecrypted: string): string {
        return environment.local ? dataToBeDecrypted : AES.decrypt(dataToBeDecrypted, environment.cryptographicAESKey).toString(enc.Utf8);
    }

    /**
     * Method responsible for encrypting information. Information will be encrypted only when environment is not local
     *
     * @param dataToBeEncrypted data to be encrypted
     * @returns encrypted data when environment is not local, otherwise returns data itself
     */
    private encryptInformation(dataToBeEncrypted: string): string {
        return environment.local ? dataToBeEncrypted : AES.encrypt(dataToBeEncrypted, environment.cryptographicAESKey).toString();
    }

    /**
     * Method responsible to update the user preferences
     *
     * @param preferenceType the preference type
     * @param value the value of the currente preference
     */
    updateUserPreference(preferenceType: Preferences, value: any) {
        // get the user information
        const user = this.getLoggedUser();

        // set the new preference
        user.preferences.set(preferenceType, value);

        // get logged user from localstorage
        const loggedUserInformation: string = localStorage.getItem(SecurityManager.localStorageKeyUserData);
        if (loggedUserInformation !== null && loggedUserInformation.length > 0)  {
            // store logged user information into local storage
            localStorage.setItem(SecurityManager.localStorageKeyUserData, this.encryptInformation(serialize<User>(user)));
        }
    }
}
