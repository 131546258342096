import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';

import { GenericService } from '../../shared/generic-service.service';
import { MouraConnectServer } from '../../shared/mechanism/moura-connect-server.service';
import { Measurement } from '../../shared/model/measurement.model';

@Injectable()
export class MeasurementService extends GenericService<Measurement> {

    /**
     * Default Constuctor
     * @param mouraConnectServer moura connect server instance
     */
    constructor(protected mouraConnectServer: MouraConnectServer) {
        // call super
        super('/measurements', mouraConnectServer);
    }

    /**
	 * Method responsible for getting measurements belonging a battery in a given period of time
	 *
	 * @param batteryId customer identifier
	 * @param startDate period start date
	 * @param endDate period end date
	 * @return battery measurements in a given period of time
	 */
    getMeasurementsByBatteryInAPeriod(batteryId: number, startDate: Date, endDate: Date): Observable<Measurement[]> {
        // convert date to the correct format between calling server
        const convertedStartDate: string = moment(startDate).format('YYYYMMDDHHmm');
        // set to the correct timezone
        // TODO: change the timezone.
        const convertedEndDate: string = moment(endDate).utcOffset(0).format('YYYYMMDDHHmm');

        return this.mouraConnectServer.get(`/measurements/battery/${batteryId}/between/${convertedStartDate}/${convertedEndDate}`, null, null);
    }
}
