import { AfterContentInit, Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import { DoughnutData } from '../../../shared/model/interface/doughnut-data';

declare var Chart: any;

/**
 * Controller class for the Doughnut chart card Component.
 */
@Component({ selector: 'app-doughnut-chart-card', templateUrl: './doughnut-chart-card.component.html' })
export class DoughnutChartCardComponent implements OnInit, AfterContentInit, OnChanges {

    /**
     * Variable to handle the chart.
     */
    @ViewChild(BaseChartDirective) chart: BaseChartDirective;

    /**
     * Flag indicating whether the component has finished loading its content.
     */
    contentLoaded: boolean;

    /**
     * Flag indicating if will maintain the aspect ratio.
     */
    @Input() maintainAspectRatio: boolean;

    /**
     * Variable to hold Labels array.
     */
    labels: string[];

    /**
     * Variable to hold datasets, with data value and background color.
     */
    datasets: any[];

    /**
     * Variable to hold the chart options.
     */
    options: any;

    /**
     * Default constructor.
     */
    constructor() {
        this.contentLoaded = false;
    }

    /**
     * Variable to hold the chart data, with label, data value and background color.
     */
    @Input() doughtnutData: DoughnutData;

    /**
     * The unit of the values in the chart, to de displayed in the legend.
     */
    @Input() valuesUnit: string;

    /**
     * @see @angular/core/OnInit/ngOnInit()
     */
    ngOnInit() {
        // set chart options
        this.options = {
            maintainAspectRatio: this.maintainAspectRatio,
            responsive: true,
            elements: {
                showMiddleText: this.doughtnutData.showMiddleText
            },
            legend: false,
            cutoutPercentage: 60,
            tooltips: {
                enabled: true,
                callbacks: {
                    // callback method responsible to create a custom label with unit when mouse is over a value.
                    label: (tooltipItem) => {
                        if (isNaN(tooltipItem.xLabel)) {
                            return '';
                        }
                        // get the correct label and value from the doughtnut data.
                        const label = this.doughtnutData.labels[tooltipItem.index];
                        const currentValue = this.doughtnutData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

                        // returns the new label with the value unit.
                        return ` ${label}: ${currentValue}${this.valuesUnit ? this.valuesUnit : ''}`;
                    }
                }
            },
        };
        // create a register to show a central rext
        this.createChartRegisterTextCenter();
    }

    /**
     * @see @angular/core/OnInit/ngAfterContentInit()
     */
    ngAfterContentInit() {
        this.contentLoaded = true;
    }

    /**
     * @see @angular/core/OnChanges/ngOnChanges()
     */
    ngOnChanges() {
        // update the chart
        this.buildChart();
    }

    /**
     * Method responsible to build the chart, setting the chart label, data and background color.
     */
    private buildChart() {
        // set the label and dataset.
        this.labels = this.doughtnutData.labels;
        this.datasets = this.doughtnutData.datasets;

        // Recreate the chart. Angular isn't able to update the chart correctly.
        if (this.chart) {
            // destroy the current chart.
            this.chart.chart.destroy();
            this.chart.chart = 0;

            // set the values, labels, colors and options to the new chart.
            this.chart.datasets = this.datasets;
            this.chart.options = this.options;
            this.chart.labels = this.labels;
            this.chart.colors = this.datasets;

            // build the chart with the new values.
            this.chart.chart = this.chart.getChartBuilder(this.chart.ctx);
         }
    }

    /**
     * Method responsible to create a register to draw a central text
     * inside the chart with the sum of the dataset values.
     */
    private createChartRegisterTextCenter() {
        // use the declared variable to register a plugin.
        Chart.pluginService.register({
            // action that will be executed before draw the chart.
            beforeDraw: function (chart) {
                // get the chart width and height
                const width = chart.chart.width;
                const height = chart.chart.height;
                // get the chart context
                const ctx = chart.chart.ctx;
                // get the current fill style
                const oldFill = ctx.fillStyle;
                // set the font size using the chart height
                const fontSize = ((height - chart.chartArea.top) / 100).toFixed(2);
                // set the font and base line
                ctx.font = `bold ${fontSize}em sans-serif`;
                ctx.textBaseline = 'middle';

                let text = '';
                // if the middle text flag is set, set the text with sum of the values
                if (chart.config.options.elements.showMiddleText) {
                    text = chart.config.data.datasets[0].data.reduce((a: number, b: number) => a + b);
                }
                // set the horizontal and vertical position of the text, using the chart width, and height
                const textX = Math.round((width - ctx.measureText(text).width) / 2);
                const textY = (height + chart.chartArea.top) / 2;

                // set the font color and draw the text.
                ctx.fillStyle = 'rgb(127, 127, 127)';
                ctx.fillText(text, textX, textY);
                // return to the current fill style
                ctx.fillStyle = oldFill;

                ctx.save();
            }
        });
    }
}
